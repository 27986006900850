import { CRUDPathSegmentEn } from '../newFeatures/crudPathSegment.enum';

const salePath = {
  sale: '/erp/sale',

  // Sale order - Phiếu bán hàng
  saleOrder: '/erp/sale/sale-order',
  saleOrderDetailCode: '/erp/sale/sale-order' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  saleOrderAdd: '/erp/sale/sale-order' + CRUDPathSegmentEn.ADD,
  saleOrderEdit: '/erp/sale/sale-order' + CRUDPathSegmentEn.EDIT,

  // Opportunity
  opportunity: '/erp/sale/opportunity',
  opportunityDetailCode: '/erp/sale/opportunity' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  opportunityAdd: '/erp/sale/opportunity' + CRUDPathSegmentEn.ADD,
  opportunityEdit: '/erp/sale/opportunity' + CRUDPathSegmentEn.EDIT
};

export default salePath;
