import type { FlatItem } from 'src/types/elements.type';
import type { IJobCategory, JobCategoryAdd } from 'src/types/master_data/jobCategory.type';
import type { IJobTask } from 'src/types/master_data/jobTask.type';
import type { ITaskActivity } from 'src/types/master_data/taskActivity.type';
import type { PagedList } from 'src/types/pagedList.type';
import { http, httpV2 } from 'src/utils/http';

const controller = 'master-data/job-categories';

export async function getJobCategoriesDropdown() {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
}

export async function getJobCategoriesDropdownByEmployee(employeeId: number) {
  return await httpV2.get<FlatItem[]>(`${controller}/dropdown/${employeeId}`);
}

export const getJobCategories = async (
  searchCondition: string | undefined,
  sequenceStatus: string,
  page: number,
  pageSize: number,
  orderBy?: string
) => {
  return await http.get<PagedList<IJobCategory>>(`${controller}`, {
    params: {
      name: searchCondition,
      sequenceStatus,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getJobCategory = async (id: number) => {
  return http.get<IJobCategory>(`${controller}/${id}`);
};

export const getJobTasksDropdown = async () => {
  return httpV2.get<IJobTask[]>(`${controller}/job-tasks/dropdown`);
};

export const getJobTasksFlatItemDropdown = async (jobCategoryId?: number) => {
  return httpV2.get<FlatItem[]>(`${controller}/job-tasks/dropdown`, { params: { jobCategoryId } });
};

export const getActivitiesDropdown = async (taskId?: number) => {
  return httpV2.get<FlatItem[]>(`${controller}/task-activities/dropdown`, { params: { taskId } });
};

export const getActivitiesDropdownByJobCategoryId = async (jobCategoryId?: number) => {
  return httpV2.get<ITaskActivity[]>(`${controller}/task-activities/dropdown/job-category/${jobCategoryId}`);
};

export const addJobCategory = async (jobCategory: JobCategoryAdd) => {
  return http.post<JobCategoryAdd>(controller, jobCategory);
};

export const updateJobCategory = async (id: number, jobCategory: IJobCategory) => {
  return await http.put<IJobCategory[]>(`${controller}/${id}`, jobCategory);
};

export const deleteJobCategory = async (id: number) => {
  await http.delete(`${controller}/${id}`);
};
