import type { FlatItem } from 'src/types/elements.type';
import { PagedList } from 'src/types/pagedList.type';
import { ITicketStatus, TicketStatusAdd } from 'src/types/helpdesk/ticketStatus.type';
import { http } from 'src/utils/http';

const controller = 'helpdesk/ticket-statuses';

export const getTicketStatuses = async (sequenceStatus: string, page: number, pageSize: number, orderBy?: string) =>
  await http.get<PagedList<ITicketStatus>>(controller, {
    params: {
      sequenceStatus,
      page,
      pageSize,
      orderBy
    }
  });

export const getTicketStatusesDropdown = async (statusType?: number) =>
  await http.get<FlatItem[]>(`${controller}/dropdown`, {
    params: {
      statusType
    }
  });

export const getTicketStatus = async (id: number) => await http.get<ITicketStatus>(`${controller}/${id}`);

export const addTicketStatus = async (ticketStatus: TicketStatusAdd) => await http.post(controller, ticketStatus);

export const updateTicketStatus = async (id: number, ticketType: ITicketStatus) =>
  await http.put(`${controller}/${id}`, ticketType);

export const deleteTicketStatus = async (id: number) => await http.delete(`${controller}/${id}`);
