import urlPath from 'src/constants/urlPath/urlPath';
import type { INotificationType } from 'src/types/helpdesk/notificationType';
import type { UserInfo } from 'src/types/master_data/employee.type';
import { DepartmentEn } from './department.enum';

const result = localStorage.getItem('userInfo');
const userInfo: UserInfo = result ? JSON.parse(result) : null;

export const NOTIFICATION_TYPE: INotificationType[] = [
  //#region TICKET
  {
    key: 'TKA',
    title: 'Ticket - Phê duyệt',
    queryFn: 'tickets',
    returnUrl: urlPath.approval.location
  },
  {
    key: 'TKAR',
    title: 'Ticket - Từ chối',
    queryFn: 'tickets',
    returnUrl: urlPath.myTicket.location
  },
  {
    key: 'TKC',
    title: 'Ticket - Xác nhận',
    queryFn: 'tickets',
    returnUrl: urlPath.accept.location
  },
  {
    key: 'TKCR',
    title: 'Ticket - Từ chối xác nhận',
    queryFn: 'tickets',
    returnUrl: urlPath.myTicket.location
  },
  {
    key: 'TKH',
    title: 'Ticket - Xử lý',
    queryFn: 'tickets',
    returnUrl: urlPath.solutionV2.location
  },
  {
    key: 'TKAS',
    title: 'Ticket - Phân công xử lý',
    queryFn: 'tickets',
    returnUrl: urlPath.handle.location
  },
  {
    key: 'TKAD',
    title: 'Ticket - Phê duyệt',
    queryFn: 'tickets',
    returnUrl: urlPath.myTicket.location
  },
  {
    key: 'TKD',
    title: 'Ticket - Hoàn thành',
    queryFn: 'tickets',
    returnUrl: urlPath.myTicket.location
  },
  {
    key: 'CANCEL_APPROVAL',
    title: 'Ticket - Thu hồi duyệt',
    queryFn: 'tickets',
    returnUrl: urlPath.approval.location
  },
  {
    key: 'TKSC',
    title: 'Ticket - Từ chối giải pháp',
    queryFn: 'tickets',
    returnUrl: urlPath.solutionV2.location
  },
  {
    key: 'TKSAD',
    title: 'Ticket - Duyệt giải pháp',
    queryFn: 'tickets',
    returnUrl: urlPath.solutionV2.location
  },
  {
    key: 'TKSAA',
    title: 'Ticket - Duyệt giải pháp',
    queryFn: 'tickets',
    returnUrl: urlPath.solutionV2.location
  },
  {
    key: 'TKNC',
    title: 'Ticket - Đồng ý kết quả xử lý',
    queryFn: 'tickets',
    returnUrl: urlPath.myTicket.location
  },
  {
    key: 'TKRC',
    title: 'Ticket - Không đồng ý kết quả xử lý',
    queryFn: 'tickets',
    returnUrl: urlPath.solutionV2.location
  },
  {
    key: 'TICKET_OWNER_OT',
    title: 'Tăng ca - Xác nhận tăng ca',
    queryFn: 'tickets',
    returnUrl: urlPath.myTicket.location
  },
  {
    key: 'TICKET_OWNER_BUS',
    title: 'Ticket - Xác nhận công tác',
    queryFn: 'tickets',
    returnUrl: urlPath.myTicket.location
  },
  //#endregion TICKET

  //#region TOPIC
  {
    key: 'TOPIC_TAG',
    title: 'Ban được nhắc đến trong tin nhắn',
    queryFn: 'topics',
    returnUrl: urlPath.topic.location
  },
  {
    key: 'TOPIC_MESSAGE',
    title: 'Tin nhắn mới',
    queryFn: 'topics',
    returnUrl: urlPath.topic.location
  },
  //#endregion

  //#region LEAVE
  {
    key: 'LEAVE_APPROVAL',
    title: 'Nghỉ phép - Phê duyệt',
    queryFn: 'leaveTicketsV2',
    returnUrl: urlPath.leaveTicketApprovalV2.location
  },
  {
    key: 'LEAVE_APPROVED',
    title: 'Nghỉ phép - Phê duyệt',
    queryFn: 'leaveTicketsV2',
    returnUrl: urlPath.myLeaveTicketV2.location
  },
  {
    key: 'LEAVE_REJECTED',
    title: 'Nghỉ phép - Từ chối',
    queryFn: 'leaveTicketsV2',
    returnUrl: urlPath.myLeaveTicketV2.location
  },
  {
    key: 'LEAVE_CANCELLED_APPROVAL',
    title: 'Nghỉ phép - Thu hồi duyệt',
    queryFn: 'leaveTicketsV2',
    returnUrl: urlPath.leaveTicketApprovalV2.location
  },
  {
    key: 'LEAVE_CANCELLED',
    title: 'Nghỉ phép - Hủy phiếu',
    queryFn: 'leaveTicketsV2',
    returnUrl: urlPath.leaveTicketApprovalV2.location
  },
  //#endregion

  //#region LEAVE PACKAGE
  {
    key: 'LEAVE_PACKAGE_APPROVAL',
    title: 'Nghỉ phép - Phê duyệt',
    queryFn: 'leaveTicketPackagesV2',
    returnUrl: urlPath.leaveTicketPackageApprovalV2.location
  },
  {
    key: 'LEAVE_PACKAGE_CANCELLED_APPROVAL',
    title: 'Nghỉ phép - Thu hồi duyệt',
    queryFn: 'leaveTicketPackagesV2',
    returnUrl: urlPath.leaveTicketPackageApprovalV2.location
  },
  {
    key: 'LEAVE_PACKAGE_CANCELLED',
    title: 'Nghỉ phép - Hủy phiếu',
    queryFn: 'leaveTicketPackagesV2',
    returnUrl: urlPath.leaveTicketPackageApprovalV2.location
  },
  //#endregion

  //#region OT
  {
    key: 'OT_APPROVAL',
    title: 'Tăng ca - Phê duyệt',
    queryFn: 'overtimeTicketsV2',
    returnUrl: urlPath.overtimeTicketApprovalV2.location
  },
  {
    key: 'OT_APPROVED',
    title: 'Tăng ca - Phê duyệt',
    queryFn: 'overtimeTicketsV2',
    returnUrl: urlPath.myOvertimeTicketV2.location
  },
  {
    key: 'OT_SUPERVISOR_CONFIRM',
    title: 'Tăng ca - Giám sát xác nhận',
    queryFn: 'overtimeTicketsV2',
    returnUrl: urlPath.overtimeTicketSupervisorConfirmV2.location
  },
  {
    key: 'OT_REJECTED',
    title: 'Tăng ca - Từ chối',
    queryFn: 'overtimeTicketsV2',
    returnUrl: urlPath.myOvertimeTicketV2.location
  },
  {
    key: 'OT_CANCELLED_APPROVAL',
    title: 'Tăng ca - Thu hồi duyệt',
    queryFn: 'overtimeTicketsV2',
    returnUrl: urlPath.overtimeTicketApprovalV2.location
  },
  //#endregion

  //#region BUSINESS TRIP
  {
    key: 'TRIP_APPROVAL',
    title: 'Công tác - Phê duyệt',
    queryFn: 'businessTripTicketsV2',
    returnUrl: urlPath.businessTripTicketApprovalV2.location
  },
  {
    key: 'TRIP_APPROVED',
    title: 'Công tác - Phê duyệt',
    queryFn: 'businessTripTicketsV2',
    returnUrl: urlPath.myBusinessTripTicketV2.location
  },
  {
    key: 'TRIP_REJECTED',
    title: 'Công tác - Từ chối',
    queryFn: 'businessTripTicketsV2',
    returnUrl: urlPath.myBusinessTripTicketV2.location
  },
  {
    key: 'TRIP_CANCELLED_APPROVAL',
    title: 'Công tác - Thu hồi duyệt',
    queryFn: 'businessTripTicketsV2',
    returnUrl: urlPath.businessTripTicketApprovalV2.location
  },
  //#endregion

  //#region TRANSPORTATION TICKET
  {
    key: 'TRAN_APPROVAL', // Gửi thông báo cho người phê duyệt
    title: 'Vận chuyển - Phê duyệt',
    queryFn: 'transportationTicket',
    returnUrl: urlPath.transportationTicketApprovalV2.location
  },
  {
    key: 'TRAN_APPROVED', // Gửi thông báo đã phê duyệt cho người tạo phiếu
    title: 'Vận chuyển - Phê duyệt',
    queryFn: 'transportationTicket',
    returnUrl: urlPath.myTransportationTicketV2.location
  },
  {
    key: 'TRAN_REJECTED',
    title: 'Vận chuyển - Từ chối',
    queryFn: 'transportationTicket',
    returnUrl: urlPath.myTransportationTicketV2.location
  },
  {
    key: 'TRAN_CANCELLED_APPROVAL', // Gửi thông báo đã xác nhận cho người tạo phiếu
    title: 'Vận chuyển - Thu hồi duyệt',
    queryFn: 'transportationTicket',
    returnUrl: urlPath.transportationTicketApprovalV2.location
  },
  {
    key: 'TRAN_CONFIRM', // Gửi thông báo cho người xác nhận
    title: 'Vận chuyển - Xác nhận',
    queryFn: 'transportationTicket',
    returnUrl:
      userInfo?.departmentId === DepartmentEn.hr
        ? urlPath.hrConfirmTransportationTicketV2.location
        : urlPath.transportationTicketConfirmV2.location
  },
  {
    key: 'TRAN_CONFIRMED', // Gửi thông báo đã xác nhận cho người tạo phiếu
    title: 'Vận chuyển - Xác nhận',
    queryFn: 'transportationTicket',
    returnUrl: urlPath.myTransportationTicketV2.location
  },
  {
    key: 'TRAN_REJECT_CONFIRM', // Gửi thông báo đã từ chối xác nhận cho người tạo phiếu
    title: 'Vận chuyển - Xác nhận',
    queryFn: 'transportationTicket',
    returnUrl: urlPath.myTransportationTicketV2.location
  },
  {
    key: 'DRIVER_CONFIRMED', // Gửi thông báo cho tài xế
    title: 'Vận chuyển - Tài xế xử lý',
    queryFn: 'transportationTicket',
    returnUrl: urlPath.transportationTicketHandleV2.location
  },
  //#endregion

  //#region PURCHASE REQUEST TICKET
  {
    key: 'PR_APPROVAL',
    title: 'Mua hàng - Phê duyệt',
    queryFn: 'purchaseRequestTicketsV2',
    returnUrl: urlPath.purchaseRequestTicketApprovalV2.location
  },
  {
    key: 'PR_APPROVED',
    title: 'Mua hàng - Phê duyệt',
    queryFn: 'purchaseRequestTicketsV2',
    returnUrl: urlPath.myPurchaseRequestTicketV2.location
  },
  {
    key: 'PR_REJECTED',
    title: 'Mua hàng - Từ chối',
    queryFn: 'purchaseRequestTicketsV2',
    returnUrl: urlPath.myPurchaseRequestTicketV2.location
  },
  {
    key: 'PR_CANCELLED_APPROVAL',
    title: 'Mua hàng - Thu hồi duyệt',
    queryFn: 'purchaseRequestTicketsV2',
    returnUrl: urlPath.purchaseRequestTicketApprovalV2.location
  },
  //#endregion

  //#region ADVANCE PAYMENT TICKET
  {
    key: 'AP_APPROVAL',
    title: 'Ứng tiền - Phê duyệt',
    queryFn: 'advancePaymentTickets',
    returnUrl: urlPath.advancePaymentTicketApprovalV2.location
  },
  {
    key: 'AP_APPROVED',
    title: 'Ứng tiền - Phê duyệt',
    queryFn: 'advancePaymentTickets',
    returnUrl: urlPath.myAdvancePaymentTicketV2.location
  },
  {
    key: 'AP_REJECTED',
    title: 'Ứng tiền - Từ chối',
    queryFn: 'advancePaymentTickets',
    returnUrl: urlPath.myAdvancePaymentTicketV2.location
  },
  {
    key: 'AP_CANCELLED_APPROVAL',
    title: 'Ứng tiền - Thu hồi duyệt',
    queryFn: 'advancePaymentTickets',
    returnUrl: urlPath.advancePaymentTicketApprovalV2.location
  },
  //#endregion

  //#region HAND OVER TICKET
  {
    key: 'HANDOVER_CONFIRM',
    title: 'Bàn giao - Phê duyệt',
    queryFn: 'handOverTicketsV2',
    returnUrl: urlPath.myHandoverTicketV2.location
  },
  {
    key: 'HANDOVER_FINISHED',
    title: 'Ứng tiền - Xác nhận',
    queryFn: 'handOverTicketsV2',
    returnUrl: urlPath.myHandoverTicketV2.location
  },
  {
    key: 'HANDOVER_CANCELED',
    title: 'Ứng tiền - Hủy xác nhận',
    queryFn: 'handOverTicketsV2',
    returnUrl: urlPath.handoverTicketApprovalV2.location
  },
  //#endregion

  //#region EQUIPMENT SUPPLY REQUEST TICKET
  {
    key: 'ESR_APPROVAL',
    title: 'Cấp thiết bị - Phê duyệt',
    queryFn: 'equipmentSupplyRequestTicketsV2',
    returnUrl: urlPath.equipmentSupplyRequestTicketApprovalV2.location
  },
  {
    key: 'ESR_APPROVED',
    title: 'Cấp thiết bị - Phê duyệt',
    queryFn: 'equipmentSupplyRequestTicketsV2',
    returnUrl: urlPath.myEquipmentSupplyRequestTicketV2.location
  },
  {
    key: 'ESR_REJECTED',
    title: 'Cấp thiết bị - Từ chối',
    queryFn: 'equipmentSupplyRequestTicketsV2',
    returnUrl: urlPath.myEquipmentSupplyRequestTicketV2.location
  },
  {
    key: 'ESR_CANCELLED_APPROVAL',
    title: 'Cấp thiết bị - Thu hồi duyệt',
    queryFn: 'equipmentSupplyRequestTicketsV2',
    returnUrl: urlPath.equipmentSupplyRequestTicketApprovalV2.location
  },
  {
    key: 'ESR_CANCELLED',
    title: 'Cấp thiết bị - Hủy phiếu',
    queryFn: 'equipmentSupplyRequestTicketsV2',
    returnUrl: urlPath.equipmentSupplyRequestTicketApprovalV2.location
  },
  //#endregion

  //#region WORK PLAN
  {
    key: 'PERTASK_APPROVAL',
    title: 'Kế hoạch cá nhân - Phê duyệt',
    queryFn: ['workPlansApproval', 'workPlan'],
    returnUrl: `${urlPath.workPlanDetail?.location}`
  },
  {
    key: 'PERTASK_APPROVED',
    title: 'Kế hoạch cá nhân - Phê duyệt',
    queryFn: ['workPlansApproval', 'workPlan'],
    returnUrl: `${urlPath.workPlanDetail?.location}`
  },
  {
    key: 'PERTASK_REJECTED',
    title: 'Kế hoạch cá nhân - Từ chối',
    queryFn: ['workPlansApproval', 'workPlan'],
    returnUrl: `${urlPath.workPlanDetail?.location}`
  },
  {
    key: 'PERTASK_CANCELLED_APPROVAL',
    title: 'Kế hoạch cá nhân - Thu hồi duyệt',
    queryFn: ['workPlansApproval', 'workPlan'],
    returnUrl: `${urlPath.workPlanDetail?.location}`
  }
  //#endregion
];
