import { CRUDPathSegmentEn } from '../newFeatures/crudPathSegment.enum';

export interface PathType {
  location: string;
  path: string[];
}

const getPath = (location: string, path?: string[]): PathType => {
  const home: PathType = {
    location,
    path: path ?? []
  };
  return home;
};

const urlPath: { [key: string]: PathType } = {
  home: getPath('/', ['sidebar.home']),

  //#region crm
  crm: getPath('/crm', ['sidebar.home', 'v2.crm.index']),

  //#endregion crm

  //#region ticket raising
  ticketRaising: getPath('/ticket-raising', ['sidebar.home', 'v2.ticketRaising.index']),

  //#region Ticket
  ticket: getPath('/ticket-raising/ticket', ['v2.sidebar.home', 'v2.helpdesk.index', 'v2.sidebar.ticket.index']),
  ticketDetail: getPath('/ticket-raising/ticket' + CRUDPathSegmentEn.DETAIL, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'action.page'
  ]),
  ticketAdd: getPath('/ticket-raising/ticket' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'action.add'
  ]),
  ticketEdit: getPath('/ticket-raising/ticket' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  myTicket: getPath('/ticket-raising/ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'Ticket của tôi'
  ]),
  approval: getPath('/ticket-raising/ticket/approval', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'v2.sidebar.approval'
  ]),
  accept: getPath('/ticket-raising/ticket/accept', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'v2.sidebar.accept'
  ]),
  solutionV2: getPath('/ticket-raising/ticket/solution', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'v2.sidebar.handle'
  ]),
  solutionAddV2: getPath('/ticket-raising/ticket/solution' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'v2.sidebar.handle',
    'action.add'
  ]),
  solutionEditV2: getPath('/ticket-raising/ticket/solution' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  handle: getPath('/ticket-raising/ticket/handle', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'v2.sidebar.myHandle'
  ]),
  //#endregion

  //#region Hr Ticket
  hrTicketV2: getPath('/ticket-raising/hr-ticket', ['v2.sidebar.home', 'v2.helpdesk.index', 'sidebar.hr.index']),

  //#region Advance Payment Ticket
  advancePaymentTicketV2: getPath('/ticket-raising/hr-ticket/advance-payment-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.advancePaymentTicket'
  ]),
  myAdvancePaymentTicketV2: getPath('/ticket-raising/hr-ticket/advance-payment-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.advancePaymentTicket',
    'Phiếu của tôi'
  ]),
  advancePaymentTicketApprovalV2: getPath('/ticket-raising/hr-ticket/advance-payment-ticket/approval', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.advancePaymentTicket',
    'v2.sidebar.approval'
  ]),
  advancePaymentTicketAddV2: getPath('/ticket-raising/hr-ticket/advance-payment-ticket' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.advancePaymentTicket',
    'action.add'
  ]),
  advancePaymentTicketEditV2: getPath('/ticket-raising/hr-ticket/advance-payment-ticket' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.advancePaymentTicket',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  advancePaymentTicketDetailV2: getPath('/ticket-raising/hr-ticket/advance-payment-ticket' + CRUDPathSegmentEn.DETAIL, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.dvancePaymentTicket',
    'page.' + CRUDPathSegmentEn.DETAIL
  ]),
  //#endregion

  //#region Leave ticket
  leaveTicketV2: getPath('/ticket-raising/hr-ticket/leave-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.leaveTicket'
  ]),
  myLeaveTicketV2: getPath('/ticket-raising/hr-ticket/leave-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.leaveTicket',
    'Phiếu của tôi'
  ]),
  leaveTicketApprovalV2: getPath('/ticket-raising/hr-ticket/leave-ticket/approval', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.leaveTicket',
    'v2.sidebar.approval'
  ]),
  leaveTicketPackageApprovalV2: getPath('/ticket-raising/hr-ticket/leave-ticket/approval-package', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.leaveTicket',
    'v2.sidebar.approval'
  ]),
  leaveTicketAddV2: getPath('/ticket-raising/hr-ticket/leave-ticket' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.leaveTicket',
    'action.add'
  ]),
  leaveTicketEditV2: getPath('/ticket-raising/hr-ticket/leave-ticket' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.leaveTicket',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  //#endregion

  //#region External Worker ticket
  externalWorkerTicket: getPath('/ticket-raising/hr-ticket/external-worker-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.externalWorkerTicket'
  ]),
  myExternalWorkerTicket: getPath('/ticket-raising/hr-ticket/external-worker-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.externalWorkerTicket',
    'Phiếu của tôi'
  ]),
  externalWorkerTicketApproval: getPath('/ticket-raising/hr-ticket/external-worker-ticket/approval', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.externalWorkerTicket',
    'v2.sidebar.approval'
  ]),
  externalWorkerTicketAdd: getPath('/ticket-raising/hr-ticket/external-worker-ticket' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.externalWorkerTicket',
    'action.add'
  ]),
  externalWorkerTicketEdit: getPath('/ticket-raising/hr-ticket/external-worker-ticket' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.externalWorkerTicket',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  //#endregion

  //#region Overtime ticket
  overtimeTicketV2: getPath('/ticket-raising/hr-ticket/overtime-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.overtimeTicket'
  ]),
  myOvertimeTicketV2: getPath('/ticket-raising/hr-ticket/overtime-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.overtimeTicket',
    'Phiếu của tôi'
  ]),
  overtimeTicketApprovalV2: getPath('/ticket-raising/hr-ticket/overtime-ticket/approval', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.overtimeTicket',
    'v2.sidebar.approval'
  ]),
  overtimeTicketAddV2: getPath('/ticket-raising/hr-ticket/overtime-ticket' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.overtimeTicket',
    'action.add'
  ]),
  overtimeTicketEditV2: getPath('/ticket-raising/hr-ticket/overtime-ticket' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.overtimeTicket',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  overtimeTicketConfirmV2: getPath('/ticket-raising/hr-ticket/overtime-ticket/confirm', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.overtimeTicket',
    'action.confirm'
  ]),
  overtimeTicketSupervisorConfirmV2: getPath('/ticket-raising/hr-ticket/overtime-ticket/supervisor-confirm', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.overtimeTicket',
    'action.supervisorConfirm'
  ]),
  //#endregion

  //#region Business trip ticket
  businessTripTicketV2: getPath('/ticket-raising/hr-ticket/business-trip-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.businessTripTicket'
  ]),
  myBusinessTripTicketV2: getPath('/ticket-raising/hr-ticket/business-trip-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.businessTripTicket',
    'Phiếu của tôi'
  ]),
  businessTripTicketApprovalV2: getPath('/ticket-raising/hr-ticket/business-trip-ticket/approval', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.businessTripTicket',
    'Phê duyệt'
  ]),
  businessTripTicketAddV2: getPath('/ticket-raising/hr-ticket/business-trip-ticket' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.businessTripTicket',
    'action.add'
  ]),
  businessTripTicketEditV2: getPath('/ticket-raising/hr-ticket/business-trip-ticket' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.businessTripTicket',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  //#endregion

  //#region Purchase request
  purchaseRequestTicketV2: getPath('/ticket-raising/hr-ticket/purchase-request-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.purchaseRequestTicket'
  ]),
  myPurchaseRequestTicketV2: getPath('/ticket-raising/hr-ticket/purchase-request-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.purchaseRequestTicket',
    'Phiếu của tôi'
  ]),
  purchaseRequestTicketApprovalV2: getPath('/ticket-raising/hr-ticket/purchase-request-ticket/approval', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.purchaseRequestTicket',
    'Phê duyệt'
  ]),
  purchaseRequestTicketAddV2: getPath('/ticket-raising/hr-ticket/purchase-request-ticket' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.purchaseRequestTicket',
    'action.add'
  ]),
  purchaseRequestTicketEditV2: getPath('/ticket-raising/hr-ticket/purchase-request-ticket' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.purchaseRequestTicket',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  //#endregion

  //#region Transportation ticket
  transportationTicketV2: getPath('/ticket-raising/hr-ticket/transportation-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.transportationTicket'
  ]),
  myTransportationTicketV2: getPath('/ticket-raising/hr-ticket/transportation-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.transportationTicket',
    'Phiếu của tôi'
  ]),
  transportationTicketApprovalV2: getPath('/ticket-raising/hr-ticket/transportation-ticket/approval', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.transportationTicket',
    'Phê duyệt'
  ]),
  transportationTicketConfirmV2: getPath('/ticket-raising/hr-ticket/transportation-ticket/confirm', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.transportationTicket',
    'Xác nhận'
  ]),
  transportationTicketHandleV2: getPath('/ticket-raising/hr-ticket/transportation-ticket/handle', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.transportationTicket',
    'Phiếu của tôi xử lý'
  ]),
  transportationTicketAddV2: getPath('/ticket-raising/hr-ticket/transportation-ticket' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.transportationTicket',
    'action.add'
  ]),
  transportationTicketEditV2: getPath('/ticket-raising/hr-ticket/transportation-ticket' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.transportationTicket',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  //#endregion

  //#region ExpenseTrackingRequestticket
  expenseTrackingRequestTicket: getPath('/ticket-raising/hr-ticket/expense-tracking-request-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.expenseTrackingRequestTicket'
  ]),
  myExpenseTrackingRequestTicket: getPath('/ticket-raising/hr-ticket/expense-tracking-request-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.expenseTrackingRequestTicket',
    'Phiếu của tôi'
  ]),
  expenseTrackingRequestTicketApproval: getPath('/ticket-raising/hr-ticket/expense-tracking-request-ticket/approval', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.expenseTrackingRequestTicket',
    'v2.sidebar.approval'
  ]),
  expenseTrackingRequestTicketAdd: getPath('/ticket-raising/hr-ticket/expense-tracking-request-ticket/add', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.expenseTrackingRequestTicket',
    'action.add'
  ]),
  expenseTrackingRequestTicketEdit: getPath('/ticket-raising/hr-ticket/expense-tracking-request-ticket/edit', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.expenseTrackingRequestTicket',
    'action.edit'
  ]),
  //#endregion

  //#region Equipment Supply Request Ticket
  equipmentSupplyRequestTicketV2: getPath('/ticket-raising/hr-ticket/equipment-supply-request-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.equipmentSupplyRequestTicket'
  ]),
  myEquipmentSupplyRequestTicketV2: getPath('/ticket-raising/hr-ticket/equipment-supply-request-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.equipmentSupplyRequestTicket',
    'Phiếu của tôi'
  ]),
  equipmentSupplyRequestTicketApprovalV2: getPath(
    '/ticket-raising/hr-ticket/equipment-supply-request-ticket/approval',
    [
      'v2.sidebar.home',
      'v2.helpdesk.index',
      'v2.sidebar.hrTicket.index',
      'v2.sidebar.hrTicket.equipmentSupplyRequestTicket',
      'Phê duyệt'
    ]
  ),
  equipmentSupplyRequestTicketAddV2: getPath(
    '/ticket-raising/hr-ticket/equipment-supply-request-ticket' + CRUDPathSegmentEn.ADD,
    [
      'v2.sidebar.home',
      'v2.helpdesk.index',
      'v2.sidebar.hrTicket.index',
      'v2.sidebar.hrTicket.equipmentSupplyRequestTicket',
      'action.add'
    ]
  ),
  equipmentSupplyRequestTicketEditV2: getPath(
    '/ticket-raising/hr-ticket/equipment-supply-request-ticket' + CRUDPathSegmentEn.EDIT,
    [
      'v2.sidebar.home',
      'v2.helpdesk.index',
      'v2.sidebar.hrTicket.index',
      'v2.sidebar.hrTicket.equipmentSupplyRequestTicket',
      'action.' + CRUDPathSegmentEn.EDIT
    ]
  ),
  //#endregion

  //#region Additional Working Time Ticket
  additionalWorkingTimeTicketV2: getPath('/ticket-raising/hr-ticket/additional-working-time-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.additionalWorkingTimeTicket'
  ]),
  myAdditionalWorkingTimeTicketV2: getPath('/ticket-raising/hr-ticket/additional-working-time-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.additionalWorkingTimeTicket',
    'Phiếu của tôi'
  ]),
  additionalWorkingTimeTicketApprovalV2: getPath('/ticket-raising/hr-ticket/additional-working-time-ticket/approval', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.additionalWorkingTimeTicket',
    'Phê duyệt'
  ]),
  additionalWorkingTimeTicketAddV2: getPath(
    '/ticket-raising/hr-ticket/additional-working-time-ticket' + CRUDPathSegmentEn.ADD,
    [
      'v2.sidebar.home',
      'v2.helpdesk.index',
      'v2.sidebar.hrTicket.index',
      'v2.sidebar.hrTicket.additionalWorkingTimeTicket',
      'action.add'
    ]
  ),
  additionalWorkingTimeTicketEditV2: getPath(
    '/ticket-raising/hr-ticket/additional-working-time-ticket' + CRUDPathSegmentEn.EDIT,
    [
      'v2.sidebar.home',
      'v2.helpdesk.index',
      'v2.sidebar.hrTicket.index',
      'v2.sidebar.hrTicket.additionalWorkingTimeTicket',
      'action.' + CRUDPathSegmentEn.EDIT
    ]
  ),
  //#endregion

  //#region Additional Working Time Package
  additionalWorkingTimePackageV2: getPath('/ticket-raising/hr-ticket/additional-working-time-package', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket. additionalWorkingTimePackage'
  ]),
  additionalWorkingTimePackageApprovalV2: getPath(
    '/ticket-raising/hr-ticket/additional-working-time-package/approval',
    [
      'v2.sidebar.home',
      'v2.helpdesk.index',
      'v2.sidebar.hrTicket.index',
      'v2.sidebar.hrTicket. additionalWorkingTimePackage',
      'Phê duyệt'
    ]
  ),
  //#endregion

  //#region Hr confirm
  hrConfirmV2: getPath('/ticket-raising/hr-confirm', ['v2.sidebar.home', 'v2.sidebar.hrConfirm.index']),
  hrConfirmLeaveTicketV2: getPath('/ticket-raising/hr-confirm/leave-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrConfirm.index',
    'v2.sidebar.hrConfirm.leaveTicket'
  ]),
  hrConfirmExpenseTrackingRequestTicket: getPath('/ticket-raising/hr-confirm/expense-tracking-request-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrConfirm.index',
    'v2.sidebar.hrConfirm.leaveTicket'
  ]),
  hrConfirmOvertimeTicketV2: getPath('/ticket-raising/hr-confirm/overtime-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrConfirm.index',
    'v2.sidebar.hrConfirm.overtimeTicket'
  ]),
  hrConfirmBusinessTripTicketV2: getPath('/ticket-raising/hr-confirm/business-trip-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrConfirm.index',
    'v2.sidebar.hrConfirm.businessTripTicket'
  ]),
  hrConfirmTransportationTicketV2: getPath('/ticket-raising/hr-confirm/transportation-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrConfirm.index',
    'v2.sidebar.hrConfirm.transportationTicket'
  ]),
  //#endregion

  //#endregion Hr Ticket

  //#endregion ticket raising

  //#region erp
  erp: getPath('/erp', ['sidebar.home', 'v2.erp.index']),

  //#region planning
  planning: getPath('/erp/planning', ['sidebar.home', 'v2.erp.index', 'v2.erp.planning.index']),

  companyBudget: getPath('/erp/planning/company-budget'),
  costControl: getPath('/erp/planning/cost-control'),
  budgetControl: getPath('/erp/planning/budget-control'),
  //#region KPI
  kpi: getPath('/erp/planning/kpi', [
    'sidebar.home',
    'v2.erp.index',
    'v2.erp.planning.index',
    'v2.erp.planning.kpi.index'
  ]),

  // Target
  target: getPath('/erp/planning/kpi/target', ['sidebar.home', 'sidebar.planning.index', 'sidebar.planning.target']),
  targetAdd: getPath('/erp/planning/kpi/target' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'sidebar.planning.index',
    'sidebar.planning.target',
    'action.add'
  ]),
  targetEdit: getPath('/erp/planning/kpi/target' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'sidebar.planning.index',
    'sidebar.planning.target',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  targetDetail: getPath('/erp/planning/kpi/target/detail', [
    'sidebar.home',
    'sidebar.planning.index',
    'v2.planning.target',
    'action.detail'
  ]),
  // Work assign
  workAssign: getPath('/erp/planning/kpi/work-assign', [
    'sidebar.home',
    'sidebar.planning.index',
    'sidebar.planning.workAssign'
  ]),
  workAssignAdd: getPath('/erp/planning/kpi/work-assign' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'sidebar.planning.index',
    'sidebar.planning.workAssign',
    'action.add'
  ]),
  workAssignEdit: getPath('/erp/planning/kpi/work-assign' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'sidebar.planning.index',
    'sidebar.planning.workAssign',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  workAssignDetail: getPath('/erp/planning/kpi/work-assign/detail', [
    'sidebar.home',
    'sidebar.planning.index',
    'v2.planning.workAssign',
    'action.detail'
  ]),

  // Work Plan
  workPlan: getPath('/erp/planning/kpi/work-plan', [
    'sidebar.home',
    'sidebar.planning.index',
    'sidebar.planning.workPlan'
  ]),
  workPlanAdd: getPath('/erp/planning/kpi/work-plan' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'sidebar.planning.index',
    'sidebar.planning.workPlan',
    'action.add'
  ]),
  workPlanEdit: getPath('/erp/planning/kpi/work-plan' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'sidebar.planning.index',
    'v2.planning.workPlan',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  workPlanDetail: getPath('/erp/planning/kpi/work-plan/detail', [
    'sidebar.home',
    'sidebar.planning.index',
    'v2.planning.workPlan',
    'action.detail'
  ]),
  //#endregion KPI

  // PQ
  planningPQ: getPath('/erp/planning/pq', [
    'sidebar.home',
    'v2.erp.index',
    'v2.erp.planning.index',
    'v2.erp.planning.pq.index',
    'v2.erp.planning.pq.' + CRUDPathSegmentEn.DETAIL
  ]),
  planningPQDetail: getPath('/erp/planning/pq' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.projectPlanning.index',
    'v2.projectPlanning.planningPQ',
    'v2.projectPlanning.' + CRUDPathSegmentEn.DETAIL
  ]),

  //SALE ORDER
  planningSODetailCode: getPath('/erp/planning/so' + CRUDPathSegmentEn.DETAIL_BY_CODE, [
    'sidebar.home',
    'v2.sale.index',
    'v2.sale.so',
    'action.' + CRUDPathSegmentEn.DETAIL_BY_CODE
  ]),

  // PR
  planningPR: getPath('/erp/planning/pr', [
    'sidebar.home',
    'v2.erp.index',
    'v2.erp.planning.index',
    'v2.erp.planning.pr.index'
  ]),
  planningPRDetail: getPath('/erp/planning/pr' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.projectPlanning.index',
    'v2.projectPlanning.planningPR',
    'v2.projectPlanning.' + CRUDPathSegmentEn.DETAIL
  ]),
  planningPRDetailCode: getPath('/erp/planning/pr' + CRUDPathSegmentEn.DETAIL_BY_CODE, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pr',
    'v2.purchase.' + CRUDPathSegmentEn.DETAIL
  ]),
  planningPRAdd: getPath('/erp/planning/pr' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pr',
    'action.add'
  ]),
  planningPREdit: getPath('/erp/planning/pr' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pr',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),

  // SR - SUPPLY REQUEST
  sr: getPath('/erp/planning/sr', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.sr']),
  srDetail: getPath('/erp/planning/sr' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.sr',
    'v2.purchase.' + CRUDPathSegmentEn.DETAIL
  ]),
  srDetailCode: getPath('/erp/planning/sr' + CRUDPathSegmentEn.DETAIL_BY_CODE, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.sr',
    'v2.purchase.' + CRUDPathSegmentEn.DETAIL
  ]),
  srAdd: getPath('/erp/planning/sr' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.sr',
    'action.add'
  ]),
  srEdit: getPath('/erp/planning/sr' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.sr',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),

  // SR SUMMARY
  planningSrSummary: getPath('/erp/planning/sr-summary', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.sr']),
  planningSrSummaryDetail: getPath('/erp/planning/sr-summary' + CRUDPathSegmentEn.DETAIL_BY_CODE, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.sr',
    'v2.purchase.' + CRUDPathSegmentEn.DETAIL
  ]),
  // PR SUMMARY
  planningPrSummary: getPath('/erp/planning/pr-summary', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.pr']),
  planningPrSummaryDetail: getPath('/erp/planning/pr-summary' + CRUDPathSegmentEn.DETAIL_BY_CODE, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pr',
    'v2.purchase.' + CRUDPathSegmentEn.DETAIL
  ]),

  // SO
  planningSaleOrder: getPath('/erp/planning/so', ['sidebar.home', 'v2.projectPlanning.index', 'v2.projectPlanning.so']),
  // Prod Order
  planningProductionOrder: getPath('/erp/planning/production-order', [
    'sidebar.home',
    'v2.projectPlanning.index',
    'v2.projectPlanning.productionOrder'
  ]),

  // MRP
  mrp: getPath('/erp/planning/mrp', ['sidebar.home', 'v2.projectPlanning.index', 'v2.projectPlanning.mrp']),
  mrpReports: getPath('/erp/planning/mrp-report', [
    'sidebar.home',
    'v2.projectPlanning.index',
    'v2.projectPlanning.mrp'
  ]),

  //#endregion planning

  //#region human resource
  humanResource: getPath('/erp/human-resource', ['sidebar.home', 'v2.erp.index', 'v2.erp.humanResource.index']),

  //#region recruitment
  recruitment: getPath('/erp/human-resource/recruitment'),

  recruitmentProcess: getPath('/erp/human-resource/recruitment/process'),

  recruitmentJobDescription: getPath('/erp/human-resource/recruitment/job-description'),

  recruitmentPlan: getPath('/erp/human-resource/recruitment/plan'),

  jobRequirements: getPath('/erp/human-resource/recruitment/job-requirements'),

  recruitmentCandidate: getPath('/erp/human-resource/recruitment/candidate'),

  interviewInvitationLetter: getPath('/erp/human-resource/recruitment/interview-invitation-letter'),

  resultNotificationLetter: getPath('/erp/human-resource/recruitment/result-notification-letter'),

  jobOfferLetter: getPath('/erp/human-resource/recruitment/job-offer-letter'),

  employmentConfirmationNotice: getPath('/erp/human-resource/recruitment/employment-confirmation-notice'),

  recruitmentActivityResults: getPath('/erp/human-resource/recruitment/activity-results'),

  //#endregion recruitment

  //#region training
  training: getPath('/erp/human-resource/training'),

  trainingProcess: getPath('/erp/human-resource/training/process'),

  trainingDocumentation: getPath('/erp/human-resource/training/documentation'),

  trainingJobDescription: getPath('/erp/human-resource/training/job-description'),

  trainingPlan: getPath('/erp/human-resource/training/plan'),

  trainingRequirements: getPath('/erp/human-resource/training/requirements'),

  trainingSupplier: getPath('/erp/human-resource/training/supplier'),

  trainingProgram: getPath('/erp/human-resource/training/program'),

  trainingCost: getPath('/erp/human-resource/training/cost'),

  trainingParticipant: getPath('/erp/human-resource/training/participant'),

  trainingInvitationLetter: getPath('/erp/human-resource/training/invitation-letter'),

  trainingResult: getPath('/erp/human-resource/training/result'),

  trainingResultNotification: getPath('/erp/human-resource/training/result-notification'),

  trainingActivityResults: getPath('/erp/human-resource/training/activity-results'),

  //#endregion training

  //#region C&B
  cnb: getPath('/erp/human-resource/cnb', ['sidebar.home', 'v2.erp.index', 'v2.erp.cnb.index']),

  cnbEmployee: getPath('/erp/human-resource/cnb/employee', ['sidebar.home', 'v2.erp.index', 'v2.erp.cnb.index']),
  cnbEmployeeAdd: getPath('/erp/human-resource/cnb/employee' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.erp.index',
    'v2.erp.cnb.index'
  ]),
  cnbEmployeeEdit: getPath('/erp/human-resource/cnb/employee' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.erp.index',
    'v2.erp.cnb.index'
  ]),
  cnbEmployeeDetail: getPath('/erp/human-resource/cnb/employee' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.erp.index',
    'v2.erp.cnb.index'
  ]),
  cnbEmployeeQR: getPath('/erp/human-resource/cnb/employee' + CRUDPathSegmentEn.QR, [
    'sidebar.home',
    'v2.erp.index',
    'v2.erp.cnb.index'
  ]),
  cnbDepartment: getPath('/erp/human-resource/cnb/department', ['sidebar.home', 'v2.erp.index', 'v2.erp.cnb.index']),
  cnbJobCategory: getPath('/erp/human-resource/cnb/job-category', ['sidebar.home', 'v2.erp.index', 'v2.erp.cnb.index']),
  cnbJobCategoryAdd: getPath('/erp/human-resource/cnb/job-category' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.erp.index',
    'v2.erp.cnb.index'
  ]),
  cnbJobCategoryEdit: getPath('/erp/human-resource/cnb/job-category' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.erp.index',
    'v2.erp.cnb.index'
  ]),
  //#endregion human resource

  //#endregion human resource

  //#region sale
  sale: getPath('/erp/sale', ['sidebar.home', 'v2.erp.index', 'v2.erp.sale.index']),

  shippingPlan: getPath('/erp/sale/shipping-plan', ['sidebar.home', 'v2.sale.index', 'v2.sale.shippingPlan']),
  saleDebt: getPath('/erp/sale/debt', ['sidebar.home', 'v2.sale.index', 'v2.sale.debt']),
  saleHistoryOrder: getPath('/erp/sale/history-order', ['sidebar.home', 'v2.sale.index', 'v2.sale.saleHistoryOrder']),
  businessProposal: getPath('/erp/sale/business-proposal', [
    'sidebar.home',
    'v2.sale.index',
    'v2.sale.businessProposal'
  ]),

  saleOrder: getPath('/erp/sale/sale-order', ['sidebar.home', 'v2.sale.index', 'v2.sale.so']),
  saleOrderAdd: getPath('/erp/sale/sale-order' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.sale.index',
    'v2.sale.so',
    'action.add'
  ]),
  saleOrderEdit: getPath('/erp/sale/sale-order' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.sale.index',
    'v2.sale.so',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  saleOrderDetailCode: getPath('/erp/sale/sale-order' + CRUDPathSegmentEn.DETAIL_BY_CODE, [
    'sidebar.home',
    'v2.sale.index',
    'v2.sale.so',
    'action.' + CRUDPathSegmentEn.DETAIL_BY_CODE
  ]),

  opportunity: getPath('/erp/sale/opportunity', ['sidebar.home', 'v2.sale.index', 'v2.sale.opportunity']),
  opportunityAdd: getPath('/erp/sale/opportunity' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.sale.index',
    'v2.sale.opportunity',
    'action.add'
  ]),
  opportunityEdit: getPath('/erp/sale/opportunity' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.sale.index',
    'v2.sale.opportunity',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  opportunityDetailCode: getPath('/erp/sale/opportunity' + CRUDPathSegmentEn.DETAIL_BY_CODE, [
    'sidebar.home',
    'v2.sale.index',
    'v2.sale.opportunity',
    'action.' + CRUDPathSegmentEn.DETAIL_BY_CODE
  ]),

  sq: getPath('/erp/sale/sq', ['sidebar.home', 'v2.sale.index', 'v2.sale.sq']),
  salePr: getPath('/erp/sale/pr', ['sidebar.home', 'v2.sale.index', 'v2.sale.salePr']),

  saleWorkAssign: getPath('/erp/sale/work-assign', ['sidebar.home', 'v2.sale.index', 'v2.sale.saleWorkAssign']),

  //#endregion sale

  //#region purchase
  purchase: getPath('/erp/purchase', ['sidebar.home', 'v2.purchase.index']),

  // PR
  purchaseRequest: getPath('/erp/purchase/pr', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.pr']),
  purchaseRequestDetail: getPath('/erp/purchase/pr' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pr',
    'v2.purchase.' + CRUDPathSegmentEn.DETAIL
  ]),
  purchaseRequestDetailCode: getPath('/erp/purchase/pr' + CRUDPathSegmentEn.DETAIL_BY_CODE, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pr',
    'v2.purchase.' + CRUDPathSegmentEn.DETAIL
  ]),

  // PQR
  pqr: getPath('/erp/purchase/pqr', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.pqr']),
  pqrDetail: getPath('/erp/purchase/pqr' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pqr',
    'v2.purchase.' + CRUDPathSegmentEn.DETAIL
  ]),
  pqrDetailCode: getPath('/erp/purchase/pqr' + CRUDPathSegmentEn.DETAIL_BY_CODE, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pqr',
    'v2.purchase.' + CRUDPathSegmentEn.DETAIL
  ]),
  pqrAdd: getPath('/erp/purchase/pqr' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pqr',
    'action.add'
  ]),
  pqrEdit: getPath('/erp/purchase/pqr' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pqr',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),

  // PQ
  purchaseQuotation: getPath('/erp/purchase/pq', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.pq']),
  purchaseQuotationDetail: getPath('/erp/purchase/pq' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pq',
    'v2.purchase.' + CRUDPathSegmentEn.DETAIL
  ]),
  purchaseQuotationDetailCode: getPath('/erp/purchase/pq' + CRUDPathSegmentEn.DETAIL_BY_CODE, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pq',
    'v2.purchase.' + CRUDPathSegmentEn.DETAIL
  ]),
  purchaseQuotationAdd: getPath('/erp/purchase/pq' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pq',
    'action.add'
  ]),
  purchaseQuotationVendorAdd: getPath('/erp/purchase/pq/vendor-add', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pq',
    'action.add'
  ]),
  purchaseQuotationEdit: getPath('/erp/purchase/pq' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pq',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),

  //PO
  purchaseOrder: getPath('/erp/purchase/po', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.po']),
  purchaseOrderStamp: getPath('/erp/purchase/po-stamp', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.poStamp']),
  purchaseOrderDetail: getPath('/erp/purchase/po' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.po',
    'v2.purchase.' + CRUDPathSegmentEn.DETAIL
  ]),
  purchaseOrderDetailCode: getPath('/erp/purchase/po' + CRUDPathSegmentEn.DETAIL_BY_CODE, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.po',
    'v2.purchase.' + CRUDPathSegmentEn.DETAIL
  ]),
  purchaseOrderAdd: getPath('/erp/purchase/po' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.po',
    'action.add'
  ]),
  purchaseOrderEdit: getPath('/erp/purchase/po' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.po',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),

  grpor: getPath('/erp/purchase/grpor', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.grpor']),
  grporDetail: getPath('/erp/purchase/grpor' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.grpor',
    'v2.purchase.' + CRUDPathSegmentEn.DETAIL
  ]),
  grporDetailCode: getPath('/erp/purchase/grpor' + CRUDPathSegmentEn.DETAIL_BY_CODE, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.grpor',
    'v2.purchase.' + CRUDPathSegmentEn.DETAIL
  ]),
  grporAdd: getPath('/erp/purchase/grpor' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.grpor',
    'action.add'
  ]),
  grporEdit: getPath('/erp/purchase/grpor' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.grpor',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),

  // QC Result
  qcResult: getPath('/erp/purchase/qc-result', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.qcResult']),
  qcResultDetail: getPath('/erp/purchase/qc-result' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.qcResult',
    'v2.purchase.' + CRUDPathSegmentEn.DETAIL
  ]),
  qcResultAdd: getPath('/erp/purchase/qc-result' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.qcResult',
    'action.add'
  ]),
  qcResultEdit: getPath('/erp/purchase/qc-result' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.qcResult',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),

  // HẠCH TOÁN - ACCOUNTING
  purchaseAccounting: getPath('/erp/purchase/accounting', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.accounting'
  ]),
  purchaseAccountingDetail: getPath('/erp/purchase/accounting' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.accounting',
    'v2.purchase.' + CRUDPathSegmentEn.DETAIL
  ]),
  purchaseAccountingAdd: getPath('/erp/purchase/accounting' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.accounting',
    'action.add'
  ]),
  purchaseAccountingEdit: getPath('/erp/purchase/accounting' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.accounting',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),

  // AP Invoice
  apInvoice: getPath('/erp/purchase/ap-invoice', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.apInvoice']),
  apInvoiceDetail: getPath('/erp/purchase/ap-invoice' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.apInvoice',
    'v2.purchase.' + CRUDPathSegmentEn.DETAIL
  ]),
  apInvoiceAdd: getPath('/erp/purchase/ap-invoice' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.apInvoice',
    'action.add'
  ]),
  apInvoiceEdit: getPath('/erp/purchase/ap-invoice' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.apInvoice',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),

  receivePlan: getPath('/erp/purchase/receive-plan', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.receivePlan']),
  ordersHistory: getPath('/erp/purchase/orders-history', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.ordersHistory'
  ]),
  debtGroup: getPath('/erp/purchase/debt-group', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.debtGroup']),
  debt: getPath('/erp/purchase/debt', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.debt']),
  setOfDocument: getPath('/erp/purchase/set-of-documents', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.setOfDocument'
  ]),
  documentType: getPath('/erp/purchase/document-type', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.documentType'
  ]),
  refKey: getPath('/erp/purchase/ref-key', ['sidebar.home', 'v2.purchase.index', 'Ref key']),

  //#endregion Purchase

  //#region standards quality
  standardsQuality: getPath('/erp/standards-quality', [
    'sidebar.home',
    'v2.erp.index',
    'v2.erp.standardsQuality.index'
  ]),
  // PRODUCTION PLAN
  productionPlan: getPath('/erp/standards-quality/production-plan', [
    'sidebar.home',
    'v2.accounting.index',
    'v2.accounting.productionPlan'
  ]),
  productionPlanDetail: getPath('/erp/standards-quality/production-plan' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.accounting.index',
    'v2.accounting.productionPlan',
    'v2.accounting.' + CRUDPathSegmentEn.DETAIL
  ]),
  productionPlanAdd: getPath('/erp/standards-quality/production-plan' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.accounting.index',
    'v2.accounting.productionPlan',
    'action.add'
  ]),
  productionPlanEdit: getPath('/erp/standards-quality/production-plan' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.accounting.index',
    'v2.accounting.productionPlan',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  standard: getPath('/erp/standards-quality/standard', [
    'sidebar.home',
    'v2.accounting.index',
    'v2.accounting.standard'
  ]),
  checkList: getPath('/erp/standards-quality/check-list', [
    'sidebar.home',
    'v2.accounting.index',
    'v2.accounting.checkList'
  ]),
  //#endregion standards quality

  //#region finance
  finance: getPath('/erp/finance', ['sidebar.home', 'v2.erp.index', 'v2.erp.finance.index']),
  cashFlowPlan: getPath('/erp/finance/cash-flow-plan', [
    'sidebar.home',
    'v2.accounting.index',
    'v2.accounting.cashFlowPlan'
  ]),
  financeReport: getPath('/erp/finance/finance-report', [
    'sidebar.home',
    'v2.accounting.index',
    'v2.accounting.financeReport'
  ]),
  paymentPlan: getPath('/erp/finance/payment-plan', [
    'sidebar.home',
    'v2.accounting.index',
    'v2.accounting.paymentPlan'
  ]),
  cashFlowReport: getPath('/erp/finance/cash-flow-report', [
    'sidebar.home',
    'v2.accounting.index',
    'v2.accounting.cashFlowReport'
  ]),
  //#endregion finance

  //#region contract execution
  contractExecution: getPath('/erp/contract-execution', [
    'sidebar.home',
    'v2.erp.index',
    'v2.erp.contractExecution.index'
  ]),

  //#region project management
  projectManagement: getPath('/erp/contract-execution/project', [
    'sidebar.home',
    'v2.erp.index',
    'v2.erp.contractExecution.index',
    'v2.erp.contractExecution.projectManagement.index'
  ]),
  //#endregion project management

  //#region production management

  // PMR SPREADSHEET
  pmrSpreadsheet: getPath('/erp/contract-execution/production/pmr-spreadsheet', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.pmrSpreadsheet'
  ]),
  pmrSpreadsheetDetail: getPath('/erp/contract-execution/production/pmr-spreadsheet' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.pmrSpreadsheet',
    'v2.bom.' + CRUDPathSegmentEn.DETAIL
  ]),
  pmrSpreadsheetDetailCode: getPath('/erp/contract-execution/production/pmr-spreadsheet' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.pmrSpreadsheet',
    'v2.bom.' + CRUDPathSegmentEn.DETAIL
  ]),
  pmrSpreadsheetAdd: getPath('/erp/contract-execution/production/pmr-spreadsheet' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.pmrSpreadsheet',
    'action.add'
  ]),
  pmrSpreadsheetEdit: getPath('/erp/contract-execution/production/pmr-spreadsheet' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.pmrSpreadsheet',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),

  // RAW MATERIAL ESTIMATE
  rawMatEst: getPath('/erp/contract-execution/production/raw-material-estimate', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.rawMatEst'
  ]),
  rawMatEstDetail: getPath('/erp/contract-execution/production/raw-material-estimate' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.rawMatEst',
    'v2.bom.' + CRUDPathSegmentEn.DETAIL
  ]),
  rawMatEstDetailCode: getPath('/erp/contract-execution/production/raw-material-estimate' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.rawMatEst',
    'v2.bom.' + CRUDPathSegmentEn.DETAIL
  ]),
  rawMatEstAdd: getPath('/erp/contract-execution/production/raw-material-estimate' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.rawMatEst',
    'action.add'
  ]),
  rawMatEstEdit: getPath('/erp/contract-execution/production/raw-material-estimate' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.rawMatEst',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),

  // PMR
  pmr: getPath('/erp/contract-execution/production/pmr', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.pmr'
  ]),
  pmrDetail: getPath('/erp/contract-execution/production/pmr' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.pmr',
    'v2.bom.' + CRUDPathSegmentEn.DETAIL
  ]),
  pmrDetailCode: getPath('/erp/contract-execution/production/pmr' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.pmr',
    'v2.bom.' + CRUDPathSegmentEn.DETAIL
  ]),
  pmrAdd: getPath('/erp/contract-execution/production/pmr' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.pmr',
    'action.add'
  ]),
  pmrEdit: getPath('/erp/contract-execution/production/pmr' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.pmr',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),

  // BOM - BILL OF MATERIAL
  bom: getPath('/erp/contract-execution/production/bom', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.bom'
  ]),
  bomDetail: getPath('/erp/contract-execution/production/bom' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.bom',
    'v2.bom.' + CRUDPathSegmentEn.DETAIL
  ]),
  bomDetailCode: getPath('/erp/contract-execution/production/bom' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.bom',
    'v2.bom.' + CRUDPathSegmentEn.DETAIL
  ]),
  bomAdd: getPath('/erp/contract-execution/production/bom' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.bom',
    'action.add'
  ]),
  bomEdit: getPath('/erp/contract-execution/production/bom' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.bom',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),

  // Packaging Request
  packagingRequest: getPath('/erp/contract-execution/production/packaging-request', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.packagingRequest'
  ]),
  packagingRequestDetail: getPath('/erp/contract-execution/production/packaging-request' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.packagingRequest',
    'v2.bom.' + CRUDPathSegmentEn.DETAIL
  ]),
  packagingRequestDetailCode: getPath(
    '/erp/contract-execution/production/packaging-request' + CRUDPathSegmentEn.DETAIL,
    [
      'sidebar.home',
      'v2.contractExecution.index',
      'v2.contractExecution.productionManagement.index',
      'v2.contractExecution.productionManagement.packagingRequest',
      'v2.bom.' + CRUDPathSegmentEn.DETAIL
    ]
  ),
  packagingRequestAdd: getPath('/erp/contract-execution/production/packaging-request' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.packagingRequest',
    'action.add'
  ]),
  packagingRequestEdit: getPath('/erp/contract-execution/production/packaging-request' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.packagingRequest',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),

  // Budget plan
  productionBudgetPlan: getPath('/erp/contract-execution/production/budget-plan', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.productionBudgetPlan'
  ]),

  // Process
  productionProcess: getPath('/erp/contract-execution/production/production-process', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.productionProcess'
  ]),

  // Process
  productionQualityControl: getPath('/erp/contract-execution/production/production-quality-control', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.productionQualityControl'
  ]),

  // BOM NVL
  rawMatBom: getPath('/erp/contract-execution/production/raw-mat-bom', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.rawMatBom'
  ]),
  rawMatBomDetail: getPath('/erp/contract-execution/production/raw-mat-bom' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.rawMatBom',
    'v2.bom.' + CRUDPathSegmentEn.DETAIL
  ]),
  rawMatBomDetailCode: getPath('/erp/contract-execution/production/raw-mat-bom' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.rawMatBom',
    'v2.bom.' + CRUDPathSegmentEn.DETAIL
  ]),
  rawMatBomAdd: getPath('/erp/contract-execution/production/raw-mat-bom' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.rawMatBom',
    'action.add'
  ]),
  rawMatBomEdit: getPath('/erp/contract-execution/production/raw-mat-bom' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.rawMatBom',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),

  // BOM Nhân công
  laborBom: getPath('/erp/contract-execution/production/labor-bom', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.laborBom'
  ]),
  laborBomDetail: getPath('/erp/contract-execution/production/labor-bom' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.laborBom',
    'v2.bom.' + CRUDPathSegmentEn.DETAIL
  ]),
  laborBomDetailCode: getPath('/erp/contract-execution/production/labor-bom' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.laborBom',
    'v2.bom.' + CRUDPathSegmentEn.DETAIL
  ]),
  laborBomAdd: getPath('/erp/contract-execution/production/labor-bom' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.laborBom',
    'action.add'
  ]),
  laborBomEdit: getPath('/erp/contract-execution/production/labor-bom' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.laborBom',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),

  // BOM NVL
  equipmentBom: getPath('/erp/contract-execution/production/equipment-bom', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.equipmentBom'
  ]),
  equipmentBomDetail: getPath('/erp/contract-execution/production/equipment-bom' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.equipmentBom',
    'v2.bom.' + CRUDPathSegmentEn.DETAIL
  ]),
  equipmentBomDetailCode: getPath('/erp/contract-execution/production/equipment-bom' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.equipmentBom',
    'v2.bom.' + CRUDPathSegmentEn.DETAIL
  ]),
  equipmentBomAdd: getPath('/erp/contract-execution/production/equipment-bom' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.equipmentBom',
    'action.add'
  ]),
  equipmentBomEdit: getPath('/erp/contract-execution/production/equipment-bom' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.equipmentBom',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),

  //#endregion produciton

  //#region installation management
  installationManagement: getPath('/erp/contract-execution/installation', [
    'sidebar.home',
    'v2.erp.index',
    'v2.erp.contractExecution.index',
    'v2.erp.contractExecution.installationManagement.index'
  ]),

  // INSTALLATION ORDER
  installationOrder: getPath('/erp/contract-execution/installation/installation-order', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.installationManagement.index',
    'v2.contractExecution.installationManagement.installationOrder'
  ]),
  //INSTALLATION DELIVERY REQUEST
  installationDeliveryOrder: getPath('/erp/contract-execution/installation/delivery-order', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.installationManagement.index',
    'v2.contractExecution.installationManagement.installationDeliveryOrder'
  ]),
  //INSTALLATION REQUEST
  installationRequest: getPath('/erp/contract-execution/installation/installation-request', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.installationManagement.index',
    'v2.contractExecution.installationManagement.installationRequest'
  ]),
  //WAREHOUSE TRANSFER REQUEST
  installationWareHouseTransferRequest: getPath('/erp/contract-execution/installation/warehouse-transfer-request', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.installationManagement.index',
    'v2.contractExecution.installationManagement.installationWareHouseTransferRequest'
  ]),
  //INSTALLATION BUDGET PLANNING
  installationBudgetPlan: getPath('/erp/contract-execution/installation/installation-budget-plan', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.installationManagement.index',
    'v2.contractExecution.installationManagement.installationBudgetPlan'
  ]),
  //INSTALLATION PROCESS
  installationProcess: getPath('/erp/contract-execution/installation/installation-process', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.installationManagement.index',
    'v2.contractExecution.installationManagement.installationProcess'
  ]),
  //INSTALLATION QUALITY CONTROL
  installationQualityControl: getPath('/erp/contract-execution/installation/quality-control', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.installationManagement.index',
    'v2.contractExecution.installationManagement.installationQualityControl'
  ]),
  //INSTALLATION REPORT
  installationReport: getPath('/erp/contract-execution/installation/installation-report', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.installationManagement.index',
    'v2.contractExecution.installationManagement.installationReport'
  ]),
  //#endregion installation management

  //#region maintenance management
  maintenanceManagement: getPath('/erp/contract-execution/maintenance', [
    'sidebar.home',
    'v2.erp.index',
    'v2.erp.contractExecution.index',
    'v2.erp.contractExecution.maintenanceManagement.index'
  ]),

  // MAINTENANCE ORDER
  maintenanceOrder: getPath('/erp/contract-execution/maintenance/maintenance-order', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.maintenanceManagement.index',
    'v2.contractExecution.maintenanceManagement.maintenanceOrder'
  ]),
  //MAINTENANCE DELIVERY REQUEST
  maintenanceDeliveryOrder: getPath('/erp/contract-execution/maintenance/delivery-order', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.maintenanceManagement.index',
    'v2.contractExecution.maintenanceManagement.maintenanceDeliveryOrder'
  ]),
  //MAINTENANCE REQUEST
  maintenanceRequest: getPath('/erp/contract-execution/maintenance/maintenance-request', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.maintenanceManagement.index',
    'v2.contractExecution.maintenanceManagement.maintenanceRequest'
  ]),
  //WAREHOUSE TRANSFER REQUEST
  maintenanceWareHouseTransferRequest: getPath('/erp/contract-execution/maintenance/warehouse-transfer-request', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.maintenanceManagement.index',
    'v2.contractExecution.maintenanceManagement.maintenanceWareHouseTransferRequest'
  ]),
  //MAINTENANCE BUDGET PLANNING
  maintenanceBudgetPlan: getPath('/erp/contract-execution/maintenance/maintenance-budget-plan', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.maintenanceManagement.index',
    'v2.contractExecution.maintenanceManagement.maintenanceBudgetPlan'
  ]),
  //MAINTENANCE PROCESS
  maintenanceProcess: getPath('/erp/contract-execution/maintenance/maintenance-process', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.maintenanceManagement.index',
    'v2.contractExecution.maintenanceManagement.maintenanceProcess'
  ]),
  //MAINTENANCE QUALITY CONTROL
  maintenanceQualityControl: getPath('/erp/contract-execution/maintenance/quality-control', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.maintenanceManagement.index',
    'v2.contractExecution.maintenanceManagement.maintenanceQualityControl'
  ]),
  //MAINTENANCE REPORT
  maintenanceReport: getPath('/erp/contract-execution/maintenance/maintenance-report', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.maintenanceManagement.index',
    'v2.contractExecution.maintenanceManagement.maintenanceReport'
  ]),
  //#endregion maintenance management

  //#region delivery management
  deliveryManagement: getPath('/erp/contract-execution/delivery', [
    'sidebar.home',
    'v2.erp.index',
    'v2.erp.contractExecution.index',
    'v2.erp.contractExecution.deliveryManagement.index'
  ]),

  // DELIVERY ORDER
  deliveryOrder: getPath('/erp/contract-execution/delivery/delivery-order', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.deliveryManagement.index',
    'v2.contractExecution.deliveryManagement.deliveryOrder'
  ]),

  // DELIVERY REQUEST
  deliveryRequest: getPath('/erp/contract-execution/delivery/delivery-request', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.deliveryManagement.index',
    'v2.contractExecution.deliveryManagement.deliveryRequest'
  ]),
  //DELIVERY TRANSFER REQUEST
  deliveryWareHouseTransferRequest: getPath('/erp/contract-execution/delivery/warehouse-transfer-request', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.deliveryManagement.index',
    'v2.contractExecution.deliveryManagement.deliveryWareHouseTransferRequest'
  ]),
  //DELIVERY BUDGET PLANNING
  deliveryBudgetPlan: getPath('/erp/contract-execution/delivery/delivery-budget-plan', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.deliveryManagement.index',
    'v2.contractExecution.deliveryManagement.deliveryBudgetPlan'
  ]),
  //DELIVERY PROCESS
  deliveryProcess: getPath('/erp/contract-execution/delivery/delivery-process', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.deliveryManagement.index',
    'v2.contractExecution.deliveryManagement.deliveryProcess'
  ]),
  //DELIVERY QUALITY CONTROL
  deliveryQualityControl: getPath('/erp/contract-execution/delivery/quality-control', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.deliveryManagement.index',
    'v2.contractExecution.deliveryManagement.deliveryQualityControl'
  ]),
  //DELIVERY REPORT
  deliveryReport: getPath('/erp/contract-execution/delivery/delivery-report', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.deliveryManagement.index',
    'v2.contractExecution.deliveryManagement.deliveryReport'
  ]),
  //#endregion delivery management

  //#endregion contract execution

  //#region item master data
  itemMasterData: getPath('/erp/item-master-data', ['sidebar.home', 'v2.erp.index', 'v2.erp.itemMasterData.index']),

  // Item
  item: getPath('/erp/item-master-data/item', ['sidebar.home', 'v2.erp.index', 'v2.erp.itemMasterData.index']),
  itemAdd: getPath('/erp/item-master-data/item' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.itemMasterData.index',
    'action.add'
  ]),
  itemEdit: getPath('/erp/item-master-data/item' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.itemMasterData.index',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  itemDetail: getPath('/erp/item-master-data/item' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.itemMasterData.index',
    'v2.item.' + CRUDPathSegmentEn.DETAIL
  ]),

  // Item Type
  itemType: getPath('/erp/item-master-data/item-type', [
    'sidebar.home',
    'v2.itemMasterData.index',
    'v2.itemMasterData.itemType'
  ]),
  itemTypeAdd: getPath('/erp/item-master-data/item-type' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.itemMasterData.index',
    'v2.itemMasterData.itemType',
    'action.add'
  ]),
  itemTypeEdit: getPath('/erp/item-master-data/item-type' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.itemMasterData.index',
    'v2.itemMasterData.itemType',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  itemTypeDetail: getPath('/erp/item-master-data/item-type' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.itemMasterData.index',
    'v2.itemMasterData.itemType',
    'v2.item.' + CRUDPathSegmentEn.DETAIL
  ]),

  // Item Group
  itemGroup: getPath('/erp/item-master-data/item-group', [
    'sidebar.home',
    'v2.itemMasterData.index',
    'v2.itemMasterData.itemGroup'
  ]),
  itemGroupAdd: getPath('/erp/item-master-data/item-group' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.itemMasterData.index',
    'v2.itemMasterData.itemGroup',
    'action.add'
  ]),
  itemGroupEdit: getPath('/erp/item-master-data/item-group' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.itemMasterData.index',
    'v2.itemMasterData.itemGroup',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  itemGroupDetail: getPath('/erp/item-master-data/item-group' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.itemMasterData.index',
    'v2.itemMasterData.itemGroup',
    'v2.item.' + CRUDPathSegmentEn.DETAIL
  ]),

  // MODEL
  model: getPath('/erp/item-master-data/model', ['sidebar.home', 'v2.itemMasterData.index', 'v2.itemMasterData.model']),
  modelAdd: getPath('/erp/item-master-data/model' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.itemMasterData.index',
    'v2.itemMasterData.model',
    'action.add'
  ]),
  modelEdit: getPath('/erp/item-master-data/model' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.itemMasterData.index',
    'v2.itemMasterData.model',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  modelDetail: getPath('/erp/item-master-data/model' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.itemMasterData.index',
    'v2.itemMasterData.model',
    'v2.item.' + CRUDPathSegmentEn.DETAIL
  ]),
  // ITEM SPEC
  itemSpec: getPath('/erp/item-master-data/item-spec', [
    'sidebar.home',
    'v2.itemMasterData.index',
    'v2.itemMasterData.itemSpec'
  ]),
  itemSpecAdd: getPath('/erp/item-master-data/item-spec' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.itemMasterData.index',
    'v2.itemMasterData.itemSpec',
    'action.add'
  ]),
  itemSpecEdit: getPath('/erp/item-master-data/item-spec' + CRUDPathSegmentEn.EDIT, [
    'sidebar.home',
    'v2.itemMasterData.index',
    'v2.itemMasterData.itemSpec',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  itemSpecDetail: getPath('/erp/item-master-data/item-spec' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.erp.index',
    'v2.itemMasterData.index',
    'v2.itemMasterData.itemSpec',
    'v2.item.' + CRUDPathSegmentEn.DETAIL
  ]),
  // ACCOUNTING ITEM
  accountingItem: getPath('/erp/item-master-data/accounting-item', [
    'sidebar.home',
    'v2.erp.index',
    'v2.itemMasterData.index',
    'v2.itemMasterData.accountingItem'
  ]),
  // SPECIFICATION
  specification: getPath('/erp/item-master-data/specification', [
    'sidebar.home',
    'v2.erp.index',
    'v2.itemMasterData.index',
    'v2.itemMasterData.specification'
  ]),
  // ITEM TECHNICAL SPECIFICATION
  itemTechnicalSpecification: getPath('/erp/item-master-data/item-technical-specification', [
    'sidebar.home',
    'v2.erp.index',
    'v2.itemMasterData.index',
    'v2.itemMasterData.itemTechnicalSpecification'
  ]),
  //#endregion item master data

  //#endregion erp

  //#region mes
  mes: getPath('/mes', ['sidebar.home', 'v2.mes.index']),

  //#endregion mes

  //#region interactive
  interactive: getPath('/interactive', ['sidebar.home', 'v2.interactive.index']),

  systemAdmin: getPath('/interactive/system-admin', [
    'sidebar.home',
    'v2.interactive.index',
    'v2.crm.systemAdmin.index'
  ]),

  //#region Ticket
  ticketType: getPath('/interactive/system-admin/ticket-type', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'sidebar.ticket.ticketType'
  ]),
  ticketCategoryGroup: getPath('/interactive/system-admin/ticket-category-group', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'sidebar.ticket.ticketCategoryGroup'
  ]),
  ticketCategory: getPath('/interactive/system-admin/ticket-category', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'sidebar.ticket.ticketCategory'
  ]),
  ticketCategoryEdit: getPath('/interactive/system-admin/ticket-category' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'sidebar.ticket.ticketCategory',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  ticketCategoryAdd: getPath('/interactive/system-admin/ticket-category' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'sidebar.ticket.ticketCategory',
    'action.add'
  ]),
  ticketCategoryDetail: getPath('/interactive/system-admin/ticket-category' + CRUDPathSegmentEn.DETAIL, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'sidebar.ticket.ticketCategory',
    'page.' + CRUDPathSegmentEn.DETAIL
  ]),
  userTicketCategory: getPath('/interactive/system-admin/user-ticket-category', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'sidebar.ticket.userTicketCategory'
  ]),
  ticketForm: getPath('/interactive/system-admin/ticket-form', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'sidebar.ticket.ticketForm'
  ]),
  ticketFormAdd: getPath('/interactive/system-admin/ticket-form' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'sidebar.ticket.ticketForm',
    'action.add'
  ]),
  ticketFormEdit: getPath('/interactive/system-admin/ticket-form' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'sidebar.ticket.ticketForm',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  ticketFormDetail: getPath('/interactive/system-admin/ticket-form' + CRUDPathSegmentEn.DETAIL, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'sidebar.ticket.ticketForm',
    'page.' + CRUDPathSegmentEn.DETAIL
  ]),
  ticketFormMapping: getPath('/interactive/system-admin/ticket-form-mapping', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'sidebar.ticket.ticketFormMapping'
  ]),
  reason: getPath('/interactive/system-admin/reason', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'sidebar.ticket.reason'
  ]),
  //#endregion

  //#region Hr ticket
  biometricLog: getPath('/interactive/system-admin/biometric-log', [
    'sidebar.hr.biometric.index',
    'sidebar.hr.biometricLog'
  ]),
  biometricEmployeeMapping: getPath('/interactive/system-admin/biometric-employee-mapping', [
    'sidebar.hr.biometric.index',
    'sidebar.hr.biometricEmployeeMapping'
  ]),
  advancePaymentLimit: getPath('/interactive/system-admin/advance-payment-limit', [
    'v2.sidebar.home',
    'sidebar.hr.index',
    'sidebar.hr.advancePaymentLimit'
  ]),
  employeeEvaluation: getPath('/interactive/system-admin/employee-evaluation', [
    'v2.sidebar.home',
    'sidebar.hr.index',
    'sidebar.hr.employeeEvaluation'
  ]),
  leaveRemainingYear: getPath('/interactive/system-admin/leave-remaining-year', [
    'sidebar.home',
    'v2.leaveRemainingYear.index'
  ]),
  employeeShift: getPath('/interactive/system-admin/employee-shift', ['sidebar.home', 'v2.employeeShift.index']),
  advancePaymentEmployee: getPath('/interactive/system-admin/advance-payment-employee', [
    'sidebar.home',
    'v2.advancePaymentEmployee.index'
  ]),
  leaveTicketPackage: getPath('/interactive/system-admin/leave-ticket-package', [
    'sidebar.home',
    'v2.leaveTicketPackage.index'
  ]),
  leaveTicketPackageAdd: getPath('/interactive/system-admin/leave-ticket-package' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.leaveTicketPackage.index',
    'action.add'
  ]),
  leaveTicketPackageDetail: getPath('/interactive/system-admin/leave-ticket-package' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.leaveTicketPackage.index',
    'page.' + CRUDPathSegmentEn.DETAIL
  ]),
  additionalWorkingTimePackage: getPath('/interactive/system-admin/additional-working-time-package', [
    'sidebar.home',
    'v2.additionalWorkingTimePackage.index'
  ]),
  additionalWorkingTimePackageAdd: getPath(
    '/interactive/system-admin/additional-working-time-package' + CRUDPathSegmentEn.ADD,
    ['sidebar.home', 'v2.additionalWorkingTimePackage.index', 'action.add']
  ),
  additionalWorkingTimePackageDetail: getPath(
    '/interactive/system-admin/additional-working-time-package' + CRUDPathSegmentEn.DETAIL,
    ['sidebar.home', 'v2.additionalWorkingTimePackage.index', 'page.' + CRUDPathSegmentEn.DETAIL]
  ),
  additionalWage: getPath('/interactive/system-admin/additional-wage', ['sidebar.home', 'v2.additionalWage.index']),
  employeeCompensation: getPath('/interactive/system-admin/employee-compensation', [
    'sidebar.home',
    'v2.employeeCompensation.index'
  ]),
  timesheet: getPath('/interactive/system-admin/timesheet', ['sidebar.home', 'sidebar.hr.timesheet']),
  timesheetAdd: getPath('/interactive/system-admin/timesheet' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.timesheet.index',
    'action.add'
  ]),
  timesheetDetail: getPath('/interactive/system-admin/timesheet' + CRUDPathSegmentEn.DETAIL, [
    'sidebar.home',
    'v2.timesheet.index',
    'page.' + CRUDPathSegmentEn.DETAIL
  ]),
  //#endregion

  //#region Administration
  approvalProcess: getPath('/interactive/system-admin/approval-process', [
    'v2.sidebar.home',
    'sidebar.administration.index',
    'sidebar.administration.approvalProcess'
  ]),
  approvalProcessAdd: getPath('/interactive/system-admin/approval-process' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'sidebar.administration.index',
    'sidebar.administration.approvalProcess',
    'action.add'
  ]),
  approvalProcessEdit: getPath('/interactive/system-admin/approval-process' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'sidebar.administration.index',
    'sidebar.administration.approvalProcess',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  approvalProcessDetail: getPath('/interactive/system-admin/approval-process' + CRUDPathSegmentEn.DETAIL, [
    'v2.sidebar.home',
    'sidebar.administration.index',
    'sidebar.administration.approvalProcess',
    'page.' + CRUDPathSegmentEn.DETAIL
  ]),
  handleProcess: getPath('/interactive/system-admin/handle-process', [
    'v2.sidebar.home',
    'sidebar.administration.index',
    'sidebar.administration.handleProcess'
  ]),
  handleProcessAdd: getPath('/interactive/system-admin/handle-process' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'sidebar.administration.index',
    'sidebar.administration.handleProcess',
    'action.add'
  ]),
  handleProcessEdit: getPath('/interactive/system-admin/handle-process' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'sidebar.administration.index',
    'sidebar.administration.handleProcess',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  template: getPath('/interactive/system-admin/template', [
    'v2.sidebar.home',
    'sidebar.administration.index',
    'sidebar.administration.template'
  ]),
  templateAdd: getPath('/interactive/system-admin/template' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'sidebar.administration.index',
    'sidebar.administration.template',
    'action.add'
  ]),
  templateEdit: getPath('/interactive/system-admin/template' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'sidebar.administration.index',
    'sidebar.administration.template',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  //#endregion

  //#region Master data
  employee: getPath('/interactive/system-admin/employee', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.employee'
  ]),
  employeeAdd: getPath('/interactive/system-admin/employee' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.employee',
    'action.add'
  ]),
  employeeQR: getPath('/interactive/system-admin/employee/qr', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.employee',
    'action.getQR'
  ]),
  employeeEdit: getPath('/interactive/system-admin/employee' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.employee',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  employeeDetail: getPath('/interactive/system-admin/employee' + CRUDPathSegmentEn.DETAIL, [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.employee',
    'page.' + CRUDPathSegmentEn.DETAIL
  ]),
  department: getPath('/interactive/system-admin/department', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.department'
  ]),
  branch: getPath('/interactive/system-admin/branch', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.branch'
  ]),
  position: getPath('/interactive/system-admin/position', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.position'
  ]),
  board: getPath('/interactive/system-admin/board', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.board'
  ]),
  level: getPath('/interactive/system-admin/level', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.level'
  ]),
  tier: getPath('/interactive/system-admin/tier', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.tier'
  ]),
  jobCategory: getPath('/interactive/system-admin/job-category', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.jobCategory'
  ]),
  jobCategoryAdd: getPath('/interactive/system-admin/job-category' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.jobCategory',
    'action.add'
  ]),
  jobCategoryEdit: getPath('/interactive/system-admin/job-category' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.jobCategory',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  organizationChart: getPath('/interactive/system-admin/organization-chart', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.organizationChart'
  ]),
  asset: getPath('/interactive/system-admin/asset', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.asset'
  ]),
  degree: getPath('/interactive/system-admin/degree', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.degree'
  ]),
  partnerType: getPath('/interactive/system-admin/partner-type', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.partnerType'
  ]),
  partner: getPath('/interactive/system-admin/partner', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.partner'
  ]),
  partnerAdd: getPath('/interactive/system-admin/partner' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.partner',
    'action.add'
  ]),
  partnerEdit: getPath('/interactive/system-admin/partner' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.partner',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  accountingAccountType: getPath('/interactive/system-admin/accounting-account-type', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.accountingAccountType'
  ]),
  accountingObjectType: getPath('/interactive/system-admin/accounting-object-type', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.accountingObjectType'
  ]),
  generalAccountingAccount: getPath('/interactive/system-admin/general-accounting-account', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.generalAccountingAccount'
  ]),
  employeeContract: getPath('/interactive/system-admin/employee-contract', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.employeeContract'
  ]),
  contractType: getPath('/interactive/system-admin/contract-type', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.contractType'
  ]),
  applicationInfo: getPath('/interactive/system-admin/application-info', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.applicationInfo'
  ]),
  applicationInfoAdd: getPath('/interactive/system-admin/application-info' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.applicationInfo',
    'action.add'
  ]),
  applicationInfoEdit: getPath('/interactive/system-admin/application-info' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.applicationInfo',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  applicationInfoDetail: getPath('/interactive/system-admin/application-info' + CRUDPathSegmentEn.DETAIL, [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.applicationInfo',
    'page.' + CRUDPathSegmentEn.DETAIL
  ]),
  certificate: getPath('/interactive/system-admin/certificate', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.certificate'
  ]),
  errorCode: getPath('/interactive/system-admin/error-code', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.errorCode'
  ]),
  hrTicketCategory: getPath('/interactive/system-admin/hr-category', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.hrTicketCategory'
  ]),
  resource: getPath('/interactive/system-admin/resource', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.resource'
  ]),
  applicationFunction: getPath('/interactive/system-admin/application-function', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.applicationFunction'
  ]),
  role: getPath('/interactive/system-admin/role', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.role'
  ]),
  roleAdd: getPath('/interactive/system-admin/role' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.role',
    'action.add'
  ]),
  roleEdit: getPath('/interactive/system-admin/role' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.role',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  userResource: getPath('/interactive/system-admin/user-resource', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.userResource'
  ]),
  userResourceAdd: getPath('/interactive/system-admin/user-resource' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.userResource',
    'action.add'
  ]),
  userResourceEdit: getPath('/interactive/system-admin/user-resource' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.userResource',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  permission: getPath('/interactive/system-admin/permission', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.permission'
  ]),

  biometricDevice: getPath('/interactive/system-admin/biometric-device', [
    'sidebar.hr.biometric.index',
    'sidebar.master-data.biometricDevice'
  ]),
  distance: getPath('/interactive/system-admin/distance', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.distance'
  ]),
  holiday: getPath('/interactive/system-admin/holiday', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.holiday'
  ]),
  fuelConsumption: getPath('/interactive/system-admin/fuel-consumption', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.fuelConsumption'
  ]),
  fuelUnitPrice: getPath('/interactive/system-admin/fuel-unit-price', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.fuelUnitPrice'
  ]),
  subSystem: getPath('/interactive/system-admin/sub-system', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.subSystem'
  ]),
  subSystemAdd: getPath('/interactive/system-admin/sub-system' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.subSystem',
    'action.add'
  ]),
  subSystemEdit: getPath('/interactive/system-admin/sub-system' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.subSystem',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  workDescription: getPath('/interactive/system-admin/work-description', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.workDescription'
  ]),
  userJobCategory: getPath('/interactive/system-admin/user-job-category', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.userJobCategory'
  ]),
  shift: getPath('/interactive/system-admin/shift', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.shift'
  ]),
  managerAssignment: getPath('/interactive/system-admin/manager-assignment', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.managerAssignment'
  ]),
  wareHouseGroup: getPath('/interactive/system-admin/ware-house-group', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.wareHouseGroup'
  ]),
  wareHouse: getPath('/interactive/system-admin/ware-house', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.wareHouse'
  ]),
  mobileVersion: getPath('/interactive/system-admin/mobile-versions', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.mobileVersion'
  ]),
  productionUnit: getPath('/interactive/system-admin/production-unit', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.productionUnit'
  ]),
  productionUnitAdd: getPath('/interactive/system-admin/production-unit' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.productionUnit',
    'action.add'
  ]),
  productionUnitEdit: getPath('/interactive/system-admin/production-unit' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.productionUnit',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  //#endregion

  //#endregion interactive

  //#region My Profile
  biometricLogV2: getPath('/profile/biometric-log', ['v2.sidebar.home', 'v2.sidebar.hrTicket.biometricLog']),

  // USER GUIDE
  userGuideV2: getPath('/profile/user-guide', ['sidebar.home', 'v2.userGuide.index']),
  userGuideAddV2: getPath('/profile/user-guide' + CRUDPathSegmentEn.ADD, [
    'sidebar.home',
    'v2.userGuide.index',
    'action.add'
  ]),

  //#endregion My Profile

  //#region Sub
  homeSub: getPath('/s', ['v2.sidebar.home']),

  //#region QR SCANNER
  qrScanner: getPath('/s/qr-scanner', ['v2.sidebar.home', 'v2.sidebar.qrScanner.index']),
  //#endregion QR SCANNER

  biometricLogSub: getPath('/s/biometric-log', ['v2.sidebar.home', 'v2.sidebar.hrTicket.biometricLog']),

  //#region LEAVE TICKET
  leaveTicketSub: getPath('/s/hr-ticket/leave-ticket', [
    'v2.sidebar.home',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.leaveTicket'
  ]),
  myLeaveTicketSub: getPath('/s/hr-ticket/leave-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.leaveTicket',
    'Phiếu của tôi'
  ]),
  leaveTicketAddSub: getPath('/s/hr-ticket/leave-ticket' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.leaveTicket',
    'action.add'
  ]),
  leaveTicketEditSub: getPath('/s/hr-ticket/leave-ticket' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.leaveTicket',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  //#endregion LEAVE TICKET
  //#endregion Sub

  //#region Topic
  topic: getPath('/topic', ['sidebar.home', 'v2.topic.index']),
  //#endregion Topic

  //#region Report
  report: getPath('/report', ['sidebar.home', 'v2.report.index']),
  reportLeaveTicket: getPath('/report/leave-ticket', ['sidebar.home', 'v2.report.index', 'v2.report.leaveTicket']),
  reportOvertimeTicket: getPath('/report/overtime-ticket', [
    'sidebar.home',
    'v2.report.index',
    'v2.report.overtimeTicket'
  ]),
  reportBusinessTripTicket: getPath('/report/business-trip-ticket', [
    'sidebar.home',
    'v2.report.index',
    'v2.report.businessTripTicket'
  ]),
  reportDailyInventoryJournal: getPath('/report/daily-inventory-journal', [
    'sidebar.home',
    'v2.report.index',
    'v2.report.dailyInventoryJournal'
  ]),
  //#endregion Report

  //#region COPORATE OPERATION
  corporateOperationV2: getPath('/coporate-opertaion', ['sidebar.home', 'v2.coporateOpertaion.index']),
  //#region Handover ticket
  handoverTicketV2: getPath('/coporate-opertaion/asset-management/handover-ticket', [
    'v2.sidebar.home',
    'v2.assetManagement.index',
    'v2.assetManagement.handoverTicket'
  ]),
  myHandoverTicketV2: getPath('/coporate-opertaion/asset-management/handover-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.assetManagement.index',
    'v2.assetManagement.handoverTicket',
    'Phiếu của tôi'
  ]),
  handoverTicketApprovalV2: getPath('/coporate-opertaion/asset-management/handover-ticket/confirm', [
    'v2.sidebar.home',
    'v2.assetManagement.index',
    'v2.assetManagement.handoverTicket',
    'v2.sidebar.confirm'
  ]),
  handoverTicketAddV2: getPath('/coporate-opertaion/asset-management/handover-ticket' + CRUDPathSegmentEn.ADD, [
    'v2.sidebar.home',
    'v2.assetManagement.index',
    'v2.assetManagement.handoverTicket',
    'action.add'
  ]),
  handoverTicketEditV2: getPath('/coporate-opertaion/asset-management/handover-ticket' + CRUDPathSegmentEn.EDIT, [
    'v2.sidebar.home',
    'v2.assetManagement.index',
    'v2.assetManagement.handoverTicket',
    'action.' + CRUDPathSegmentEn.EDIT
  ]),
  //#endregion
  //#endregion COPORATE OPERATION

  //#region Signin
  logout: getPath('/logout', []),
  signinOidc: getPath('/signin-oidc', []),
  signoutOidc: getPath('/signout-oidc', []),
  signoutCallbackOidc: getPath('/signout-callback-oidc', []),
  //#endregion

  //#region Server Error
  serverError: getPath('/server-error', [])
  //#endregion
};

export default urlPath;
