import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getJobCategoriesDropdownByEmployee } from 'src/apis/master_data/jobCategory.api';
import { SelectProps } from 'src/types/elements.type';

type Props = SelectProps & {
  employeeId?: number;
  value?: string | undefined;
};

export default function JobCategorySelectV2({
  value,
  employeeId,
  onChange,
  style,
  allowClear = true,
  placeholder
}: Props) {
  const jobCategoryDropdownQuery = useQuery({
    queryKey: ['jobCategoryDropdown', employeeId],
    queryFn: () => getJobCategoriesDropdownByEmployee(Number(employeeId)),
    staleTime: 60 * 1000,
    enabled: !!employeeId
  });

  return (
    <Select
      allowClear={allowClear}
      value={jobCategoryDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={jobCategoryDropdownQuery.data?.data.map((data) => ({ value: String(data.id), label: data.name }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={jobCategoryDropdownQuery.isFetching}
      placeholder={placeholder}
    />
  );
}
