import { CRUDPathSegmentEn } from '../newFeatures/crudPathSegment.enum';

const rndPath = {
  rnd: '/erp/rnd',

  // Raw Material PMR - PMR Nguyên vật liệu
  rawMatPmr: '/erp/rnd/raw-mat-pmr',
  rawMatPmrAdd: '/erp/rnd/raw-mat-pmr' + CRUDPathSegmentEn.ADD,
  rawMatPmrDetail: '/erp/rnd/raw-mat-pmr' + CRUDPathSegmentEn.DETAIL,
  rawMatPmrDetailCode: '/erp/rnd/raw-mat-pmr' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  rawMatPmrEdit: '/erp/rnd/raw-mat-pmr' + CRUDPathSegmentEn.EDIT,

  // Labor PMR - PMR Nhân công
  laborPmr: '/erp/rnd/labor-pmr',
  laborPmrAdd: '/erp/rnd/labor-pmr' + CRUDPathSegmentEn.ADD,
  laborPmrDetail: '/erp/rnd/labor-pmr' + CRUDPathSegmentEn.DETAIL,
  laborPmrDetailCode: '/erp/rnd/labor-pmr' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  laborPmrEdit: '/erp/rnd/labor-pmr' + CRUDPathSegmentEn.EDIT,

  // Equipment PMR - PMR công cụ dụng cụ
  equipmentPmr: '/erp/rnd/equipment-pmr',
  equipmentPmrAdd: '/erp/rnd/equipment-pmr' + CRUDPathSegmentEn.ADD,
  equipmentPmrDetail: '/erp/rnd/equipment-pmr' + CRUDPathSegmentEn.DETAIL,
  equipmentPmrDetailCode: '/erp/rnd/equipment-pmr' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  equipmentPmrEdit: '/erp/rnd/equipment-pmr' + CRUDPathSegmentEn.EDIT,

  // Production Process - Quy trình sản xuất
  rndProductionProcess: '/erp/rnd/production-process',
  rndProductionProcessAdd: '/erp/rnd/production-process' + CRUDPathSegmentEn.ADD,
  rndProductionProcessDetail: '/erp/rnd/production-process' + CRUDPathSegmentEn.DETAIL,
  rndProductionProcessDetailCode: '/erp/rnd/production-process' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  rndProductionProcessEdit: '/erp/rnd/production-process' + CRUDPathSegmentEn.EDIT,

  itemProductRnd: '/erp/rnd/item-product',
  itemMaterialRnd: '/erp/rnd/item-material',

  modelRnd: '/erp/rnd/model',

  itemSpecRnd: '/erp/rnd/item-spec'
};
export default rndPath;
