import { FilterFilled, FilterTwoTone, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, DatePicker, Descriptions, Flex, Popconfirm, Space, Table, Typography } from 'antd';
import { default as vi } from 'antd/es/date-picker/locale/vi_VN';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/es/table/interface';
import dayjs, { Dayjs } from 'dayjs';
import { TFunction } from 'i18next';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteEmployeeCompensation, getEmployeeCompensations } from 'src/apis/hr_ticket/employeeCompensation.api';
import { DepartmentTreeSelect } from 'src/components/v2/Select';
import usePermission from 'src/hooks/usePermission';
import { useUpdateSearchParams } from 'src/hooks/useUpdateSearchParams';
import { IEmployeeCompensation } from 'src/types/hr_ticket/employeeCompensation.type';
import { SearchParamType } from 'src/types/searchParams.type';
import type { TableParams } from 'src/types/tableParams.type';
import { showNotification } from 'src/utils/notification';
import { formatDateTime, formatToVND, getOrderString, getParamItem } from 'src/utils/utils';
import AddEmployeeCompensation from './AddEmployeeCompensation';
import './EmployeeCompensation.scss';

const { Text } = Typography;

const columns = function (
  callbackEdit: (id: number) => void,
  callbackDelete: (id: number) => void,
  isUpdate: boolean,
  isDelete: boolean,
  t: TFunction<'translation', undefined, 'translation'>,
  searchParams: URLSearchParams,
  updateSearchParams: (params: SearchParamType[]) => void,
  departmentFilterRef: React.RefObject<HTMLDivElement>,
  periodFilterRef: React.RefObject<HTMLDivElement>,
  openDepartmentFilter: boolean,
  setOpenDepartmentFilter: Dispatch<SetStateAction<boolean>>,
  openPeriodFilter: boolean,
  setOpenPeriodFilter: Dispatch<SetStateAction<boolean>>
): ColumnsType<IEmployeeCompensation> {
  const columnTitle: ColumnsType<IEmployeeCompensation> = [
    {
      title: t(`columnTitle.employeeCompensation.id`),
      dataIndex: 'id',
      sorter: true,
      key: 'id',
      width: '5%'
    },
    {
      title: t(`columnTitle.employeeCompensation.employee`),
      dataIndex: 'employeeId',
      key: 'employeeId',
      width: '20%',
      render: (_: string, record: IEmployeeCompensation) => (
        <Text>{`${record.employee?.code} - ${record?.employee?.fullName}`}</Text>
      ),
      filterDropdownOpen: openDepartmentFilter,
      filterDropdown: () => (
        <div
          style={{
            padding: 10
          }}
          ref={departmentFilterRef}
        >
          <DepartmentTreeSelect
            allowClear
            style={{ width: 250 }}
            value={Number(searchParams.get('departmentId')) || undefined}
            placeholder={t('placeholder.filter.department').toString()}
            onChange={(value) => {
              updateSearchParams([getParamItem('departmentId', value || undefined)]);
              updateSearchParams([getParamItem('page', 1)]);
              setOpenDepartmentFilter(false);
            }}
          />
        </div>
      ),
      filterIcon: searchParams.get('departmentId') ? (
        <FilterTwoTone style={{ fontSize: 14 }} onClick={() => setOpenDepartmentFilter(true)} />
      ) : (
        <FilterFilled style={{ fontSize: 12 }} onClick={() => setOpenDepartmentFilter(true)} />
      )
    },
    {
      title: 'Kỳ chấm công',
      dataIndex: 'period',
      sorter: false,
      key: 'period',
      width: '15%',
      render: (_: number, record: IEmployeeCompensation) => `${record.month}/${record.year}`,
      filterDropdownOpen: openPeriodFilter,
      filterDropdown: () => (
        <div style={{ padding: 10 }} ref={periodFilterRef}>
          <DatePicker
            value={
              searchParams.get('year') && searchParams.get('month')
                ? dayjs(`${searchParams.get('year')}-${searchParams.get('month')}`)
                : undefined
            }
            onChange={(value: Dayjs | null) => {
              updateSearchParams([getParamItem('month', dayjs(value).month() + 1)]);
              updateSearchParams([getParamItem('year', dayjs(value).year())]);
              updateSearchParams([getParamItem('page', 1)]);
              setOpenPeriodFilter(false);
            }}
            style={{ width: 200 }}
            locale={vi}
            allowClear={true}
            picker='month'
            format={'MM/YYYY'}
            placeholder={t('placeholder.biometricLog.month').toString()}
          />
        </div>
      ),
      filterIcon:
        searchParams.get('month') && searchParams.get('month') ? (
          <FilterTwoTone style={{ fontSize: 14 }} onClick={() => setOpenPeriodFilter(true)} />
        ) : (
          <FilterFilled style={{ fontSize: 12 }} onClick={() => setOpenPeriodFilter(true)} />
        )
    },
    {
      title: t(`columnTitle.employeeCompensation.compensationType`),
      dataIndex: 'compensationTypeId',
      sorter: true,
      key: 'compensationTypeId',
      width: '20%',
      render: (_: string, record: IEmployeeCompensation) => <Text>{record.compensationType?.name}</Text>
    },
    {
      title: t(`columnTitle.employeeCompensation.amount`),
      dataIndex: 'amount',
      sorter: true,
      key: 'amount',
      width: '15%',
      render: (amount: string) => `${formatToVND(amount)} ${t('value.vnd')}`
    },
    {
      title: 'Thao tác',
      key: 'action',
      width: '10%',
      render: (_: string, record: IEmployeeCompensation) => (
        <Space size='small'>
          {isUpdate && (
            <Button type='link' size='small' onClick={() => callbackEdit(record.id)}>
              {t('action.edit')}
            </Button>
          )}
          {isDelete && (
            <Popconfirm title={t('notification.warning.delete')} onConfirm={() => callbackDelete(record.id)}>
              <Button type='link' size='small' danger>
                {t('action.delete')}
              </Button>
            </Popconfirm>
          )}
        </Space>
      )
    }
  ];

  !isUpdate && !isDelete && columnTitle.pop();

  return columnTitle;
};

export interface OpenType {
  isOpen: boolean;
  employeeId?: number;
  id?: number;
}

export default function EmployeeCompensation() {
  const { t } = useTranslation();
  const [open, setOpen] = useState<OpenType>({ isOpen: false });
  const { searchParams, updateSearchParams } = useUpdateSearchParams();
  const departmentFilterRef = React.useRef<HTMLDivElement>(null);
  const periodFilterRef = React.useRef<HTMLDivElement>(null);
  const [openDepartmentFilter, setOpenDepartmentFilter] = useState<boolean>(false);
  const [openPeriodFilter, setOpenPeriodFilter] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: false,
      pageSizeOptions: [10]
    }
  });

  const { isCreate, isDelete, isUpdate } = usePermission({});

  useEffect(() => {
    const handleCloseFilter = (e: any) => {
      if (
        departmentFilterRef.current &&
        !departmentFilterRef.current.contains(e.target as Node) &&
        openDepartmentFilter
      ) {
        setOpenDepartmentFilter(false);
      } else if (periodFilterRef.current && !periodFilterRef.current.contains(e.target as Node) && openPeriodFilter) {
        setOpenPeriodFilter(false);
      }
    };

    document.addEventListener('click', handleCloseFilter);
    return () => document.removeEventListener('click', handleCloseFilter);
  }, [openDepartmentFilter, openPeriodFilter]);

  // Api handling
  const employeeCompensationsQuery = useQuery({
    queryKey: [
      'employeeCompensationsQuery',
      Number(searchParams.get('page')) || 1,
      tableParams.pagination?.pageSize ?? 10,
      getOrderString(String(searchParams.get('field')), String(searchParams.get('order'))),
      !!Number(searchParams.get('departmentId')) ? Number(searchParams.get('departmentId')) : undefined,
      !!Number(searchParams.get('month')) ? Number(searchParams.get('month')) : undefined,
      !!Number(searchParams.get('year')) ? Number(searchParams.get('year')) : undefined
    ],
    queryFn: () => {
      return getEmployeeCompensations(
        Number(searchParams.get('page')) || 1,
        tableParams.pagination?.pageSize ?? 10,
        getOrderString(String(searchParams.get('field')), String(searchParams.get('order'))),
        !!Number(searchParams.get('departmentId')) ? Number(searchParams.get('departmentId')) : undefined,
        !!Number(searchParams.get('month')) ? Number(searchParams.get('month')) : undefined,
        !!Number(searchParams.get('year')) ? Number(searchParams.get('year')) : undefined
      );
    },
    staleTime: 60 * 1000,
    keepPreviousData: true
  });

  const handleInvalidate = () => {
    queryClient.invalidateQueries({
      queryKey: [
        'employeeCompensationsQuery',
        Number(searchParams.get('page')) || 1,
        tableParams.pagination?.pageSize ?? 10,
        getOrderString(String(searchParams.get('field')), String(searchParams.get('order'))),
        !!Number(searchParams.get('departmentId')) ? Number(searchParams.get('departmentId')) : undefined,
        !!Number(searchParams.get('month')) ? Number(searchParams.get('month')) : undefined,
        !!Number(searchParams.get('year')) ? Number(searchParams.get('year')) : undefined
      ],
      exact: true
    });
  };

  const deleteEmployeeCompensationQuery = useMutation({
    mutationFn: (id: number) => deleteEmployeeCompensation(id)
  });

  const handleTableChange = function (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<IEmployeeCompensation> | SorterResult<IEmployeeCompensation>[],
    extra: TableCurrentDataSource<IEmployeeCompensation>
  ) {
    setTableParams({
      field: (sorter as any).field,
      order: (sorter as any).order,
      pagination,
      filters,
      ...sorter
    });

    updateSearchParams([
      getParamItem('page', pagination?.current ?? 1),
      getParamItem('field', (sorter as any).order ? (sorter as any).field : undefined),
      getParamItem('order', (sorter as any).order)
    ]);
  };

  const handleEdit = (id: number) => {
    setOpen({ isOpen: true, id });
  };

  const handleDelete = (id: number) => {
    deleteEmployeeCompensationQuery.mutate(id, {
      onSuccess: () => {
        handleInvalidate();
        showNotification(`${t('notification.success.delete')} ${id}`, 'success');
      }
    });
  };

  useEffect(() => {
    if (!searchParams.get('page')) {
      updateSearchParams([getParamItem('page', 1)]);
    }
  }, [updateSearchParams, searchParams]);

  useEffect(() => {
    if (employeeCompensationsQuery.data?.data && searchParams) {
      setTableParams((prev) => {
        return {
          ...prev,
          field: String(searchParams.get('field')) ? String(searchParams.get('field')) : prev.field,
          order: String(searchParams.get('order')) ? String(searchParams.get('order')) : prev.order,
          pagination: {
            ...prev.pagination,
            current: Number(searchParams.get('page'))
              ? Number(searchParams.get('page'))
              : Math.min(
                  Number(prev.pagination?.current),
                  employeeCompensationsQuery.data.data.totalPages === 0
                    ? 1
                    : employeeCompensationsQuery.data.data.totalPages
                ),
            total: employeeCompensationsQuery.data.data.totalCount
          }
        };
      });
    }
  }, [employeeCompensationsQuery.data?.data, searchParams]);

  return (
    <>
      <Flex gap={10} align='center' style={{ marginBottom: 16 }}>
        {isCreate && (
          <Button onClick={() => setOpen({ isOpen: true })} icon={<PlusOutlined />}>
            {t('action.add')}
          </Button>
        )}
      </Flex>
      <Table
        sticky
        columns={columns(
          handleEdit,
          handleDelete,
          isUpdate,
          isDelete,
          t,
          searchParams,
          updateSearchParams,
          departmentFilterRef,
          periodFilterRef,
          openDepartmentFilter,
          setOpenDepartmentFilter,
          openPeriodFilter,
          setOpenPeriodFilter
        )}
        rowKey={(record) => record.id}
        dataSource={employeeCompensationsQuery.data?.data?.results || []}
        pagination={tableParams.pagination}
        onChange={handleTableChange}
        loading={employeeCompensationsQuery.isFetching}
        scroll={{ x: 400 }}
        bordered
        size='middle'
        expandable={{
          columnWidth: '5%',
          expandedRowRender: (record) => (
            <Descriptions size='small' column={2}>
              <Descriptions.Item
                span={2}
                label={t('columnTitle.employeeCompensation.note')}
                style={{ paddingBlockEnd: 0 }}
              >
                {record.note}
              </Descriptions.Item>
              <Descriptions.Item label={t('columnTitle.base.createdDate')}>
                {formatDateTime(record.createdDate)}
              </Descriptions.Item>
              <Descriptions.Item label={t('columnTitle.base.lastModifiedDate')}>
                {formatDateTime(record.lastModifiedDate)}
              </Descriptions.Item>
            </Descriptions>
          )
        }}
      />

      {open && <AddEmployeeCompensation open={open} setOpen={setOpen} handleInvalidate={handleInvalidate} />}
    </>
  );
}
