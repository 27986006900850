import type { FlatItem } from 'src/types/elements.type';
import { PagedList } from 'src/types/pagedList.type';
import { ITemplate, TemplateAdd, TemplateType } from 'src/types/helpdesk/template.type';
import { http } from 'src/utils/http';

const controller = 'master-data/templates';

export const getTemplates = async (
  sequenceStatus: string,
  page: number,
  pageSize: number,
  orderBy?: string,
  departmentId?: number
) =>
  await http.get<PagedList<ITemplate>>(controller, {
    params: {
      sequenceStatus,
      page,
      pageSize,
      orderBy,
      departmentId
    }
  });

export const getTemplatesDropdown = async (lstDepartmentId?: number | number[], templateType?: TemplateType) =>
  await http.get<FlatItem[]>(`${controller}/dropdown`, {
    params: {
      lstDepartmentId,
      templateType
    }
  });

export const getTemplate = async (id: number) => await http.get<ITemplate>(`${controller}/${id}`);

export const getTemplateDetail = async (id?: number, departmentId?: number) =>
  await http.get<ITemplate>(`${controller}/get-template`, {
    params: {
      id,
      departmentId
    }
  });

export const addTemplate = async (template: TemplateAdd) => await http.post(controller, template);

export const updateTemplate = async (id: number, template: ITemplate) =>
  await http.put(`${controller}/${id}`, template);

export const deleteTemplate = async (id: number) => await http.delete(`${controller}/${id}`);
