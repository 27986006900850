import { App as AntdApp, ConfigProvider, theme } from 'antd';
import dayjs from 'dayjs';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './App.scss';
import urlPath from './constants/urlPath/urlPath';
import { AppContext } from './contexts/app.context';
import useRouteElements from './hooks/useRouteElements';

import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import 'dayjs/locale/vi';
import { getUserResourceFullAccess } from './apis/master_data/userResource.api';
import type { IResourceFullAccessDetail, IUserResourceFullAccess } from './types/master_data/userResource.type';
import { getAccessTokenFromLS } from './utils/auth';
import TicketManagementModal from './pages/v2/Home/TicketManagementModal';
import ApplicationMenu from './pages/v2/Home/ApplicationMenu/ApplicationMenu';
dayjs.locale('vi');

const { compactAlgorithm } = theme;

function App() {
  const {
    openTicketManagement,
    setOpenTicketManagement,
    openAppMenu,
    setOpenAppMenu,
    resources,
    setResources,
    setUserInfo
  } = useContext(AppContext);

  const routeElements = useRouteElements(resources);
  const location = useLocation();
  const isHomePage = location.pathname === urlPath.home.location || location.pathname === urlPath.systemAdmin.location;

  const token = getAccessTokenFromLS();

  useQuery({
    queryKey: ['userAccessResource', token],
    queryFn: async () => await getUserResourceFullAccess(),
    staleTime: 60 * 1000,
    enabled: !!token,
    onSuccess: (data: AxiosResponse<IUserResourceFullAccess, any>) => {
      // Set Resource
      let _resourcePermissions: IResourceFullAccessDetail[] = [];
      data.data?.details?.forEach(({ path, permissions }) => {
        if (!!path) {
          let pathRoots = path?.split(',');
          pathRoots?.forEach((pathString) => {
            const index = _resourcePermissions?.findIndex((x) => x.path === pathString);
            if (index === -1) {
              _resourcePermissions.push({ path: pathString, permissions: permissions });
            } else {
              _resourcePermissions[index] = {
                path: pathString,
                permissions: [...(_resourcePermissions[index] || [])?.permissions, ...permissions]
              };
            }
          });
        }
      });
      setResources(_resourcePermissions);

      // Set User Info
      if (data.data.employee) {
        setUserInfo(data.data.employee);
        localStorage.setItem('userInfo', JSON.stringify(data.data.employee));
      }
    }
  });

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          type: 'CHECK_VERSION_AND_CLEAR_CACHE',
          data: {}
        });
      }
    }
  }, []);

  return (
    <div className={`app-container ${isHomePage ? 'home' : ''} `}>
      <ConfigProvider
        // componentSize='small'
        theme={{
          components: {
            Divider: {
              colorSplit: 'var(--divider-color)'
            },
            Layout: {
              siderBg: '#103c62',
              triggerBg: '#1c4569',
              lightTriggerBg: '#ededed',
              triggerColor: '#fff'
            },
            Menu: {
              darkSubMenuItemBg: '#1c4569',
              darkItemBg: '#103c62',
              darkPopupBg: '#103c62',
              darkItemColor: '#fff',
              popupBg: '#f9f8f8',
              fontSize: 12
            },
            Table: { fontSize: 13 },
            Card: { fontSize: 13 },
            Form: { fontSize: 13, itemMarginBottom: 10 },
            Descriptions: { fontSize: 13 },
            Button: { fontSize: 13 }
          },
          algorithm: compactAlgorithm,
          token: {
            borderRadius: 4,
            colorSplit: 'grey',
            colorTextDisabled: 'rgba(0, 0, 0, 0.62)'
            // fontSize: 13
          }
        }}
      >
        <AntdApp>{routeElements}</AntdApp>
      </ConfigProvider>
      {openAppMenu && <ApplicationMenu open={openAppMenu} setOpen={setOpenAppMenu} />}
      {openTicketManagement && (
        <TicketManagementModal setIsOpen={setOpenTicketManagement} isOpen={openTicketManagement} />
      )}
    </div>
  );
}

export default App;
