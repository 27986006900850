import { useQuery } from '@tanstack/react-query';
import { Select, type SelectProps } from 'antd';
import { getModelsByItemShort, getModelsDropdown } from 'src/apis/item_master_data/model.api';

type Props = SelectProps & {
  categoryId?: number;
  itemId?: number;
  valueType?: 'id' | 'code';
};

export default function ModelSelect({
  value,
  onChange,
  style,
  allowClear = true,
  placeholder,
  disabled,
  categoryId,
  itemId,
  valueType = 'id'
}: Props) {
  const modelsDropdownQuery = useQuery({
    queryKey: ['modelsDropdown', categoryId, itemId],
    queryFn: () => (!!itemId ? getModelsByItemShort(Number(itemId)) : getModelsDropdown(Number(categoryId))),
    staleTime: 60 * 1000,
    enabled: !!categoryId || !!itemId
  });

  return (
    <Select
      disabled={disabled}
      allowClear={allowClear}
      value={modelsDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={modelsDropdownQuery.data?.data.map((item) => ({ value: item[valueType], label: item.name, data: item }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      placeholder={placeholder}
      loading={modelsDropdownQuery.isFetching}
    />
  );
}
