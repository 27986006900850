import type { FlatItem } from 'src/types/elements.type';
import { IPartnerType, PartnerTypeAdd } from 'src/types/master_data/partnerType.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/partner-types';

export const getPartnerTypes = async (
  searchCondition: string | undefined,
  sequenceStatus: string,
  page: number,
  pageSize: number,
  orderBy?: string
) => {
  return await http.get<PagedList<IPartnerType>>(`${controller}`, {
    params: {
      name: searchCondition,
      sequenceStatus,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getPartnerType = async (id: number) => {
  return http.get<IPartnerType>(`${controller}/${id}`);
};

export const addPartnerType = async (partnerType: PartnerTypeAdd) => {
  return http.post<PartnerTypeAdd>(controller, partnerType);
};

export const updatePartnerType = async (id: number, partnerType: IPartnerType) => {
  return await http.put<IPartnerType[]>(`${controller}/${id}`, partnerType);
};

export const deletePartnerType = async (id: number) => {
  await http.delete(`${controller}/${id}`);
};

export const getPartnerTypeDropdown = async () => {
  return http.get<FlatItem[]>(`${controller}/dropdown`);
};
