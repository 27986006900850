import { useQuery } from '@tanstack/react-query';
import { Divider, Flex, Layout, Menu, Skeleton, Typography, type MenuProps } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getResourceByPath, getResourcesByPath } from 'src/apis/master_data/resource.api';
import { AppContext } from 'src/contexts/app.context';
import type { HierarchicalItemMenu } from 'src/types/elements.type';
import { buildHierarchyMenu, findMenuItem } from 'src/utils/collection';
import { getPathsFromRoles } from 'src/utils/utils';
import styles from './Sidebar.module.scss';
// @ts-ignore
import { ItemType } from 'antd/es/menu/hooks/useItems';
import ResourceThumbnail from 'src/components/v2/Thumbnail/ResourceThumbnail';
import urlPath from 'src/constants/urlPath/urlPath';
import TicketManagementModal from 'src/pages/v2/Home/TicketManagementModal';

const { Sider: SiderAntd } = Layout;
const { Text } = Typography;

interface LevelKeysProps {
  key?: string;
  children?: LevelKeysProps[];
}

const getLevelKeys = (items1: LevelKeysProps[]) => {
  const key: Record<string, number> = {};
  const func = (items2: LevelKeysProps[], level = 1) => {
    items2.forEach((item) => {
      if (item.key) {
        key[item.key] = level;
      }
      if (item.children) {
        func(item.children, level + 1);
      }
    });
  };
  func(items1);
  return key;
};

type Props = {
  isDefaultCollapsed?: boolean;
};

export default function Sidebar({ isDefaultCollapsed = undefined }: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState<string>(location.pathname);
  const [menuItems, setMenuItems] = useState<ItemType[]>([]);
  const [stateOpenKeys, setStateOpenKeys] = useState<string[]>([]);

  const levelKeys = getLevelKeys(menuItems as LevelKeysProps[]);
  const {
    roles,
    isSidebarCollapsed,
    setIsSidebarCollapsed,
    openTicketManagement,
    setOpenTicketManagement,
    currentResource,
    setCurrentResource
  } = useContext(AppContext);

  const pathParts = location?.pathname?.split('/');

  const currPath = `/${pathParts[1]}/${pathParts[2]}`;

  // TH reload trang => ko có currentResource => gọi api để lấy resource
  const currentResourceQuery = useQuery({
    queryKey: ['currentResource', currPath],
    queryFn: async () => await getResourceByPath(currPath),
    staleTime: 60 * 1000,
    enabled: !currentResource
  });

  useEffect(() => {
    if (currentResourceQuery.data) {
      setCurrentResource(currentResourceQuery.data?.data);
    }
  }, [currentResourceQuery.data, setCurrentResource]);

  const resourcesQuery = useQuery({
    queryKey: ['resourceByPath', currentResource?.path],
    queryFn: async () => await getResourcesByPath(String(currentResource?.path)),
    staleTime: 60 * 1000,
    enabled: !!currentResource?.path
  });

  useEffect(() => {
    if (resourcesQuery.data) {
      const items: HierarchicalItemMenu[] = resourcesQuery.data?.data?.map((item) => ({
        id: item.id,
        code: item.code,
        sort: item.sort,
        name: item.name.toLocaleUpperCase(),
        parentId: item.parentId,
        path: item.path,
        icon: (
          <ResourceThumbnail
            className='ant-menu-item-icon'
            style={{ marginRight: '10px' }}
            filePath={item.iconPath || '/masterdata/development/appfunction/240802_13584002981_1.png'}
            size={12}
          />
        ),
        children: []
      }));

      const hierarchyMenu = buildHierarchyMenu(items, resourcesQuery.data?.data?.[0]?.parentId);

      setMenuItems(hierarchyMenu || []);
    }
  }, [resourcesQuery.data]);

  const onOpenChange: MenuProps['onOpenChange'] = (openKeys) => {
    const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
  };

  const paths = useMemo(() => {
    return getPathsFromRoles(roles);
  }, [roles]);

  useEffect(() => {
    setSelectedKey(location.pathname);

    const paths = location.pathname
      .split('/')
      .splice(1)
      .reduce((accumulator: string[], currentValue, currentIndex) => {
        const prevPath = currentIndex > 0 ? accumulator[currentIndex - 1] : '';
        accumulator.push(`${prevPath}/${currentValue}`);
        return accumulator;
      }, []);

    setStateOpenKeys(paths);
  }, [location.pathname]);

  useEffect(() => {
    if (isDefaultCollapsed !== undefined) {
      setIsSidebarCollapsed(isDefaultCollapsed);
    }
  }, [isDefaultCollapsed, setIsSidebarCollapsed]);

  return (
    <SiderAntd width={360} collapsible={false} collapsed={isSidebarCollapsed} theme='light' className={styles.sidebar}>
      {/* <div className={styles.sidebar__header}>
        <Flex gap={10}>
          <ResourceThumbnail filePath={String(currentResource?.iconPath)} size={20} />
          <Text strong>{currentResource?.name}</Text>
        </Flex>
        <Divider />
      </div> */}
      {!currentResource ? (
        <Skeleton paragraph={{ rows: 5 }} active />
      ) : (
        <Flex vertical gap={10}>
          <Flex gap={10} style={{ paddingLeft: '20px' }}>
            <ResourceThumbnail filePath={String(currentResource?.iconPath)} size={20} />
            {!isSidebarCollapsed ? <Text strong>{currentResource?.name}</Text> : ''}
          </Flex>
          <Divider style={{ margin: '0' }} />
          <Menu
            selectedKeys={[selectedKey]}
            mode='inline'
            inlineIndent={16}
            openKeys={stateOpenKeys}
            onOpenChange={onOpenChange}
            theme='light'
            items={
              Array.isArray(paths) ? (menuItems as ItemType[]) : []
              //   [
              //   {
              //     key: currentResource?.id,
              //     label: (
              //       <Flex gap={10}>
              //         <ResourceThumbnail filePath={String(currentResource?.iconPath)} size={20} />
              //         <Text strong>{currentResource?.name}</Text>
              //       </Flex>
              //     ),
              //     type: 'group',
              //     // icon: <ResourceThumbnail filePath={String(currentResource?.iconPath)} size={20} />,
              //     children: Array.isArray(paths) ? (menuItems as ItemType[]) : []
              //   }
              //   // ...(Array.isArray(paths) ? (menuItems as ItemType[]) : [])
              // ]
            }
            onClick={(e) => {
              let _selectedItem = findMenuItem(menuItems, e.key);
              if (e.key === urlPath.ticketRaising.location) {
                setOpenTicketManagement(true);
              } else if (!_selectedItem?.children && !e.key.startsWith('undefined')) navigate(e.key);
            }}
            className={styles.sidebar__menu}
          />
        </Flex>
      )}

      {openTicketManagement && (
        <TicketManagementModal setIsOpen={setOpenTicketManagement} isOpen={openTicketManagement} />
      )}
    </SiderAntd>
  );
}
