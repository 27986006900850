import { FilterFilled } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Form, Grid, Popover, Select, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTicketCategoriesDropdownV2 } from 'src/apis/helpdesk/ticketCategory.api';
import { TicketCategoryGroupSelect } from 'src/components/v1/Select';
import { TicketTypeEn } from 'src/constants/ticketType';

const { useBreakpoint } = Grid;

interface FormStateType {
  departmentFilterId?: number;
  ticketCategoryGroupId?: number;
  isFilter?: boolean;
  ticketTypeId?: number;
}

interface Props {
  value?: number;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean | undefined;
  onChange?:
    | ((
        value: number | ((prevState: number | undefined) => number | undefined),
        option:
          | {
              value: number;
              label: string;
            }
          | {
              value: number;
              label: string;
            }[]
      ) => void)
    | undefined;
  isApproval: boolean;
  departmentId?: number;
  placeholder?: string;
  disabled?: boolean;
  handleSetValueUndefined?: () => void;
}

const initialFormState = {
  isApproval: true,
  departmentFilterId: undefined,
  ticketCategoryGroupId: undefined,
  ticketTypeId: undefined,
  isFilter: undefined,
  disabled: false
};

export default function TicketCategoryyFilterSelectV2({
  isApproval,
  departmentId,
  value,
  onChange,
  style,
  allowClear = true,
  placeholder,
  disabled,
  handleSetValueUndefined
}: Props) {
  const [filter, setFilter] = useState<boolean>(false);
  const [formState, setFormState] = useState<FormStateType>(initialFormState);
  const [form] = useForm();

  const { t } = useTranslation();

  const screens = useBreakpoint();

  const ticketCategoriesFilterDropdownV2Query = useQuery({
    queryKey: [
      'ticketCategoriesFilterDropdownV2',
      isApproval,
      departmentId,
      formState.departmentFilterId,
      formState?.ticketCategoryGroupId,
      formState?.ticketTypeId
    ],
    queryFn: () =>
      getTicketCategoriesDropdownV2(
        isApproval,
        departmentId,
        formState.departmentFilterId,
        formState?.ticketCategoryGroupId,
        formState?.ticketTypeId
      ),
    staleTime: 60 * 1000,
    enabled: formState.isFilter === false ? false : true
  });

  const handleCancel = () => {
    setFilter(false);
  };

  const handleClear = () => {
    if (formState?.departmentFilterId || formState?.ticketCategoryGroupId) {
      setFormState(initialFormState);
    }
    setFilter(false);
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      handleSetValueUndefined && handleSetValueUndefined();
      setFormState({
        ...formState,
        isFilter: true
      });
      setFilter(false);
    });
  };

  return (
    <Space.Compact style={{ ...style }} direction='horizontal'>
      <Select
        allowClear={allowClear}
        style={{ width: '100%' }}
        disabled={disabled}
        placeholder={placeholder}
        value={ticketCategoriesFilterDropdownV2Query.isFetching ? undefined : value}
        onChange={onChange}
        options={ticketCategoriesFilterDropdownV2Query.data?.data
          .filter((item) => !item.isUsed)
          .map((data) => ({
            value: data?.id,
            label: data?.name
          }))}
        showSearch
        filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
        loading={ticketCategoriesFilterDropdownV2Query.isFetching}
      />

      <Popover
        placement='bottomRight'
        openClassName='category-filter'
        content={
          <div style={{ minWidth: screens.xl ? '400px' : '350px' }}>
            <Form
              labelCol={{ span: screens.xl ? 10 : 10 }}
              wrapperCol={{ span: screens.xl ? 14 : 14 }}
              layout='horizontal'
              form={form}
              fields={[
                {
                  name: 'departmentFilterId',
                  value: formState?.departmentFilterId
                },
                {
                  name: 'ticketCategoryGroupId',
                  value: formState?.ticketCategoryGroupId
                },
                {
                  name: 'ticketTypeId',
                  value: formState?.ticketTypeId
                }
              ]}
            >
              <Form.Item name='ticketTypeId' label='Loại ticket'>
                <Select
                  allowClear
                  className='filter-category-group-select'
                  style={{ width: '100%' }}
                  options={[
                    { value: TicketTypeEn.New, label: 'Activities' },
                    { value: TicketTypeEn.Issue, label: 'Sự cố phát sinh' }
                  ]}
                  onChange={(value) =>
                    setFormState((prev) => ({
                      ...prev,
                      ticketTypeId: value ? Number(value) : undefined
                    }))
                  }
                />
              </Form.Item>
              <Form.Item name='ticketCategoryGroupId' label={t('columnTitle.ticket.ticketCategoryGroup')}>
                <TicketCategoryGroupSelect
                  allowClear
                  className='filter-category-group-select'
                  style={{ width: '100%' }}
                  ticketTypeId={formState?.ticketTypeId}
                  onChange={(value) =>
                    setFormState((prev) => ({
                      ...prev,
                      ticketCategoryGroupId: value ? Number(value) : undefined,
                      isFilter: false
                    }))
                  }
                />
              </Form.Item>
            </Form>
            <Space style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
              <Button type='default' onClick={() => handleClear()} style={{ borderRadius: '6px' }}>
                {t('action.clear')}
              </Button>
              <Button type='primary' onClick={() => handleSubmit()} style={{ borderRadius: '6px' }}>
                {t('action.filter')}
              </Button>
            </Space>
          </div>
        }
        title={t('columnTitle.base.filter')}
        trigger='click'
        style={{
          position: 'relative'
        }}
        open={filter}
        onOpenChange={handleCancel}
      >
        <Button
          type='text'
          icon={<FilterFilled />}
          onClick={() => setFilter(!filter)}
          style={{
            backgroundColor: formState?.isFilter ? '#036eb7' : '#D8D9DA',
            color: formState?.isFilter ? 'white' : '#191717'
          }}
        />
      </Popover>
    </Space.Compact>
  );
}
