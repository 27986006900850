import { Select, Typography } from 'antd';
import { SelectProps } from 'src/types/elements.type';
import './UnitOfCurrencySelect.scss';
import { getUnitOfCurrenciesDropdown } from 'src/apis/master_data/unitOfCurrency.api';
import { useQuery } from '@tanstack/react-query';

const { Text } = Typography;

export default function UnitOfCurrencySelect({
  value,
  style,
  allowClear,
  onChange,
  placeholder,
  disabled
}: SelectProps) {
  const unitOfCurrenciesDropdownQuery = useQuery({
    queryKey: ['unitOfCurrenciesDropdown'],
    queryFn: () => getUnitOfCurrenciesDropdown(),
    staleTime: 60 * 1000 * 60
  });

  return (
    <Select
      rootClassName='unit-of-currency'
      allowClear={allowClear}
      disabled={disabled}
      value={unitOfCurrenciesDropdownQuery.isFetching ? undefined : value}
      style={style}
      placeholder={placeholder}
      onChange={onChange}
      options={unitOfCurrenciesDropdownQuery.data?.data?.map((item) => ({
        label: <Text className={`${item.conversionValue <= 0 && 'disable'}`}>{item.code}</Text>,
        value: item.id,
        data: item
      }))}
      showSearch
      filterOption={(input, option) => {
        return (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase());
      }}
      loading={unitOfCurrenciesDropdownQuery.isFetching}
    />
  );
}
