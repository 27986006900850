import type { FlatItem } from 'src/types/elements.type';
import { IOrganizationChart, OrganizationChartAdd } from 'src/types/master_data/organizationChart.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/organization-charts';

export const getOrganizationCharts = async (
  searchCondition: string | undefined,
  sequenceStatus: string,
  page: number,
  pageSize: number,
  orderBy?: string
) => {
  return await http.get<PagedList<IOrganizationChart>>(controller, {
    params: {
      name: searchCondition,
      sequenceStatus,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getOrganizationChart = async (id: number) => {
  return await http.get<IOrganizationChart>(`${controller}/${id}`);
};

export const getOrganizationChartsDropdown = async () => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
};

export const addOrganizationChart = async (organizationChart: OrganizationChartAdd) => {
  return await http.post(controller, organizationChart);
};

export const updateOrganizationChart = async (id: number, organizationChart: OrganizationChartAdd) => {
  return await http.put(`${controller}/${id}`, organizationChart);
};

export const deleteOrganizationChart = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};
