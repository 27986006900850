import { CRUDPathSegmentEn } from '../newFeatures/crudPathSegment.enum';

const accountingPath = {
  accounting: '/erp/accounting',

  accountingAccount: '/erp/accounting/accounting-account',

  accountingPaymentRequestTicket: '/erp/accounting/payment-request-ticket',

  debtTaking: '/erp/accounting/debt-taking',
  debtPaying: '/erp/accounting/debt-paying',

  //GRPO
  grpo: '/erp/accounting/grpo',
  grpoDetail: '/erp/accounting/grpo' + CRUDPathSegmentEn.DETAIL,
  grpoDetailCode: '/erp/accounting/grpo' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  grpoAdd: '/erp/accounting/grpo' + CRUDPathSegmentEn.ADD,
  grpoEdit: '/erp/accounting/grpo' + CRUDPathSegmentEn.EDIT,

  //#region  MasterData

  // Incoterm - Điều khoản thương mại
  incoterm: '/erp/accounting/master-data/incoterm',
  incotermDetail: '/erp/accounting/master-data/incoterm' + CRUDPathSegmentEn.DETAIL,
  incotermDetailCode: '/erp/accounting/master-data/incoterm' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  incotermAdd: '/erp/accounting/master-data/incoterm' + CRUDPathSegmentEn.ADD,
  incotermEdit: '/erp/accounting/master-data/incoterm' + CRUDPathSegmentEn.EDIT,

  // Payment method - Hình thức thanh toán
  paymentMethod: '/erp/accounting/master-data/payment-method',
  paymentMethodDetail: '/erp/accounting/master-data/payment-method' + CRUDPathSegmentEn.DETAIL,
  paymentMethodDetailCode: '/erp/accounting/master-data/payment-method' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  paymentMethodAdd: '/erp/accounting/master-data/payment-method' + CRUDPathSegmentEn.ADD,
  paymentMethodEdit: '/erp/accounting/master-data/payment-method' + CRUDPathSegmentEn.EDIT,

  // Main Shipping method - Phương tiện vận chuyển chính
  mainShippingMethod: '/erp/accounting/master-data/main-shipping-method',
  mainShippingMethodDetail: '/erp/accounting/master-data/main-shipping-method' + CRUDPathSegmentEn.DETAIL,
  mainShippingMethodDetailCode: '/erp/accounting/master-data/main-shipping-method' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  mainShippingMethodAdd: '/erp/accounting/master-data/main-shipping-method' + CRUDPathSegmentEn.ADD,
  mainShippingMethodEdit: '/erp/accounting/master-data/main-shipping-method' + CRUDPathSegmentEn.EDIT,

  // Shipping Stage - Chặng vận chuyển
  shippingStage: '/erp/accounting/master-data/shipping-stage',
  shippingStageDetail: '/erp/accounting/master-data/shipping-stage' + CRUDPathSegmentEn.DETAIL,
  shippingStageDetailCode: '/erp/accounting/master-data/shipping-stage' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  shippingStageAdd: '/erp/accounting/master-data/shipping-stage' + CRUDPathSegmentEn.ADD,
  shippingStageEdit: '/erp/accounting/master-data/shipping-stage' + CRUDPathSegmentEn.EDIT,

  // Payment document - Chứng từ thanh toán
  paymentDocument: '/erp/accounting/master-data/payment-document',
  paymentDocumentDetail: '/erp/accounting/master-data/payment-document' + CRUDPathSegmentEn.DETAIL,
  paymentDocumentDetailCode: '/erp/accounting/master-data/payment-document' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  paymentDocumentAdd: '/erp/accounting/master-data/payment-document' + CRUDPathSegmentEn.ADD,
  paymentDocumentEdit: '/erp/accounting/master-data/payment-document' + CRUDPathSegmentEn.EDIT,
  // Payment document detail requirement - Yêu cầu chi tiết cho chứng từ
  paymentDocumentDetailRequirement: '/erp/accounting/master-data/payment-document-detail-requirement',
  paymentDocumentDetailRequirementDetail:
    '/erp/accounting/master-data/payment-document-detail-requirement' + CRUDPathSegmentEn.DETAIL,
  paymentDocumentDetailRequirementDetailCode:
    '/erp/accounting/master-data/payment-document-detail-requirement' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  paymentDocumentDetailRequirementAdd:
    '/erp/accounting/master-data/payment-document-detail-requirement' + CRUDPathSegmentEn.ADD,
  paymentDocumentDetailRequirementEdit:
    '/erp/accounting/master-data/payment-document-detail-requirement' + CRUDPathSegmentEn.EDIT

  //#endregion MasterData
};
export default accountingPath;
