import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getEmployeesDropdown } from 'src/apis/master_data/employee.api';

interface Props {
  value?: string;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean | undefined;
  onChange?: (value: string) => void;
  departmentId?: number;
  disabled?: boolean;
}

export default function EmployeeSelectV2({ value, style, disabled, allowClear, onChange, departmentId }: Props) {
  const employeesDropdownV2Query = useQuery({
    queryKey: ['employeesDropdownV2', departmentId],
    queryFn: () => getEmployeesDropdown(departmentId),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={employeesDropdownV2Query.isFetching ? undefined : value}
      style={style}
      disabled={disabled}
      onChange={onChange}
      options={employeesDropdownV2Query.data?.data.map((data) => ({
        value: JSON.stringify(data),
        label: `${data.code} - ${data.fullName}`
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={employeesDropdownV2Query.isFetching}
    />
  );
}
