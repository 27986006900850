import { Suspense, lazy, useMemo } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { PermissionEn } from 'src/constants/permission.enum';
import accountingPath from 'src/constants/urlPath/accountingPath';
import inventoryPath from 'src/constants/urlPath/inventoryPath';
import productionPath from 'src/constants/urlPath/productionPath';
import salePath from 'src/constants/urlPath/salePath';
import standardsQualityPath from 'src/constants/urlPath/standardsQualityPath';
import Forbidden from 'src/pages/Error/Forbidden';
import InternalServer from 'src/pages/Error/InternalServer';
import NotFound from 'src/pages/Error/NotFound';
import EmployeeCompensation from 'src/pages/Hr/EmployeeCompensation';
import accountingRoutes from 'src/routes/accountingRoute';
import inventoryRoutes from 'src/routes/inventoryRoute';
import productionRoutes from 'src/routes/productionRoute';
import rndRoutes from 'src/routes/rndRoute';
import type { IResourceFullAccessDetail } from 'src/types/master_data/userResource.type';
import urlPath from '../constants/urlPath/urlPath';
import NewLayout from '../layouts/NewFeatures/MainLayout';
import MainLayout from '../layouts/v1/MainLayout';
import MainLayoutV2 from '../layouts/v2/MainLayout';
import SigninOidc from '../pages/SigninOidc';
import SignoutCallbackOidc from '../pages/SignoutCallBackOidc';
import SignoutOidc from '../pages/SignoutOidc';
import { usePermissionNew } from './usePermission';

//#region Import components
const Home = lazy(() => import('../pages/Home'));

//#region Ticket
const TicketCategoryGroup = lazy(() => import('../pages/Ticket/TicketCategoryGroup'));
const TicketCategory = lazy(() => import('../pages/Ticket/TicketCategory'));
const AddTicketCategory = lazy(() => import('../pages/Ticket/TicketCategory/AddTicketCategory'));
const TicketCategoryDetail = lazy(() => import('../pages/Ticket/TicketCategoryDetail'));
const TicketType = lazy(() => import('../pages/Ticket/TicketType'));
const TicketForm = lazy(() => import('src/pages/Ticket/TicketForm/TicketForm'));
const AddTicketForm = lazy(() => import('src/pages/Ticket/TicketForm/AddTicketForm'));
const TicketFormDetail = lazy(() => import('src/pages/Ticket/TicketFormDetail'));
const Reason = lazy(() => import('src/pages/Ticket/Reason'));
const TicketFormMapping = lazy(() => import('src/pages/Ticket/TicketFormMapping'));
//#endregion

//#region Admininstration
const ApprovalProcess = lazy(() => import('src/pages/Administration/ApprovalProcess'));
const AddApprovalProcess = lazy(() => import('src/pages/Administration/AddApprovalProcess'));
const ApprovalProcessDetail = lazy(() => import('src/pages/Administration/ApprovalProcessDetail'));
const Template = lazy(() => import('src/pages/Administration/Template'));
const AddTemplate = lazy(() => import('src/pages/Administration/AddTemplate'));
//#endregion

//#region Master data
const Branch = lazy(() => import('src/pages/MasterData/Branch'));
const Department = lazy(() => import('src/pages/MasterData/Department'));
const Employee = lazy(() => import('src/pages/MasterData/Employee'));
const Position = lazy(() => import('src/pages/MasterData/Position'));
const AddEmployee = lazy(() => import('src/pages/MasterData/AddEmployee'));
const EmployeeQrCode = lazy(() => import('src/pages/MasterData/EmployeeQrCode'));
const EmployeeDetail = lazy(() => import('src/pages/MasterData/EmployeeDetail'));
const Board = lazy(() => import('src/pages/MasterData/Board'));
const Level = lazy(() => import('src/pages/MasterData/Level'));
const Tier = lazy(() => import('src/pages/MasterData/Tier'));
const JobCategory = lazy(() => import('src/pages/MasterData/JobCategory'));
const AddJobCategory = lazy(() => import('src/pages/MasterData/AddJobCategory'));
const OrganizationChart = lazy(() => import('src/pages/MasterData/OrganizationChart'));
const Asset = lazy(() => import('src/pages/MasterData/Asset'));
const Degree = lazy(() => import('src/pages/MasterData/Degree'));
const PartnerType = lazy(() => import('src/pages/MasterData/PartnerType'));
const Partner = lazy(() => import('src/pages/MasterData/Partner'));
const AddPartner = lazy(() => import('src/pages/MasterData/AddPartner'));
const AccountingAccountType = lazy(() => import('src/pages/MasterData/AccountingAccountType'));
const AccountingObjectType = lazy(() => import('src/pages/MasterData/AccountingObjectType'));
const GeneralAccountingAccount = lazy(() => import('src/pages/MasterData/GeneralAccountingAccount'));
const ContractType = lazy(() => import('src/pages/MasterData/ContractType'));
const ApplicationInfo = lazy(() => import('src/pages/MasterData/ApplicationInfo'));
const Certificate = lazy(() => import('src/pages/MasterData/Certificate'));
const ErrorCode = lazy(() => import('src/pages/MasterData/ErrorCode'));
const HRTicketCategory = lazy(() => import('src/pages/MasterData/HRTicketCategory'));
const Resource = lazy(() => import('src/pages/MasterData/Resource'));
const ApplicationFunction = lazy(() => import('src/pages/MasterData/ApplicationFunction'));
const Role = lazy(() => import('src/pages/MasterData/Role'));
const UserResource = lazy(() => import('src/pages/MasterData/UserResource'));
const AddRole = lazy(() => import('src/pages/MasterData/AddRole'));
const Permission = lazy(() => import('src/pages/MasterData/Permission'));
const Holiday = lazy(() => import('src/pages/MasterData/Holiday'));
const FuelConsumption = lazy(() => import('src/pages/MasterData/FuelConsumption'));
const FuelUnitPrice = lazy(() => import('src/pages/MasterData/FuelUnitPrice'));
const AdvancePaymentLimit = lazy(() => import('src/pages/Hr/AdvancePaymentLimit'));
const SubSystem = lazy(() => import('src/pages/MasterData/SubSystem'));
const WorkDescription = lazy(() => import('src/pages/MasterData/WorkDescription'));
const AddSubSystem = lazy(() => import('src/pages/MasterData/AddSubSystem'));
const AddApplicationInfo = lazy(() => import('src/pages/MasterData/AddApplicationInfo'));
const ApplicationInfoDetail = lazy(() => import('src/pages/MasterData/ApplicationInfoDetail'));
const Distance = lazy(() => import('src/pages/MasterData/Distance'));
const BiometricDevice = lazy(() => import('src/pages/Hr/BiometricDevice'));
const BiometricLog = lazy(() => import('src/pages/Hr/BiometricLog'));
const BiometricEmployeeMapping = lazy(() => import('src/pages/Hr/BiometricEmployeeMapping'));
const UserTicketCategory = lazy(() => import('src/pages/MasterData/UserTicketCategory'));
const UserJobCategory = lazy(() => import('src/pages/MasterData/UserJobCategory'));
const Shift = lazy(() => import('src/pages/MasterData/Shift'));
const ManagerAssignment = lazy(() => import('src/pages/MasterData/ManagerAssignment'));
const WareHouseGroup = lazy(() => import('src/pages/MasterData/WareHouseGroup'));
const WareHouse = lazy(() => import('src/pages/MasterData/WareHouse'));
const MobileVersion = lazy(() => import('src/pages/MasterData/MobileVersion'));
const EmployeeEvaluation = lazy(() => import('src/pages/Hr/EmployeeEvaluation'));
const ProductionUnit = lazy(() => import('src/pages/MasterData/ProductionUnit'));
const AddProductionUnit = lazy(() => import('src/pages/MasterData/AddProductionUnit'));
//#endregion

//#region HR
const EmployeeShift = lazy(() => import('src/pages/Hr/EmployeeShift'));
const AdvancePaymentEmployee = lazy(() => import('src/pages/Hr/AdvancePaymentEmployee'));
const LeaveTicketPackage = lazy(() => import('src/pages/Hr/LeaveTicketPackage'));
const AddLeaveTicketPackage = lazy(() => import('src/pages/Hr/AddLeaveTicketPackage'));
const LeaveTicketPackageDetail = lazy(() => import('src/pages/Hr/LeaveTicketPackageDetail'));
const Timesheet = lazy(() => import('src/pages/Hr/Timesheet'));
const AdditionalWorkingTimePackage = lazy(() => import('src/pages/Hr/AdditionalWorkingTimePackage'));
const AdditionalWorkingTimePackageDetail = lazy(() => import('src/pages/Hr/AdditionalWorkingTimePackageDetail'));
// const EmployeeCompensation = lazy(() => import('src/pages/Hr/EmployeeCompensation'));
//#endregion

//#region Import components V2
const HomeV2 = lazy(() => import('src/pages/v2/Home'));
const HomeSub = lazy(() => import('src/pages/v2/HomeSub'));
const BiometricLogV2 = lazy(() => import('src/pages/v2/HrTicket/BiometricLog'));
const Login = lazy(() => import('src/pages/v2/Login'));

// #region Ticket
const TicketsV2 = lazy(() => import('src/pages/v2/Ticket/Tickets'));
const TicketLayout = lazy(() => import('src/layouts/v2/TicketLayout'));
const SubLayout = lazy(() => import('src/layouts/v2/ExternalLayout'));
const AddTicketV2 = lazy(() => import('src/pages/v2/Ticket/AddTicket'));
const TicketDetailSolutionAddV2 = lazy(
  () => import('src/pages/v2/Ticket/TicketDetail/TicketDetailSolution/TicketDetailSolutionAdd')
);
//#endregion

// #region HR
const AdvancePaymentTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AdvancePaymentTicket'));
const AddAdvancePaymentTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddAdvancePaymentTicket'));

const TransportationTicketV2 = lazy(() => import('src/pages/v2/HrTicket/TransportationTicket'));
const AddTransportationTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddTransportationTicket'));

const LeaveTicketV2 = lazy(() => import('src/pages/v2/HrTicket/LeaveTicket'));
const LeaveTicketPackageV2 = lazy(() => import('src/pages/v2/HrTicket/LeaveTicketPackage'));
const AddLeaveTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddLeaveTicket'));
const AddLeaveTicketSub = lazy(() => import('src/pages/v2/Sub/AddLeaveTicket'));

const ExternalWorkerTicket = lazy(() => import('src/pages/v2/HrTicket/ExternalWorkerTicket'));
const AddExternalWorkerTicket = lazy(() => import('src/pages/v2/HrTicket/AddExternalWorkerTicket'));

const ExpenseTrackingRequestTicket = lazy(() => import('src/pages/v2/HrTicket/ExpenseTrackingRequestTicket'));
const AddExpenseTrackingRequestTicket = lazy(() => import('src/pages/v2/HrTicket/AddExpenseTrackingRequestTicket'));
const ExpenseTrackingRequestTicketHrConfirm = lazy(() => import('src/pages/v2/HrConfirm/ExpenseTrackingRequestTicket'));
const OvertimeTicketV2 = lazy(() => import('src/pages/v2/HrTicket/OvertimeTicket'));
const AddOvertimeTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddOvertimeTicket'));

const HandoverTicketV2 = lazy(() => import('src/pages/v2/OfficeTicket/HandoverTicket'));
const AddHandoverTicketV2 = lazy(() => import('src/pages/v2/OfficeTicket/AddHandoverTicket'));

const BusinessTripTicketV2 = lazy(() => import('src/pages/v2/HrTicket/BusinessTripTicket'));
const AddBusinessTripTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddBusinessTripTicket'));

const PurchaseRequestTicketV2 = lazy(() => import('src/pages/v2/HrTicket/PurchaseRequestTicket'));
const AddPurchaseRequestTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddPurchaseRequestTicket'));

const EquipmentSupplyRequestTicketV2 = lazy(() => import('src/pages/v2/HrTicket/EquipmentSupplyRequestTicket'));
const AddEquipmentSupplyRequestTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddEquipmentSupplyRequestTicket'));

const AdditionalWorkingTimeTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AdditionalWorkingTimeTicket'));
const AddAdditionalWorkingTimeTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddAdditionalWorkingTimeTicket'));

const AdditionalWorkingTimePackageV2 = lazy(() => import('src/pages/v2/HrTicket/AdditionalWorkingTimePackage'));

const LeaveTicketHrConfirmV2 = lazy(() => import('src/pages/v2/HrConfirm/LeaveTicket'));
const BusinessTripHrConfirmV2 = lazy(() => import('src/pages/v2/HrConfirm/BusinessTripTicket'));
const OvertimeHrConfirmV2 = lazy(() => import('src/pages/v2/HrConfirm/OvertimeTicket'));
const TransportationHrConfirmV2 = lazy(() => import('src/pages/v2/HrConfirm/TransportationTicket'));
//#endregion

//#region V2 USER GUIDE
const UserGuides = lazy(() => import('src/pages/v2/UserGuide/UserGuides'));
//#endregion V2 USER GUIDE

//#region V2 LEAVE REMAINING YEAR
const LeaveRemainingYear = lazy(() => import('src/pages/v2/HrTicket/LeaveRemainingYear'));
//#endregion V2 LEAVE REMAINING YEAR

//#region TOPIC
const TopicLayout = lazy(() => import('src/layouts/v2/TopicLayout'));
const TopicConversationBox = lazy(() => import('src/pages/v2/Topic/TopicConversationBox'));
//#endregion TOPIC

//#region REPORT
const ReportLayout = lazy(() => import('src/layouts/v2/ReportLayout'));
const ReportManagement = lazy(() => import('src/pages/v2/Report/ReportManagement'));
const ReportLeaveTicket = lazy(() => import('src/pages/v2/Report/LeaveTicket'));
const ReportOvertimeTicket = lazy(() => import('src/pages/v2/Report/OvertimeTicket'));
// const PNL04 = lazy(() => import('src/pages/v2/Report/PNL04'));
const ReportBusinessTripTicket = lazy(() => import('src/pages/v2/Report/BusinessTripTicket'));
const ReportDailyInventoryJournal = lazy(() => import('src/pages/v2/Report/DailyInventoryJournal'));
//#endregion REPORT

//#region PURCHASE
const Purchase = lazy(() => import('src/pages/Purchase'));
const ReceivePlan = lazy(() => import('src/pages/Purchase/ReceivePlan'));
const HistoryOrder = lazy(() => import('src/pages/Purchase/HistoryOrder'));
const Debt = lazy(() => import('src/pages/Purchase/Debt'));
const DebtGroup = lazy(() => import('src/pages/Purchase/DebtGroup'));

const PurchaseOrder = lazy(() => import('src/pages/Purchase/PurchaseOrder'));
const PurchaseOrderStamp = lazy(() => import('src/pages/Purchase/PurchaseOrderStamp'));
const AddPurchaseOrder = lazy(() => import('src/pages/Purchase/PurchaseOrder/AddPurchaseOrder'));
const PurchaseOrderDetail = lazy(() => import('src/pages/Purchase/PurchaseOrder/PurchaseOrderDetail'));

const GRPOR = lazy(() => import('src/pages/Inventory/GRPOR'));
const AddGRPOR = lazy(() => import('src/pages/Inventory/GRPOR/AddGRPOR'));
const GRPORDetail = lazy(() => import('src/pages/Inventory/GRPOR/GRPORDetail'));

const GRPO = lazy(() => import('src/pages/Purchase/GRPO'));
const AddGRPO = lazy(() => import('src/pages/Purchase/GRPO/AddGRPO'));
const GRPODetail = lazy(() => import('src/pages/Purchase/GRPO/GRPODetail'));

const AddQcResult = lazy(() => import('src/pages/Inventory/QcResult/AddQcResult'));
const QcResultDetail = lazy(() => import('src/pages/Inventory/QcResult/QcResultDetail'));

const AddAccounting = lazy(() => import('src/pages/Purchase/Accounting/AddAccounting'));
const AccountingDetail = lazy(() => import('src/pages/Purchase/Accounting/AccountingDetail'));

const AddAPInvoice = lazy(() => import('src/pages/Purchase/APInvoice/AddAPInvoice'));
const APInvoiceDetail = lazy(() => import('src/pages/Purchase/APInvoice/APInvoiceDetail'));

const SupplyRequest = lazy(() => import('src/pages/Purchase/SupplyRequest'));
const AddSupplyRequest = lazy(() => import('src/pages/Purchase/SupplyRequest/AddSupplyRequest'));
const SupplyRequestDetail = lazy(() => import('src/pages/Purchase/SupplyRequest/SupplyRequestDetail'));

const PurchaseRequest = lazy(() => import('src/pages/Purchase/PurchaseRequest'));
const AddPurchaseRequest = lazy(() => import('src/pages/Purchase/PurchaseRequest/AddPurchaseRequest'));
const PurchaseRequestDetail = lazy(() => import('src/pages/Purchase/PurchaseRequest/PurchaseRequestDetail'));

const PQR = lazy(() => import('src/pages/Purchase/PQR'));
const AddPQR = lazy(() => import('src/pages/Purchase/PQR/AddPQR'));
const PQRDetail = lazy(() => import('src/pages/Purchase/PQR/PQRDetail'));

const PurchaseQuotation = lazy(() => import('src/pages/Purchase/PurchaseQuotation'));
const AddPurchaseQuotation = lazy(() => import('src/pages/Purchase/PurchaseQuotation/AddPurchaseQuotation'));
const AddPurchaseQuotationVendor = lazy(
  () => import('src/pages/Purchase/PurchaseQuotation/AddPurchaseQuotationVendor')
);
const PurchaseQuotationDetail = lazy(() => import('src/pages/Purchase/PurchaseQuotation/PurchaseQuotationDetail'));

const TemplateSetOfDocument = lazy(() => import('src/pages/Purchase/TemplateSetOfDocument'));
const DocumentType = lazy(() => import('src/pages/Purchase/DocumentType'));
const RefKey = lazy(() => import('src/pages/Purchase/RefKey'));
//#endregion PURCHASEimport QcResult from './../pages/Purchase/QcResult/index';

// #region SALE
const SO = lazy(() => import('src/pages/Sale/SaleOrder'));
const AddSO = lazy(() => import('src/pages/Sale/SaleOrder/AddSaleOrder'));
// #endregion SALE

//#region ITEM MASTER DATA
const ItemMasterDataHome = lazy(() => import('src/pages/ItemMasterData'));
const ItemMasterData = lazy(() => import('src/pages/ItemMasterData/ItemMasterData'));
const AddItemMasterData = lazy(() => import('src/pages/ItemMasterData/AddItemMasterData'));
const ItemMasterDataDetail = lazy(() => import('src/pages/ItemMasterData/ItemMasterDataDetail'));
const ItemType = lazy(() => import('src/pages/ItemMasterData/ItemType'));
const AddItemType = lazy(() => import('src/pages/ItemMasterData/AddItemType'));
const ItemTypeDetail = lazy(() => import('src/pages/ItemMasterData/ItemTypeDetail'));
const ItemGroup = lazy(() => import('src/pages/ItemMasterData/ItemGroup'));
const AddItemGroup = lazy(() => import('src/pages/ItemMasterData/AddItemGroup'));
const ItemGroupDetail = lazy(() => import('src/pages/ItemMasterData/ItemGroupDetail'));
const Model = lazy(() => import('src/pages/ItemMasterData/Model'));
const AddModel = lazy(() => import('src/pages/ItemMasterData/AddModel'));
const ModelDetail = lazy(() => import('src/pages/ItemMasterData/ModelDetail'));
const ItemSpec = lazy(() => import('src/pages/ItemMasterData/ItemSpec'));
const AddItemSpec = lazy(() => import('src/pages/ItemMasterData/AddItemSpec'));
const ItemSpecDetail = lazy(() => import('src/pages/ItemMasterData/ItemSpecDetail'));
const AccountingItem = lazy(() => import('src/pages/ItemMasterData/AccountingItem'));
const Specification = lazy(() => import('src/pages/ItemMasterData/Specification'));
const ItemTechnicalSpecification = lazy(() => import('src/pages/ItemMasterData/ItemTechnicalSpecification'));
//#endregion ITEM MASTER DATA

//#region KPI
const Target = lazy(() => import('src/pages/ERP/Planning/Kpi/Target'));
const AddTarget = lazy(() => import('src/pages/ERP/Planning/Kpi/AddTarget'));
const TargetDetail = lazy(() => import('src/pages/ERP/Planning/Kpi/TargetDetail'));
const WorkAssign = lazy(() => import('src/pages/ERP/Planning/Kpi/WorkAssign'));
const AddWorkAssign = lazy(() => import('src/pages/ERP/Planning/Kpi/AddWorkAssign'));
const WorkAssignDetail = lazy(() => import('src/pages/ERP/Planning/Kpi/WorkAssignDetail'));
const WorkPlan = lazy(() => import('src/pages/ERP/Planning/Kpi/WorkPlan'));
const AddWorkPlan = lazy(() => import('src/pages/ERP/Planning/Kpi/AddWorkPlan'));
const WorkPlanDetail = lazy(() => import('src/pages/ERP/Planning/Kpi/WorkPlanDetail'));
//#endregion KPI

//#region CONTRACT EXECUTION
const ContractExecution = lazy(() => import('src/pages/ERP/ContractExecution'));

//#region PRODUCTION MANAGEMENT

const PackagingRequest = lazy(() => import('src/pages/ERP/ContractExecution/Production/PackagingRequest'));
const AddPackagingRequest = lazy(
  () => import('src/pages/ERP/ContractExecution/Production/PackagingRequest/AddPackagingRequest')
);

const ProductionOrder = lazy(() => import('src/pages/ERP/ContractExecution/Production/ProductionOrder'));

const DeliveryRequest = lazy(() => import('src/pages/ERP/ContractExecution/Production/DeliveryRequest'));
const AddDeliveryRequest = lazy(
  () => import('src/pages/ERP/ContractExecution/Production/DeliveryRequest/AddDeliveryRequest')
);

const DeliveryOrder = lazy(() => import('src/pages/ERP/ContractExecution/Production/DeliveryOrder'));
const AddDeliveryOrder = lazy(
  () => import('src/pages/ERP/ContractExecution/Production/DeliveryOrder/AddDeliveryOrder')
);

const PMRSpreadsheet = lazy(() => import('src/pages/ERP/ContractExecution/Production/PMRSpreadsheet'));
const AddPMRSpreadsheet = lazy(
  () => import('src/pages/ERP/ContractExecution/Production/PMRSpreadsheet/AddPMRSpreadsheet')
);

const PMR = lazy(() => import('src/pages/ERP/ContractExecution/Production/PMR'));
const AddPMR = lazy(() => import('src/pages/ERP/ContractExecution/Production/PMR/AddPMR'));

const RawMatEst = lazy(() => import('src/pages/ERP/ContractExecution/Production/RawMatEst'));
const AddRawMatEst = lazy(() => import('src/pages/ERP/ContractExecution/Production/RawMatEst/AddRawMatEst'));

const BOM = lazy(() => import('src/pages/ERP/ContractExecution/Production/BOM'));
const AddBOM = lazy(() => import('src/pages/ERP/ContractExecution/Production/BOM/AddBOM/AddBOM'));

//#endregion PRODUCTION MANAGEMENT

//#region INSTALLATION REQUEST
const InstallationOrder = lazy(() => import('src/pages/ERP/ContractExecution/Installation/InstallationOrder'));
const InstallationRequest = lazy(() => import('src/pages/ERP/ContractExecution/Installation/InstallationRequest'));
const WarehouseTransferRequest = lazy(
  () => import('src/pages/ERP/ContractExecution/Installation/WarehouseTransferRequest')
);
const InstallationBudgetPlan = lazy(
  () => import('src/pages/ERP/ContractExecution/Installation/InstallationBudgetPlan')
);
const InstallationProcess = lazy(() => import('src/pages/ERP/ContractExecution/Installation/InstallationProcess'));
const QualityControl = lazy(() => import('src/pages/ERP/ContractExecution/Installation/QualityControl'));
//#endregion INSTALLATION REQUEST
//#endregion CONTRACT EXECUTION

//#region FINANCE
const Finance = lazy(() => import('src/pages/ERP/Finance'));
const CashFlowPlan = lazy(() => import('src/pages/ERP/Finance/CashFlowPlan'));
const FinanceReport = lazy(() => import('src/pages/ERP/Finance/FinanceReport'));
const PaymentPlan = lazy(() => import('src/pages/ERP/Finance/PaymentPlan'));
const CashFlowReport = lazy(() => import('src/pages/ERP/Finance/CashFlowReport'));
//#endregion FINANCE

//#region ACCOUNTING
const PaymentRequestTicket = lazy(() => import('src/pages/ERP/Accounting/PaymentRequestTicket'));
//#endregion ACCOUNTING

//#region SALE
const Sale = lazy(() => import('src/pages/Sale'));
//#endregion SALE

//#region PLANNING
const ProjectPlanning = lazy(() => import('src/pages/ProjectPlanning'));
const MRP = lazy(() => import('src/pages/ProjectPlanning/MRP'));
const MRPReport = lazy(() => import('src/pages/ERP/Planning/MrpReport'));
const CompanyBudget = lazy(() => import('src/pages/ERP/Planning/CompanyBudget'));
const CostControl = lazy(() => import('src/pages/ERP/Planning/CostControl'));
const BudgetControl = lazy(() => import('src/pages/ERP/Planning/BudgetControl'));
const SrSummary = lazy(() => import('src/pages/ERP/Planning/SrSummary/SrSummary'));
const SrSummaryDetail = lazy(() => import('src/pages/ERP/Planning/SrSummary/SrSummaryDetail'));
const PrSummary = lazy(() => import('src/pages/ERP/Planning/PrSummary'));
const PrSummaryDetail = lazy(() => import('src/pages/ERP/Planning/PrSummary/PrSummaryDetail'));
//#endregion PLANNING

//#region RECRUITMENT
const Candidate = lazy(() => import('src/pages/ERP/HumanResource/RecruitmentManagement/Candidate'));
const EmploymentConfirmationNotice = lazy(
  () => import('src/pages/ERP/HumanResource/RecruitmentManagement/EmploymentConfirmationNotice')
);
const InterviewInvitationLetter = lazy(
  () => import('src/pages/ERP/HumanResource/RecruitmentManagement/InterviewInvitationLetter')
);
const JobOfferLetter = lazy(() => import('src/pages/ERP/HumanResource/RecruitmentManagement/JobOfferLetter'));
const JobRequirement = lazy(() => import('src/pages/ERP/HumanResource/RecruitmentManagement/JobRequirement'));
const RecruitmentActivityResults = lazy(
  () => import('src/pages/ERP/HumanResource/RecruitmentManagement/RecruitmentActivityResults')
);
const RecruitmentPlan = lazy(() => import('src/pages/ERP/HumanResource/RecruitmentManagement/RecruitmentPlan'));
const RecruitmentProcess = lazy(() => import('src/pages/ERP/HumanResource/RecruitmentManagement/RecruitmentProcess'));
const ResultNotificationLetter = lazy(
  () => import('src/pages/ERP/HumanResource/RecruitmentManagement/ResultNotificationLetter')
);
//#endregion RECRUITMENT

//#region TRAINING
const TrainingActivityResult = lazy(
  () => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingActivityResult')
);
const TrainingCost = lazy(() => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingCost'));
const TrainingDocumentation = lazy(
  () => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingDocumentation')
);
const TrainingInvitationLetter = lazy(
  () => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingInvitationLetter')
);
const TrainingParticipant = lazy(() => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingParticipant'));
const TrainingPlan = lazy(() => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingPlan'));
const TrainingProcess = lazy(() => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingProcess'));
const TrainingProgram = lazy(() => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingProgram'));
const TrainingRequirement = lazy(() => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingRequirement'));
const TrainingResult = lazy(() => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingResult'));
const TrainingResultNotification = lazy(
  () => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingResultNotification')
);
const TrainingSupplier = lazy(() => import('src/pages/ERP/HumanResource/TrainingManagement/TrainingSupplier'));
//#endregion TRAINING

//#region Human Resource
const HumanResource = lazy(() => import('src/pages/ERP/HumanResource'));
const CnbJobDescription = lazy(() => import('src/pages/ERP/HumanResource/CnbManagement/JobDescription'));
//#endregion Human Resource

//#region  STANDARDS QUALITY
const StandardQuality = lazy(() => import('src/pages/ERP/StandardsQuality'));
const ProductionPlan = lazy(() => import('src/pages/ERP/StandardsQuality/ProductionPlan'));
const AddProductionPlan = lazy(() => import('src/pages/ERP/StandardsQuality/AddProductionPlan'));
const ProductionPlanDetail = lazy(() => import('src/pages/ERP/StandardsQuality/ProductionPlanDetail'));
const Standard = lazy(() => import('src/pages/ERP/StandardsQuality/Standard'));
const CheckList = lazy(() => import('src/pages/ERP/StandardsQuality/CheckList'));
const CertificationAssessmentPlan = lazy(() => import('src/pages/ERP/StandardsQuality/CertificationAssessmentPlan'));
const QualityInspectionRequirement = lazy(() => import('src/pages/ERP/StandardsQuality/QualityInspectionRequirement'));
const QualityControlProcess = lazy(() => import('src/pages/ERP/StandardsQuality/QualityControlProcess'));
const MaterialQualityManagement = lazy(() => import('src/pages/ERP/StandardsQuality/MaterialQualityManagement'));
const ProductionProcess = lazy(() => import('src/pages/Rnd/ProductionProcess'));

//#endregion STANDARDS QUALITYimport ProductionRequestDetail from './../pages/ContractExecution/Production/ProductionRequest/ProductionRequestDetail/index';

export interface RouteType {
  path: string;
  redirect?: string;
  Layout?: any;
  SubLayout?: any;
  Component?: any;
  permission?: PermissionEn;
  checkPath?: string;
  isUsePathV2?: boolean;
  hasSidebar?: boolean;
  hasSubSidebar?: boolean;
  hasSubHeader?: boolean;
  isHideDetailLayout?: boolean;
  isDisableFooter?: boolean;
}

const routesV1: RouteType[] = [
  {
    path: urlPath.systemAdmin.location,
    Layout: MainLayout,
    Component: Home
  },
  {
    path: urlPath.ticketType.location,
    Layout: MainLayout,
    Component: TicketType,
    checkPath: urlPath.ticketType.location
  },
  {
    path: urlPath.ticketCategoryGroup.location,
    Layout: MainLayout,
    Component: TicketCategoryGroup,
    checkPath: urlPath.ticketCategoryGroup.location
  },
  {
    path: urlPath.ticketCategory.location,
    Layout: MainLayout,
    Component: TicketCategory,
    checkPath: urlPath.ticketCategory.location
  },
  {
    path: urlPath.ticketCategoryAdd.location,
    Layout: MainLayout,
    Component: AddTicketCategory,
    checkPath: urlPath.ticketCategory.location
  },
  {
    path: `${urlPath.ticketCategoryEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddTicketCategory,
    checkPath: urlPath.ticketCategory.location
  },
  {
    path: `${urlPath.ticketCategoryDetail.location}/:id`,
    Layout: MainLayout,
    Component: TicketCategoryDetail,
    permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.ticketForm.location,
    Layout: MainLayout,
    Component: TicketForm,
    checkPath: urlPath.ticketForm.location
  },
  {
    path: `${urlPath.ticketFormDetail.location}/:id`,
    Layout: MainLayout,
    Component: TicketFormDetail,
    permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.ticketFormAdd.location,
    Layout: MainLayout,
    Component: AddTicketForm,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.ticketFormEdit.location}/:id`,

    Layout: MainLayout,
    Component: AddTicketForm,
    permission: PermissionEn.update
  },
  {
    path: urlPath.reason.location,
    Layout: MainLayout,
    Component: Reason,
    checkPath: urlPath.reason.location
  },
  {
    path: urlPath.ticketFormMapping.location,
    Layout: MainLayout,
    Component: TicketFormMapping,
    checkPath: urlPath.ticketFormMapping.location
  },
  {
    path: urlPath.biometricLog.location,
    Layout: MainLayout,
    Component: BiometricLog
  },
  {
    path: urlPath.biometricEmployeeMapping.location,
    Layout: MainLayout,
    Component: BiometricEmployeeMapping,
    checkPath: urlPath.biometricEmployeeMapping.location
  },
  {
    path: urlPath.leaveRemainingYear.location,
    Layout: MainLayout,
    Component: LeaveRemainingYear,
    checkPath: urlPath.leaveRemainingYear.location
  },
  {
    path: urlPath.leaveTicketPackage.location,
    Layout: MainLayout,
    Component: LeaveTicketPackage,
    checkPath: urlPath.leaveTicketPackage.location
  },
  {
    path: urlPath.leaveTicketPackageAdd.location,
    Layout: MainLayout,
    Component: AddLeaveTicketPackage,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.leaveTicketPackageDetail.location}/:id`,
    Layout: MainLayout,
    Component: LeaveTicketPackageDetail,
    permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.additionalWorkingTimePackage.location,
    Layout: MainLayout,
    Component: AdditionalWorkingTimePackage,
    checkPath: urlPath.additionalWorkingTimePackage.location
  },
  {
    path: `${urlPath.additionalWorkingTimePackageDetail.location}/:id`,
    Layout: MainLayout,
    Component: AdditionalWorkingTimePackageDetail,
    permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.employeeCompensation.location,
    Layout: MainLayout,
    Component: EmployeeCompensation,
    permission: PermissionEn.create
  },
  {
    path: urlPath.timesheet.location,
    Layout: MainLayout,
    Component: Timesheet,
    checkPath: urlPath.timesheet.location
  },
  {
    path: urlPath.employeeShift.location,
    Layout: MainLayout,
    Component: EmployeeShift,
    checkPath: urlPath.employeeShift.location
  },
  {
    path: urlPath.advancePaymentEmployee.location,
    Layout: MainLayout,
    Component: AdvancePaymentEmployee,
    checkPath: urlPath.advancePaymentEmployee.location
  },
  {
    path: urlPath.template.location,
    Layout: MainLayout,
    Component: Template,
    checkPath: urlPath.template.location
  },
  {
    path: `${urlPath.templateEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddTemplate,
    permission: PermissionEn.update
  },
  {
    path: urlPath.templateAdd.location,
    Layout: MainLayout,
    Component: AddTemplate,
    permission: PermissionEn.update
  },
  {
    path: urlPath.templateAdd.location,
    Layout: MainLayout,
    Component: AddTemplate,
    permission: PermissionEn.create
  },
  {
    path: urlPath.approvalProcess.location,
    Layout: MainLayout,
    Component: ApprovalProcess,
    checkPath: urlPath.approvalProcess.location
  },
  {
    path: urlPath.approvalProcessAdd.location,
    Layout: MainLayout,
    Component: AddApprovalProcess,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.approvalProcessDetail.location}/:id`,
    Layout: MainLayout,
    Component: ApprovalProcessDetail,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.approvalProcessEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddApprovalProcess,
    permission: PermissionEn.update
  },
  {
    path: urlPath.branch.location,
    Layout: MainLayout,
    Component: Branch,
    checkPath: urlPath.branch.location
  },
  {
    path: urlPath.department.location,
    Layout: MainLayout,
    Component: Department,
    checkPath: urlPath.department.location
  },
  {
    path: urlPath.employee.location,
    Layout: MainLayout,
    Component: Employee,
    checkPath: urlPath.employee.location
  },
  {
    path: urlPath.employeeAdd.location,
    Layout: MainLayout,
    Component: AddEmployee,
    permission: PermissionEn.create
  },
  {
    path: urlPath.employeeQR.location,
    Layout: MainLayout,
    Component: EmployeeQrCode,
    permission: PermissionEn.get_qr
  },
  {
    path: `${urlPath.employeeEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddEmployee,
    permission: PermissionEn.update
  },
  {
    path: `${urlPath.employeeDetail.location}/:id`,
    Layout: MainLayout,
    Component: EmployeeDetail,
    permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.position.location,
    Layout: MainLayout,
    Component: Position,
    checkPath: urlPath.position.location
  },
  {
    path: urlPath.board.location,
    Layout: MainLayout,
    Component: Board,
    checkPath: urlPath.board.location
  },
  {
    path: urlPath.level.location,
    Layout: MainLayout,
    Component: Level,
    checkPath: urlPath.level.location
  },
  {
    path: urlPath.tier.location,
    Layout: MainLayout,
    Component: Tier,
    checkPath: urlPath.tier.location
  },
  {
    path: urlPath.jobCategory.location,
    Layout: MainLayout,
    Component: JobCategory,
    checkPath: urlPath.jobCategory.location
  },
  {
    path: urlPath.jobCategoryAdd.location,
    Layout: MainLayout,
    Component: AddJobCategory,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.jobCategoryEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddJobCategory,
    permission: PermissionEn.update
  },
  {
    path: urlPath.organizationChart.location,
    Layout: MainLayout,
    Component: OrganizationChart,
    checkPath: urlPath.organizationChart.location
  },
  {
    path: urlPath.partnerType.location,
    Layout: MainLayout,
    Component: PartnerType,
    checkPath: urlPath.partnerType.location
  },
  {
    path: urlPath.asset.location,
    Layout: MainLayout,
    Component: Asset,
    checkPath: urlPath.asset.location
  },
  {
    path: urlPath.degree.location,
    Layout: MainLayout,
    Component: Degree,
    checkPath: urlPath.degree.location
  },
  {
    path: urlPath.partner.location,
    Layout: MainLayout,
    Component: Partner,
    checkPath: urlPath.partner.location
  },
  {
    path: `${urlPath.partnerEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddPartner,
    permission: PermissionEn.update
  },
  {
    path: urlPath.partnerAdd.location,
    Layout: MainLayout,
    Component: AddPartner,
    permission: PermissionEn.create
  },
  {
    path: urlPath.accountingAccountType.location,
    Layout: MainLayout,
    Component: AccountingAccountType,
    checkPath: urlPath.accountingAccountType.location
  },
  {
    path: urlPath.accountingObjectType.location,
    Layout: MainLayout,
    Component: AccountingObjectType,
    checkPath: urlPath.accountingObjectType.location
  },
  {
    path: urlPath.generalAccountingAccount.location,
    Layout: MainLayout,
    Component: GeneralAccountingAccount,
    checkPath: urlPath.generalAccountingAccount.location
  },
  {
    path: urlPath.contractType.location,
    Layout: MainLayout,
    Component: ContractType,
    checkPath: urlPath.contractType.location
  },
  {
    path: urlPath.applicationInfo.location,
    Layout: MainLayout,
    Component: ApplicationInfo,
    checkPath: urlPath.applicationInfo.location
  },
  {
    path: `${urlPath.applicationInfoEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddApplicationInfo,
    permission: PermissionEn.update
  },
  {
    path: urlPath.applicationInfoAdd.location,
    Layout: MainLayout,
    Component: AddApplicationInfo,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.applicationInfoDetail.location}/:id`,
    Layout: MainLayout,
    Component: ApplicationInfoDetail,
    permission: PermissionEn.viewDetail
  },
  {
    path: urlPath.certificate.location,
    Layout: MainLayout,
    Component: Certificate,
    checkPath: urlPath.certificate.location
  },
  {
    path: urlPath.errorCode.location,
    Layout: MainLayout,
    Component: ErrorCode,
    checkPath: urlPath.errorCode.location
  },
  {
    path: urlPath.hrTicketCategory.location,
    Layout: MainLayout,
    Component: HRTicketCategory,
    checkPath: urlPath.hrTicketCategory.location
  },
  {
    path: urlPath.resource.location,
    Layout: MainLayout,
    Component: Resource,
    checkPath: urlPath.resource.location
  },
  {
    path: urlPath.applicationFunction.location,
    Layout: MainLayout,
    Component: ApplicationFunction,
    checkPath: urlPath.applicationFunction.location
  },
  {
    path: urlPath.role.location,
    Layout: MainLayout,
    Component: Role,
    checkPath: urlPath.role.location
  },
  {
    path: urlPath.roleAdd.location,
    Layout: MainLayout,
    Component: AddRole,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.roleEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddRole,
    permission: PermissionEn.update
  },
  {
    path: urlPath.userResource.location,
    Layout: MainLayout,
    Component: UserResource,
    checkPath: urlPath.userResource.location
  },
  {
    path: urlPath.permission.location,
    Layout: MainLayout,
    Component: Permission,
    checkPath: urlPath.permission.location
  },
  {
    path: urlPath.biometricDevice.location,
    Layout: MainLayout,
    Component: BiometricDevice,
    checkPath: urlPath.biometricDevice.location
  },
  {
    path: urlPath.distance.location,
    Layout: MainLayout,
    Component: Distance,
    checkPath: urlPath.distance.location
  },
  {
    path: urlPath.holiday.location,
    Layout: MainLayout,
    Component: Holiday,
    checkPath: urlPath.holiday.location
  },
  {
    path: urlPath.fuelConsumption.location,
    Layout: MainLayout,
    Component: FuelConsumption,
    checkPath: urlPath.fuelConsumption.location
  },
  {
    path: urlPath.fuelUnitPrice.location,
    Layout: MainLayout,
    Component: FuelUnitPrice,
    checkPath: urlPath.fuelUnitPrice.location
  },
  {
    path: urlPath.advancePaymentLimit.location,
    Layout: MainLayout,
    Component: AdvancePaymentLimit,
    checkPath: urlPath.advancePaymentLimit.location
  },
  {
    path: urlPath.employeeEvaluation.location,
    Layout: MainLayout,
    Component: EmployeeEvaluation,
    checkPath: urlPath.employeeEvaluation.location
  },
  {
    path: urlPath.subSystem.location,
    Layout: MainLayout,
    Component: SubSystem,
    checkPath: urlPath.subSystem.location
  },
  {
    path: urlPath.subSystemAdd.location,
    Layout: MainLayout,
    Component: AddSubSystem,
    checkPath: urlPath.subSystem.location
  },
  {
    path: `${urlPath.subSystemEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddSubSystem,
    checkPath: urlPath.subSystem.location
  },
  {
    path: urlPath.workDescription.location,
    Layout: MainLayout,
    Component: WorkDescription,
    checkPath: urlPath.workDescription.location
  },
  {
    path: urlPath.userTicketCategory.location,
    Layout: MainLayout,
    Component: UserTicketCategory,
    checkPath: urlPath.userTicketCategory.location
  },
  {
    path: urlPath.userTicketCategory.location,
    Layout: MainLayout,
    Component: UserTicketCategory
  },
  {
    path: urlPath.userJobCategory.location,
    Layout: MainLayout,
    Component: UserJobCategory,
    checkPath: urlPath.userJobCategory.location
  },
  {
    path: urlPath.shift.location,
    Layout: MainLayout,
    Component: Shift,
    checkPath: urlPath.shift.location
  },
  {
    path: urlPath.managerAssignment.location,
    Layout: MainLayout,
    Component: ManagerAssignment,
    checkPath: urlPath.managerAssignment.location
  },
  {
    path: urlPath.wareHouseGroup.location,
    Layout: MainLayout,
    Component: WareHouseGroup,
    checkPath: urlPath.wareHouseGroup.location
  },
  {
    path: urlPath.wareHouse.location,
    Layout: MainLayout,
    Component: WareHouse,
    checkPath: urlPath.wareHouse.location
  },
  {
    path: urlPath.mobileVersion.location,
    Layout: MainLayout,
    Component: MobileVersion,
    checkPath: urlPath.mobileVersion.location
  },
  {
    path: urlPath.productionUnit.location,
    Layout: MainLayout,
    Component: ProductionUnit,
    checkPath: urlPath.productionUnit.location
  },
  {
    path: urlPath.productionUnitAdd.location,
    Layout: MainLayout,
    Component: AddProductionUnit,
    checkPath: urlPath.productionUnit.location
  },
  {
    path: `${urlPath.productionUnitEdit.location}/:id`,
    Layout: MainLayout,
    Component: AddProductionUnit,
    checkPath: urlPath.productionUnit.location
  }
];

const routesV2: RouteType[] = [
  {
    path: urlPath.home.location,
    Layout: MainLayoutV2,
    Component: HomeV2
  },

  //#region TICKET RAISING
  {
    path: urlPath.hrTicketV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    redirect: urlPath.ticketRaising.location,
    hasSidebar: false,
    hasSubHeader: false
  },
  {
    path: urlPath.ticket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: urlPath.myTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.myTicket.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: urlPath.approval.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.approval.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: urlPath.accept.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.accept.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: urlPath.solutionV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.solutionV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.solutionAddV2.location}`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketDetailSolutionAddV2,
    hasSidebar: false,
    isHideDetailLayout: true
  },
  {
    path: `${urlPath.solutionEditV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketDetailSolutionAddV2,
    hasSidebar: false,
    isHideDetailLayout: true
  },
  {
    path: urlPath.handle.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.handle.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TicketsV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.ticketAdd.location}`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddTicketV2,
    hasSubHeader: false,
    hasSidebar: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: `${urlPath.ticketEdit.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddTicketV2,
    hasSubHeader: false,
    hasSidebar: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: urlPath.myAdvancePaymentTicketV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AdvancePaymentTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.advancePaymentTicketV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    redirect: urlPath.myAdvancePaymentTicketV2.location,
    hasSidebar: true
  },
  {
    path: urlPath.advancePaymentTicketApprovalV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AdvancePaymentTicketV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.myAdvancePaymentTicketV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AdvancePaymentTicketV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.advancePaymentTicketApprovalV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AdvancePaymentTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.advancePaymentTicketAddV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddAdvancePaymentTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: `${urlPath.advancePaymentTicketEditV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddAdvancePaymentTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: urlPath.myTransportationTicketV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TransportationTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.transportationTicketV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    redirect: urlPath.myTransportationTicketV2.location,
    hasSidebar: true
  },
  {
    path: urlPath.transportationTicketApprovalV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TransportationTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.transportationTicketConfirmV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TransportationTicketV2,
    hasSidebar: true
    // checkPath: urlPath.transportationTicketConfirmV2.location
  },
  {
    path: urlPath.transportationTicketHandleV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TransportationTicketV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.myTransportationTicketV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TransportationTicketV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.transportationTicketApprovalV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TransportationTicketV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.transportationTicketConfirmV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TransportationTicketV2,
    hasSidebar: true
    // checkPath: urlPath.transportationTicketConfirmV2.location
  },
  {
    path: `${urlPath.transportationTicketHandleV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TransportationTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.transportationTicketAddV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddTransportationTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: `${urlPath.transportationTicketEditV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddTransportationTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: urlPath.myLeaveTicketV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: LeaveTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.leaveTicketV2.location,
    Layout: MainLayoutV2,
    redirect: urlPath.myLeaveTicketV2.location,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true
  },
  {
    path: urlPath.leaveTicketApprovalV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: LeaveTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.leaveTicketPackageApprovalV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: LeaveTicketPackageV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.myLeaveTicketV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: LeaveTicketV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.leaveTicketApprovalV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: LeaveTicketV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.leaveTicketPackageApprovalV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: LeaveTicketPackageV2,
    hasSidebar: true
  },
  {
    path: urlPath.leaveTicketAddV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddLeaveTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: `${urlPath.leaveTicketEditV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddLeaveTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: urlPath.myExternalWorkerTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: ExternalWorkerTicket,
    hasSidebar: true
  },
  {
    path: urlPath.externalWorkerTicketAdd.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddExternalWorkerTicket,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: `${urlPath.externalWorkerTicketAdd.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddExternalWorkerTicket,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: `${urlPath.myExternalWorkerTicket.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: ExternalWorkerTicket,
    hasSidebar: true
  },
  {
    path: urlPath.externalWorkerTicketApproval.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: ExternalWorkerTicket,
    hasSidebar: true
  },
  {
    path: `${urlPath.externalWorkerTicketApproval.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: ExternalWorkerTicket,
    hasSidebar: true
  },
  {
    path: urlPath.myExpenseTrackingRequestTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: ExpenseTrackingRequestTicket,
    hasSidebar: true
  },
  {
    path: urlPath.expenseTrackingRequestTicket.location,
    Layout: MainLayoutV2,
    redirect: urlPath.myExpenseTrackingRequestTicket.location,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true
  },
  {
    path: urlPath.expenseTrackingRequestTicketApproval.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: ExpenseTrackingRequestTicket,
    hasSidebar: true
  },
  {
    path: `${urlPath.myExpenseTrackingRequestTicket.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: ExpenseTrackingRequestTicket,
    hasSidebar: true
  },
  {
    path: `${urlPath.expenseTrackingRequestTicketApproval.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: ExpenseTrackingRequestTicket,
    hasSidebar: true
  },
  {
    path: urlPath.expenseTrackingRequestTicketAdd.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddExpenseTrackingRequestTicket,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: `${urlPath.expenseTrackingRequestTicketEdit.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddExpenseTrackingRequestTicket,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },

  {
    path: urlPath.myOvertimeTicketV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: OvertimeTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.overtimeTicketV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    redirect: urlPath.myOvertimeTicketV2.location,
    hasSidebar: true
  },
  {
    path: urlPath.overtimeTicketApprovalV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: OvertimeTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.overtimeTicketSupervisorConfirmV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: OvertimeTicketV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.overtimeTicketSupervisorConfirmV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: OvertimeTicketV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.myOvertimeTicketV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: OvertimeTicketV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.overtimeTicketApprovalV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: OvertimeTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.overtimeTicketAddV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddOvertimeTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: `${urlPath.overtimeTicketEditV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddOvertimeTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: urlPath.myBusinessTripTicketV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: BusinessTripTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.businessTripTicketV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    redirect: urlPath.myBusinessTripTicketV2.location,
    hasSidebar: true
  },
  {
    path: `${urlPath.myBusinessTripTicketV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: BusinessTripTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.businessTripTicketApprovalV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: BusinessTripTicketV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.businessTripTicketApprovalV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: BusinessTripTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.businessTripTicketAddV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddBusinessTripTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: `${urlPath.businessTripTicketEditV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddBusinessTripTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: urlPath.myPurchaseRequestTicketV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: PurchaseRequestTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.purchaseRequestTicketV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    redirect: urlPath.myPurchaseRequestTicketV2.location,
    hasSidebar: true
  },
  {
    path: `${urlPath.myPurchaseRequestTicketV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: PurchaseRequestTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.purchaseRequestTicketApprovalV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: PurchaseRequestTicketV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.purchaseRequestTicketApprovalV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: PurchaseRequestTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.purchaseRequestTicketAddV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddPurchaseRequestTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: `${urlPath.purchaseRequestTicketEditV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddPurchaseRequestTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: urlPath.myEquipmentSupplyRequestTicketV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: EquipmentSupplyRequestTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.equipmentSupplyRequestTicketV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    redirect: urlPath.myEquipmentSupplyRequestTicketV2.location,
    hasSidebar: true
  },
  {
    path: `${urlPath.myEquipmentSupplyRequestTicketV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: EquipmentSupplyRequestTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.equipmentSupplyRequestTicketApprovalV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: EquipmentSupplyRequestTicketV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.equipmentSupplyRequestTicketApprovalV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: EquipmentSupplyRequestTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.equipmentSupplyRequestTicketAddV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddEquipmentSupplyRequestTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: `${urlPath.equipmentSupplyRequestTicketEditV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddEquipmentSupplyRequestTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: urlPath.myAdditionalWorkingTimeTicketV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AdditionalWorkingTimeTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.additionalWorkingTimeTicketV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    redirect: urlPath.myAdditionalWorkingTimeTicketV2.location,
    hasSidebar: true
  },
  {
    path: `${urlPath.myAdditionalWorkingTimeTicketV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AdditionalWorkingTimeTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.additionalWorkingTimeTicketAddV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddAdditionalWorkingTimeTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: `${urlPath.additionalWorkingTimeTicketEditV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AddAdditionalWorkingTimeTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: urlPath.additionalWorkingTimeTicketApprovalV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AdditionalWorkingTimePackageV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.additionalWorkingTimeTicketApprovalV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: AdditionalWorkingTimePackageV2,
    hasSidebar: true
  },
  {
    path: urlPath.hrConfirmV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    redirect: urlPath.ticketRaising.location,
    hasSidebar: false,
    hasSubHeader: false
  },
  {
    path: urlPath.hrConfirmLeaveTicketV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: LeaveTicketHrConfirmV2,
    hasSidebar: true,
    checkPath: urlPath.hrConfirmLeaveTicketV2.location
  },
  {
    path: `${urlPath.hrConfirmLeaveTicketV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: LeaveTicketHrConfirmV2,
    hasSidebar: true,
    checkPath: urlPath.hrConfirmLeaveTicketV2.location
  },
  {
    path: urlPath.hrConfirmExpenseTrackingRequestTicket.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: ExpenseTrackingRequestTicketHrConfirm,
    hasSidebar: true,
    checkPath: urlPath.hrConfirmExpenseTrackingRequestTicket.location,
    isUsePathV2: true
  },
  {
    path: `${urlPath.hrConfirmExpenseTrackingRequestTicket.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: ExpenseTrackingRequestTicketHrConfirm,
    hasSidebar: true,
    checkPath: urlPath.hrConfirmExpenseTrackingRequestTicket.location,
    isUsePathV2: true
  },

  {
    path: urlPath.hrConfirmBusinessTripTicketV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: BusinessTripHrConfirmV2,
    hasSidebar: true,
    checkPath: urlPath.hrConfirmBusinessTripTicketV2.location
  },
  {
    path: `${urlPath.hrConfirmBusinessTripTicketV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: BusinessTripHrConfirmV2,
    hasSidebar: true,
    checkPath: urlPath.hrConfirmBusinessTripTicketV2.location
  },
  {
    path: urlPath.hrConfirmOvertimeTicketV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: OvertimeHrConfirmV2,
    hasSidebar: true,
    checkPath: urlPath.hrConfirmOvertimeTicketV2.location
  },
  {
    path: `${urlPath.hrConfirmOvertimeTicketV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: OvertimeHrConfirmV2,
    hasSidebar: true,
    checkPath: urlPath.hrConfirmOvertimeTicketV2.location
  },
  {
    path: urlPath.hrConfirmTransportationTicketV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TransportationHrConfirmV2,
    hasSidebar: true,
    checkPath: urlPath.hrConfirmTransportationTicketV2.location
  },
  {
    path: `${urlPath.hrConfirmTransportationTicketV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: TransportationHrConfirmV2,
    hasSidebar: true,
    checkPath: urlPath.hrConfirmTransportationTicketV2.location
  },
  {
    path: urlPath.biometricLogV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: BiometricLogV2,
    hasSidebar: false
  },
  {
    path: urlPath.report.location,
    Layout: MainLayoutV2,
    SubLayout: ReportLayout,
    Component: ReportManagement
  },
  {
    path: urlPath.reportLeaveTicket.location,
    checkPath: urlPath.reportLeaveTicket.location,
    Layout: MainLayoutV2,
    SubLayout: ReportLayout,
    Component: ReportLeaveTicket
  },
  {
    path: urlPath.reportOvertimeTicket.location,
    checkPath: urlPath.reportOvertimeTicket.location,
    Layout: MainLayoutV2,
    SubLayout: ReportLayout,
    Component: ReportOvertimeTicket
  },
  // {
  //   path: urlPath.reportPNL04.location,
  //   checkPath: urlPath.reportPNL04.location,
  //   Layout: MainLayoutV2,
  //   SubLayout: ReportLayout,
  //   Component: PNL04
  // },
  {
    path: urlPath.reportBusinessTripTicket.location,
    checkPath: urlPath.reportBusinessTripTicket.location,
    Layout: MainLayoutV2,
    SubLayout: ReportLayout,
    Component: ReportBusinessTripTicket
  },
  {
    path: urlPath.reportDailyInventoryJournal.location,
    checkPath: urlPath.reportDailyInventoryJournal.location,
    Layout: MainLayoutV2,
    SubLayout: ReportLayout,
    Component: ReportDailyInventoryJournal
  },
  {
    path: urlPath.userGuideV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: UserGuides,
    hasSidebar: false
  },
  {
    path: urlPath.topic.location,
    Layout: MainLayoutV2,
    SubLayout: TopicLayout,
    Component: TopicConversationBox
  },
  {
    path: `${urlPath.topic.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TopicLayout,
    Component: TopicConversationBox
  },
  {
    path: urlPath.homeSub.location,
    Layout: MainLayoutV2,
    Component: HomeSub
  },
  {
    path: urlPath.qrScanner.location,
    Layout: MainLayoutV2,
    Component: HomeSub
  },
  {
    path: urlPath.biometricLogSub.location,
    Layout: MainLayoutV2,
    SubLayout: SubLayout,
    Component: BiometricLogV2,
    hasSidebar: false
  },
  {
    path: urlPath.myLeaveTicketSub.location,
    Layout: MainLayoutV2,
    SubLayout: SubLayout,
    Component: LeaveTicketV2,
    hasSidebar: false,
    hasSubSidebar: false
  },
  {
    path: `${urlPath.myLeaveTicketSub.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: SubLayout,
    Component: LeaveTicketV2,
    hasSidebar: false
  },
  {
    path: urlPath.leaveTicketAddSub.location,
    Layout: MainLayoutV2,
    SubLayout: SubLayout,
    Component: AddLeaveTicketSub,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: `${urlPath.leaveTicketEditSub.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: SubLayout,
    Component: AddLeaveTicketSub,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: urlPath.myHandoverTicketV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: HandoverTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.handoverTicketApprovalV2.location,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: HandoverTicketV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.myHandoverTicketV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: HandoverTicketV2,
    hasSidebar: true
  },
  {
    path: `${urlPath.handoverTicketApprovalV2.location}/:id`,
    Layout: MainLayoutV2,
    SubLayout: TicketLayout,
    Component: HandoverTicketV2,
    hasSidebar: true
  },
  {
    path: urlPath.handoverTicketAddV2.location,
    Layout: MainLayoutV2,
    Component: AddHandoverTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  {
    path: `${urlPath.handoverTicketEditV2.location}/:id`,
    Layout: MainLayoutV2,
    Component: AddHandoverTicketV2,
    hasSidebar: false,
    hasSubHeader: false,
    isDisableFooter: true,
    isHideDetailLayout: true
  },
  //#endregion TICKET RAISING

  //#region HRM
  {
    path: urlPath.humanResource.location,
    Layout: NewLayout,
    Component: HumanResource
  },

  //#region C&B
  {
    path: urlPath.cnbEmployee.location,
    Layout: NewLayout,
    Component: Employee
  },
  {
    path: urlPath.cnbEmployeeAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddEmployee,
    permission: PermissionEn.create
  },
  {
    path: urlPath.cnbEmployeeQR.location,
    Layout: NewLayout,
    Component: EmployeeQrCode,
    permission: PermissionEn.get_qr
  },
  {
    path: `${urlPath.cnbEmployeeEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddEmployee,
    permission: PermissionEn.update
  },
  {
    path: `${urlPath.cnbEmployeeDetail.location}/:id`,
    Layout: NewLayout,
    Component: EmployeeDetail
  },
  {
    path: urlPath.cnbDepartment.location,
    Layout: NewLayout,
    Component: Department
  },
  {
    path: urlPath.cnbJobCategory.location,
    Layout: NewLayout,
    Component: JobCategory
  },
  {
    path: urlPath.cnbJobCategoryAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddJobCategory,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.cnbJobCategoryEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddJobCategory,
    permission: PermissionEn.update
  },
  {
    path: urlPath.recruitmentJobDescription.location,
    Layout: NewLayout,
    Component: CnbJobDescription
  },
  //#endregion C&B

  //#region RECRUITMENT
  {
    path: urlPath.recruitmentProcess.location,
    Layout: NewLayout,
    Component: RecruitmentProcess
  },
  {
    path: urlPath.recruitmentJobDescription.location,
    Layout: NewLayout,
    Component: CnbJobDescription
  },

  {
    path: urlPath.recruitmentPlan.location,
    Layout: NewLayout,
    Component: RecruitmentPlan
  },
  {
    path: urlPath.jobRequirements.location,
    Layout: NewLayout,
    Component: JobRequirement
  },
  {
    path: urlPath.recruitmentCandidate.location,
    Layout: NewLayout,
    Component: Candidate
  },
  {
    path: urlPath.interviewInvitationLetter.location,
    Layout: NewLayout,
    Component: InterviewInvitationLetter
  },
  {
    path: urlPath.resultNotificationLetter.location,
    Layout: NewLayout,
    Component: ResultNotificationLetter
  },
  {
    path: urlPath.jobOfferLetter.location,
    Layout: NewLayout,
    Component: JobOfferLetter
  },
  {
    path: urlPath.employmentConfirmationNotice.location,
    Layout: NewLayout,
    Component: EmploymentConfirmationNotice
  },
  {
    path: urlPath.recruitmentActivityResults.location,
    Layout: NewLayout,
    Component: RecruitmentActivityResults
  },
  //#endregion RECRUITMENT

  //#region TRAINING
  {
    path: urlPath.trainingProcess.location,
    Layout: NewLayout,
    Component: TrainingProcess
  },

  {
    path: urlPath.trainingDocumentation.location,
    Layout: NewLayout,
    Component: TrainingDocumentation
  },
  {
    path: urlPath.trainingJobDescription.location,
    Layout: NewLayout,
    Component: CnbJobDescription
  },
  {
    path: urlPath.trainingPlan.location,
    Layout: NewLayout,
    Component: TrainingPlan
  },
  {
    path: urlPath.trainingRequirements.location,
    Layout: NewLayout,
    Component: TrainingRequirement
  },
  {
    path: urlPath.trainingSupplier.location,
    Layout: NewLayout,
    Component: TrainingSupplier
  },
  {
    path: urlPath.trainingProgram.location,
    Layout: NewLayout,
    Component: TrainingProgram
  },
  {
    path: urlPath.trainingCost.location,
    Layout: NewLayout,
    Component: TrainingCost
  },
  {
    path: urlPath.trainingParticipant.location,
    Layout: NewLayout,
    Component: TrainingParticipant
  },
  {
    path: urlPath.trainingInvitationLetter.location,
    Layout: NewLayout,
    Component: TrainingInvitationLetter
  },
  {
    path: urlPath.trainingResult.location,
    Layout: NewLayout,
    Component: TrainingResult
  },
  {
    path: urlPath.trainingResultNotification.location,
    Layout: NewLayout,
    Component: TrainingResultNotification
  },
  {
    path: urlPath.trainingActivityResults.location,
    Layout: NewLayout,
    Component: TrainingActivityResult
  },
  //#endregion TRAINING

  //#endregion HRM

  //#region SALE
  {
    path: urlPath.sale.location,
    Layout: NewLayout,
    Component: Sale
  },
  // {
  //   path: urlPath.businessProposal.location,
  //   Layout: MainLayoutV2,
  //   SubLayout: SaleLayout,
  //   Component: BusinessProposal,
  //   hasSidebar: true,
  //   hasSubHeader: false,
  //   isDisableFooter: false
  // },
  {
    path: urlPath.salePr.location,
    Layout: NewLayout,
    Component: PurchaseRequest
  },
  {
    path: salePath.saleOrder,
    Layout: NewLayout,
    Component: SO
  },
  {
    path: salePath.saleOrderAdd,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddSO,
    permission: PermissionEn.create
  },
  {
    path: `${salePath.saleOrderEdit}/:code`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddSO
  },
  {
    path: `${salePath.saleOrderDetailCode}/:code`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddSO
  },
  {
    path: urlPath.saleWorkAssign.location,
    Layout: NewLayout,
    Component: WorkAssign
  },
  //#endregion SALE

  //#region PURCHASE
  {
    path: urlPath.purchase.location,
    Layout: NewLayout,
    Component: Purchase
  },
  {
    path: urlPath.receivePlan.location,
    Layout: NewLayout,
    Component: ReceivePlan
  },
  {
    path: urlPath.ordersHistory.location,
    Layout: NewLayout,
    Component: HistoryOrder
  },
  {
    path: urlPath.purchaseOrder.location,
    Layout: NewLayout,
    Component: PurchaseOrder
  },
  {
    path: urlPath.purchaseOrderStamp.location,
    Layout: NewLayout,
    Component: PurchaseOrderStamp
  },
  {
    path: urlPath.purchaseOrderAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddPurchaseOrder,
    permission: PermissionEn.create
  },
  {
    path: `${urlPath.purchaseOrderEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddPurchaseOrder
  },
  {
    path: `${urlPath.purchaseOrderDetail.location}/:id`,
    Layout: NewLayout,
    Component: PurchaseOrderDetail
  },
  {
    path: `${urlPath.purchaseOrderDetailCode.location}/:code`,
    Layout: NewLayout,
    Component: PurchaseOrderDetail
  },
  // GRPO REQUEST
  {
    path: urlPath.grpor.location,
    Layout: NewLayout,
    Component: GRPOR
  },
  {
    path: urlPath.grporAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddGRPOR
  },
  {
    path: `${urlPath.grporEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddGRPOR
  },
  {
    path: `${urlPath.grporDetail.location}/:id`,
    Layout: NewLayout,
    Component: GRPORDetail
  },
  {
    path: `${urlPath.grporDetailCode.location}/:code`,
    Layout: NewLayout,
    Component: GRPORDetail
  },

  // QC RESULT
  {
    path: urlPath.qcResult.location,
    Layout: NewLayout,
    redirect: urlPath.grpor.location
  },
  {
    path: urlPath.qcResultAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddQcResult
  },
  {
    path: `${urlPath.qcResultEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddQcResult
  },
  {
    path: `${urlPath.qcResultDetail.location}/:id`,
    Layout: NewLayout,
    Component: QcResultDetail
  },
  // HẠCH TOÁN - ACCOUNTING
  // {
  //   path: urlPath.purchaseAccounting.location,
  //   Layout: NewLayout,
  //   redirect: urlPath.grpo.location
  // },
  {
    path: urlPath.purchaseAccountingAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddAccounting
  },
  {
    path: `${urlPath.purchaseAccountingEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddAccounting
  },
  {
    path: `${urlPath.purchaseAccountingDetail.location}/:id`,
    Layout: NewLayout,
    Component: AccountingDetail
  },
  // ĐỀ NGHỊ THANH TOÁN - AP INVOICE
  // {
  //   path: urlPath.apInvoice.location,
  //   Layout: NewLayout,
  //   redirect: urlPath.grpo.location
  // },
  {
    path: urlPath.apInvoiceAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddAPInvoice
  },
  {
    path: `${urlPath.apInvoiceDetail.location}/:id`,
    Layout: NewLayout,
    Component: APInvoiceDetail
  },
  // ĐỀ NGHỊ CẤP HÀNG - SUPPLY REQUEST
  {
    path: urlPath.sr.location,
    Layout: NewLayout,
    Component: SupplyRequest
  },
  {
    path: urlPath.srAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddSupplyRequest
  },
  {
    path: `${urlPath.srEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddSupplyRequest
  },
  {
    path: `${urlPath.srDetail.location}/:id`,
    Layout: NewLayout,
    Component: SupplyRequestDetail
  },
  {
    path: `${urlPath.srDetailCode.location}/:code`,
    Layout: NewLayout,
    Component: SupplyRequestDetail
  },
  // ĐỀ NGHỊ MUA HÀNG - PURCHASE REQUEST
  {
    path: urlPath.purchaseRequest.location,
    Layout: NewLayout,
    Component: PurchaseRequest
  },

  {
    path: `${urlPath.purchaseRequestDetail.location}/:id`,
    Layout: NewLayout,
    Component: PurchaseRequestDetail
  },
  {
    path: `${urlPath.purchaseRequestDetailCode.location}/:code`,
    Layout: NewLayout,
    Component: PurchaseRequestDetail
  },
  // YÊU CẦU BÁO GIÁ MUA HÀNG - PURCHASE QUOTATION REQUEST
  {
    path: urlPath.pqr.location,
    Layout: NewLayout,
    Component: PQR
  },
  {
    path: urlPath.pqrAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddPQR
  },
  {
    path: `${urlPath.pqrEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddPQR
  },
  {
    path: `${urlPath.pqrDetail.location}/:id`,
    Layout: NewLayout,
    Component: PQRDetail
  },
  {
    path: `${urlPath.pqrDetailCode.location}/:code`,
    Layout: NewLayout,
    Component: PQRDetail
  },
  // BÁO GIÁ MUA HÀNG - PURCHASE QUOTATION
  {
    path: urlPath.purchaseQuotation.location,
    Layout: NewLayout,
    Component: PurchaseQuotation
  },
  {
    path: urlPath.purchaseQuotationVendorAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddPurchaseQuotationVendor
  },
  {
    path: urlPath.purchaseQuotationAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddPurchaseQuotation
  },
  {
    path: `${urlPath.purchaseQuotationEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddPurchaseQuotation
  },
  {
    path: `${urlPath.purchaseQuotationDetail.location}/:id`,
    Layout: NewLayout,
    Component: PurchaseQuotationDetail
  },
  {
    path: `${urlPath.purchaseQuotationDetailCode.location}/:code`,
    Layout: NewLayout,
    Component: PurchaseQuotationDetail
  },
  // CÔNG NỢ - DEBT
  {
    path: urlPath.debtGroup.location,
    Layout: NewLayout,
    Component: DebtGroup
  },
  {
    path: urlPath.debt.location,
    Layout: NewLayout,
    Component: Debt
  },
  // BỘ CHỨNG TỪ - SET OF DOCUMENT
  {
    path: urlPath.setOfDocument.location,
    Layout: NewLayout,
    Component: TemplateSetOfDocument
  },
  // LOẠI CHỨNG TỪ - DOCUMENT TYPE
  {
    path: urlPath.documentType.location,
    Layout: NewLayout,
    Component: DocumentType
  },
  // REF KEY
  {
    path: urlPath.refKey.location,
    Layout: NewLayout,
    Component: RefKey
  },
  //#endregion PURCHASING

  // #region WAREHOUSE
  {
    path: inventoryPath.inventoryGrpor,
    Layout: NewLayout,
    Component: GRPOR
  },
  {
    path: `${inventoryPath.inventoryGrporEdit}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddGRPOR
  },
  {
    path: `${inventoryPath.inventoryGrporDetail}/:id`,
    Layout: NewLayout,
    Component: GRPORDetail
  },
  {
    path: `${inventoryPath.inventoryGrporDetailCode}/:code`,
    Layout: NewLayout,
    Component: GRPORDetail
  },
  // #endregion WAREHOUSE

  //#region STANDARDS QUALITY
  {
    path: standardsQualityPath.standardsQuality,
    Layout: NewLayout,
    Component: StandardQuality
  },
  {
    path: standardsQualityPath.productionPlan,
    Layout: NewLayout,
    Component: ProductionPlan
  },
  {
    path: standardsQualityPath.productionPlanAdd,
    Layout: NewLayout,
    Component: AddProductionPlan
  },
  {
    path: `${standardsQualityPath.productionPlanEdit}/:id`,
    Layout: NewLayout,
    Component: AddProductionPlan
  },
  {
    path: `${standardsQualityPath.productionPlanDetail}/:id`,
    Layout: NewLayout,
    Component: ProductionPlanDetail
  },
  {
    path: standardsQualityPath.standard,
    Layout: NewLayout,
    Component: Standard
  },
  {
    path: standardsQualityPath.checkList,
    Layout: NewLayout,
    Component: CheckList
  },
  {
    path: standardsQualityPath.certificationAssessmentPlan,
    Layout: NewLayout,
    Component: CertificationAssessmentPlan
  },
  {
    path: standardsQualityPath.qualityInspectionRequirement,
    Layout: NewLayout,
    Component: QualityInspectionRequirement
  },
  {
    path: standardsQualityPath.qualityControlProcess,
    Layout: NewLayout,
    Component: QualityControlProcess
  },
  {
    path: standardsQualityPath.materialQualityManagement,
    Layout: NewLayout,
    Component: MaterialQualityManagement
  },
  //#endregion STANDARDS QUALITY

  //#region ITEM MASTER DATA
  {
    path: urlPath.itemMasterData.location,
    Layout: NewLayout,
    Component: ItemMasterDataHome
  },
  {
    path: urlPath.item.location,
    Layout: NewLayout,
    Component: ItemMasterData
  },
  {
    path: urlPath.itemAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddItemMasterData
  },
  {
    path: `${urlPath.itemEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddItemMasterData
  },
  {
    path: `${urlPath.itemDetail.location}/:id`,
    Layout: NewLayout,
    Component: ItemMasterDataDetail
  },
  {
    path: urlPath.itemType.location,
    Layout: NewLayout,
    Component: ItemType
  },
  {
    path: urlPath.itemTypeAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddItemType
  },
  {
    path: `${urlPath.itemTypeEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddItemType
  },
  {
    path: `${urlPath.itemTypeDetail.location}/:id`,
    Layout: NewLayout,
    Component: ItemTypeDetail
  },
  {
    path: urlPath.itemGroup.location,
    Layout: NewLayout,
    Component: ItemGroup
  },
  {
    path: urlPath.itemGroupAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddItemGroup
  },
  {
    path: `${urlPath.itemGroupEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddItemGroup
  },
  {
    path: `${urlPath.itemGroupDetail.location}/:id`,
    Layout: NewLayout,
    Component: ItemGroupDetail
  },
  {
    path: urlPath.model.location,
    Layout: NewLayout,
    Component: Model
  },
  {
    path: urlPath.modelAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddModel
  },
  {
    path: `${urlPath.modelEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddModel
  },
  {
    path: `${urlPath.modelDetail.location}/:id`,
    Layout: NewLayout,
    Component: ModelDetail
  },
  {
    path: urlPath.itemSpec.location,
    Layout: NewLayout,
    Component: ItemSpec
  },
  {
    path: urlPath.itemSpecAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddItemSpec
  },
  {
    path: `${urlPath.itemSpecEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddItemSpec
  },
  {
    path: `${urlPath.itemSpecDetail.location}/:id`,
    Layout: NewLayout,
    Component: ItemSpecDetail
  },
  {
    path: urlPath.accountingItem.location,
    Layout: NewLayout,
    Component: AccountingItem
  },
  {
    path: urlPath.specification.location,
    Layout: NewLayout,
    Component: Specification
  },
  {
    path: urlPath.itemTechnicalSpecification.location,
    Layout: NewLayout,
    Component: ItemTechnicalSpecification
  },
  //#endregion ITEM MASTER DATA

  //#region KPI
  {
    path: urlPath.target.location,
    Layout: NewLayout,
    Component: Target
  },
  {
    path: urlPath.targetAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddTarget
  },
  {
    path: `${urlPath.targetEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddTarget
  },
  {
    path: `${urlPath.targetDetail.location}/:id`,
    Layout: NewLayout,
    Component: TargetDetail
  },
  {
    path: urlPath.workAssign.location,
    Layout: NewLayout,
    Component: WorkAssign
  },
  {
    path: urlPath.workAssignAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddWorkAssign
  },
  {
    path: `${urlPath.workAssignEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddWorkAssign
  },
  {
    path: `${urlPath.workAssignDetail.location}/:id`,
    Layout: NewLayout,
    Component: WorkAssignDetail
  },
  {
    path: urlPath.workPlan.location,
    Layout: NewLayout,
    Component: WorkPlan
  },
  {
    path: urlPath.workPlanAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddWorkPlan
  },
  {
    path: `${urlPath.workPlanEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddWorkPlan
  },
  {
    path: `${urlPath.workPlanDetail.location}/:id`,
    Layout: NewLayout,
    Component: WorkPlanDetail
  },
  //#endregion KPI

  //#region PRODUCTION

  // BOM
  {
    path: urlPath.bom.location,
    Layout: NewLayout,
    Component: BOM
  },
  {
    path: urlPath.bomAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddBOM
  },
  {
    path: `${urlPath.bomEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddBOM
  },

  // DELIVERY ORDER
  {
    path: productionPath.deliveryOrder,
    Layout: NewLayout,
    Component: DeliveryOrder
  },
  {
    path: productionPath.deliveryOrderAdd,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddDeliveryOrder
  },
  {
    path: `${productionPath.deliveryOrderEdit}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddDeliveryOrder
  },
  // PMR SPREADSHEET
  {
    path: urlPath.pmrSpreadsheet.location,
    Layout: NewLayout,
    Component: PMRSpreadsheet
  },
  {
    path: urlPath.pmrSpreadsheetAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddPMRSpreadsheet
  },
  {
    path: `${urlPath.pmrSpreadsheetEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddPMRSpreadsheet
  },
  // PMR
  {
    path: urlPath.pmr.location,
    Layout: NewLayout,
    Component: PMR
  },
  {
    path: urlPath.pmrAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddPMR
  },
  {
    path: `${urlPath.pmrEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddPMR
  },
  // Raw Matterial Estimate
  {
    path: urlPath.rawMatEst.location,
    Layout: NewLayout,
    Component: RawMatEst
  },
  {
    path: urlPath.rawMatEstAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddRawMatEst
  },
  {
    path: `${urlPath.rawMatEstEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddRawMatEst
  },
  // DELIVERY REQUEST
  {
    path: productionPath.deliveryRequest,
    Layout: NewLayout,
    Component: DeliveryRequest
  },
  {
    path: productionPath.deliveryRequestAdd,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddDeliveryRequest
  },
  {
    path: `${productionPath.deliveryRequestEdit}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddDeliveryRequest
  },
  // PACKAGING REQUEST
  {
    path: urlPath.packagingRequest.location,
    Layout: NewLayout,
    Component: PackagingRequest
  },
  {
    path: urlPath.packagingRequestAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddPackagingRequest
  },
  {
    path: `${urlPath.packagingRequestEdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddPackagingRequest
  },
  // BUDGET PLAN
  {
    path: urlPath.productionBudgetPlan.location,
    Layout: NewLayout,
    Component: InstallationBudgetPlan
  },
  // PROCESS
  {
    path: urlPath.productionProcess.location,
    Layout: NewLayout,
    Component: ProductionProcess
  },
  // PROCESS
  {
    path: urlPath.productionQualityControl.location,
    Layout: NewLayout,
    Component: QualityControl
  },

  //#endregion PRODUCTION

  //#region PLANNING
  {
    path: urlPath.planning.location,
    Layout: NewLayout,
    Component: ProjectPlanning
  },
  {
    path: urlPath.companyBudget.location,
    Layout: NewLayout,
    Component: CompanyBudget
  },
  {
    path: urlPath.budgetControl.location,
    Layout: NewLayout,
    Component: BudgetControl
  },
  {
    path: urlPath.costControl.location,
    Layout: NewLayout,
    Component: CostControl
  },
  {
    path: urlPath.planningSrSummary.location,
    Layout: NewLayout,
    Component: SrSummary
  },
  {
    path: `${urlPath.planningSrSummaryDetail.location}/:code`,
    Layout: NewLayout,
    Component: SrSummaryDetail
  },
  {
    path: urlPath.planningPrSummary.location,
    Layout: NewLayout,
    Component: PrSummary
  },
  {
    path: `${urlPath.planningPrSummaryDetail.location}/:code`,
    Layout: NewLayout,
    Component: PrSummaryDetail
  },
  {
    path: urlPath.planningPQ.location,
    Layout: NewLayout,
    Component: PurchaseQuotation
  },
  {
    path: urlPath.planningPR.location,
    Layout: NewLayout,
    Component: PurchaseRequest
  },
  {
    path: `${urlPath.planningPRDetailCode.location}/:code`,
    Layout: NewLayout,
    Component: PurchaseRequestDetail
  },
  {
    path: urlPath.planningPRAdd.location,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddPurchaseRequest
  },
  {
    path: `${urlPath.planningPREdit.location}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddPurchaseRequest
  },
  {
    path: urlPath.planningSaleOrder.location,
    Layout: NewLayout,
    Component: SO
  },
  {
    path: `${urlPath.planningSODetailCode.location}/:code`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddSO
  },
  {
    path: urlPath.planningProductionOrder.location,
    Layout: NewLayout,
    Component: ProductionOrder
  },
  {
    path: urlPath.mrp.location,
    Layout: NewLayout,
    Component: MRP
  },
  {
    path: urlPath.mrpReports.location,
    Layout: NewLayout,
    Component: MRPReport
  },
  //#endregion PLANNING

  //#region FINANCE
  {
    path: urlPath.finance.location,
    Layout: NewLayout,
    Component: Finance
  },
  {
    path: urlPath.cashFlowPlan.location,
    Layout: NewLayout,
    Component: CashFlowPlan
  },
  {
    path: urlPath.financeReport.location,
    Layout: NewLayout,
    Component: FinanceReport
  },
  {
    path: urlPath.paymentPlan.location,
    Layout: NewLayout,
    Component: PaymentPlan
  },
  {
    path: urlPath.cashFlowReport.location,
    Layout: NewLayout,
    Component: CashFlowReport
  },
  //#endregion FINANCE

  //#region ACCOUNTING
  {
    path: accountingPath.debtPaying,
    Layout: NewLayout,
    Component: Debt
  },
  {
    path: accountingPath.accountingAccount,
    Layout: NewLayout,
    Component: GeneralAccountingAccount
  },
  {
    path: accountingPath.accountingPaymentRequestTicket,
    Layout: NewLayout,
    Component: PaymentRequestTicket
  },
  {
    path: accountingPath.debtTaking,
    Layout: NewLayout,
    Component: Debt
  },
  // GRPO
  {
    path: accountingPath.grpo,
    Layout: NewLayout,
    Component: GRPO
  },
  {
    path: accountingPath.grpoAdd,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddGRPO
  },
  {
    path: `${accountingPath.grpoEdit}/:id`,
    Layout: NewLayout,
    hasSidebar: false,
    Component: AddGRPO
  },
  {
    path: `${accountingPath.grpoDetail}/:id`,
    Layout: NewLayout,
    Component: GRPODetail
  },
  {
    path: `${accountingPath.grpoDetailCode}/:code`,
    Layout: NewLayout,
    Component: GRPODetail
  },
  //#endregion ACCOUNTING

  //#region  CONTRACT EXECUTION
  {
    path: urlPath.contractExecution.location,
    Layout: NewLayout,
    Component: ContractExecution
  },
  //#region installation
  //INSTALLATION ORDER
  {
    path: urlPath.installationOrder.location,
    Layout: NewLayout,
    Component: InstallationOrder
  },
  //INSTALLATION DELIVERY REQUEST
  {
    path: urlPath.installationDeliveryOrder.location,
    Layout: NewLayout,
    Component: DeliveryOrder
  },
  //INSTALLATION REQUEST
  {
    path: urlPath.installationRequest.location,
    Layout: NewLayout,
    Component: InstallationRequest
  },
  //WAREHOUSE TRANSFER REQUEST
  {
    path: urlPath.installationWareHouseTransferRequest.location,
    Layout: NewLayout,
    Component: WarehouseTransferRequest
  },
  //INSTALLATION BUDGET PLANNING
  {
    path: urlPath.installationBudgetPlan.location,
    Layout: NewLayout,
    Component: InstallationBudgetPlan
  },
  //INSTALLATION PROCESS
  {
    path: urlPath.installationProcess.location,
    Layout: NewLayout,
    Component: InstallationProcess
  },
  //INSTALLATION QUALITY CONTROL
  {
    path: urlPath.installationQualityControl.location,
    Layout: NewLayout,
    Component: QualityControl
  },
  //#endregion installation

  //#region maintenance
  //MAINTENANCE ORDER
  {
    path: urlPath.maintenanceOrder.location,
    Layout: NewLayout,
    Component: InstallationOrder
  },
  //MAINTENANCE DELIVERY REQUEST
  {
    path: urlPath.maintenanceDeliveryOrder.location,
    Layout: NewLayout,
    Component: DeliveryOrder
  },
  //MAINTENANCE REQUEST
  {
    path: urlPath.maintenanceRequest.location,
    Layout: NewLayout,
    Component: InstallationRequest
  },
  //WAREHOUSE TRANSFER REQUEST
  {
    path: urlPath.maintenanceWareHouseTransferRequest.location,
    Layout: NewLayout,
    Component: WarehouseTransferRequest
  },
  //MAINTENANCE BUDGET PLANNING
  {
    path: urlPath.maintenanceBudgetPlan.location,
    Layout: NewLayout,
    Component: InstallationBudgetPlan
  },
  //MAINTENANCE PROCESS
  {
    path: urlPath.maintenanceProcess.location,
    Layout: NewLayout,
    Component: InstallationProcess
  },
  //MAINTENANCE QUALITY CONTROL
  {
    path: urlPath.maintenanceQualityControl.location,
    Layout: NewLayout,
    Component: QualityControl
  },
  //#endregion maintenance

  //#region delivery
  //DELIVERY ORDER
  {
    path: productionPath.deliveryOrder,
    Layout: NewLayout,
    Component: InstallationOrder
  },
  //DELIVERY REQUEST
  {
    path: productionPath.deliveryRequest,
    Layout: NewLayout,
    Component: InstallationRequest
  },
  //WAREHOUSE TRANSFER REQUEST
  {
    path: urlPath.deliveryWareHouseTransferRequest.location,
    Layout: NewLayout,
    Component: WarehouseTransferRequest
  },
  //DELIVERY BUDGET PLANNING
  {
    path: urlPath.deliveryBudgetPlan.location,
    Layout: NewLayout,
    Component: InstallationBudgetPlan
  },
  //DELIVERY PROCESS
  {
    path: urlPath.deliveryProcess.location,
    Layout: NewLayout,
    Component: InstallationProcess
  },
  //DELIVERY QUALITY CONTROL
  {
    path: urlPath.deliveryQualityControl.location,
    Layout: NewLayout,
    Component: QualityControl
  },
  //#endregion delivery
  //#endregion CONTRACT EXECUTION

  ...accountingRoutes,
  ...inventoryRoutes,
  ...rndRoutes,
  ...productionRoutes
];

const commonRoutes: RouteType[] = [
  {
    path: urlPath.signinOidc.location,
    Component: SigninOidc
  },
  {
    path: urlPath.signoutOidc.location,
    Component: SignoutOidc
  },
  {
    path: urlPath.signoutCallbackOidc.location,
    Component: SignoutCallbackOidc
  },
  {
    path: urlPath.serverError.location,
    Layout: MainLayoutV2,
    Component: InternalServer
  },
  {
    path: '*',
    Layout: MainLayoutV2,
    Component: NotFound
  },
  {
    path: '/login',
    Component: Login
  }
];

export default function useRouteElements(resources: IResourceFullAccessDetail[]) {
  const permissionResource = usePermissionNew(
    [...routesV1, ...routesV2, ...commonRoutes].map((x) => x.path),
    resources
  );

  const hasPermission = (path: string, permission: PermissionEn) => {
    const similarResource = permissionResource?.filter((x) => path?.includes(x?.path as string));

    const mostSimilarPath = similarResource?.reduce((prevPath, currPath) => {
      return (currPath?.path?.length || 0) > (prevPath?.path?.length || 0) ? currPath : prevPath;
    }, similarResource[0]);
    if (mostSimilarPath) {
      return mostSimilarPath?.permissions?.includes(permission);
    }
    if (path === '/erp/purchase/po') {
      console.log(mostSimilarPath);
    }
    return false;
  };

  const paths = useMemo(() => {
    return resources.map((item) => item.path);
  }, [resources]);

  const routeElements = useRoutes(
    [...routesV1, ...routesV2, ...commonRoutes].map(
      ({
        path,
        redirect,
        Layout,
        SubLayout,
        Component,
        permission,
        checkPath,
        hasSidebar,
        hasSubSidebar,
        isDisableFooter,
        isHideDetailLayout,
        hasSubHeader
      }) => {
        let result = {};

        // const innerPaths = isUsePathV2 ? pathsV2 : paths;
        const resComponent = redirect ? <Navigate to={redirect} /> : <Component />;

        if (!SubLayout && Layout)
          result = {
            path,
            element: (
              <Layout isDisableFooter={isDisableFooter} hasSidebar={hasSidebar}>
                <Suspense>
                  {permission ? (
                    hasPermission(path, permission) ? (
                      resComponent
                    ) : (
                      <Forbidden />
                    )
                  ) : !checkPath || paths.length === 0 || paths?.includes(checkPath) ? (
                    resComponent
                  ) : (
                    <NotFound />
                  )}
                </Suspense>
              </Layout>
            )
          };
        else if (SubLayout && Layout)
          result = {
            path,
            element: (
              <Layout isDisableFooter={isDisableFooter}>
                <Suspense>
                  <SubLayout
                    hasSidebar={hasSidebar}
                    hasSubSidebar={hasSubSidebar}
                    hasSubHeader={hasSubHeader}
                    isHideDetailLayout={isHideDetailLayout}
                  >
                    <Suspense>
                      {permission ? (
                        hasPermission(path, permission) ? (
                          resComponent
                        ) : (
                          <Forbidden />
                        )
                      ) : !checkPath || paths.length === 0 || paths?.includes(checkPath) ? (
                        resComponent
                      ) : (
                        <NotFound />
                      )}
                    </Suspense>
                  </SubLayout>
                </Suspense>
              </Layout>
            )
          };
        else
          result = {
            path,
            element: resComponent
          };

        return result;
      }
    )
  );

  return routeElements;
}
