import type { FlatItem } from 'src/types/elements.type';
import { IHRTicketCategory, hrTicketCategoryAdd } from 'src/types/master_data/hrTicketCategory.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/hr-ticket-categories';

export const getHRTicketCategoriesDropdown = async () => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
};

export const getHRTicketCategories = async (
  searchCondition: string | undefined,
  sequenceStatus: string,
  page: number,
  pageSize: number,
  orderBy?: string
) => {
  return await http.get<PagedList<IHRTicketCategory>>(controller, {
    params: {
      name: searchCondition,
      sequenceStatus,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getHRTicketCategory = async (id: number) => {
  return await http.get<IHRTicketCategory>(`${controller}/${id}`);
};

export const addHRTicketCategory = async (hrTicketCategory: hrTicketCategoryAdd) => {
  return await http.post(controller, hrTicketCategory);
};

export const updateHRTicketCategory = async (id: number, hrTicketCategory: hrTicketCategoryAdd) => {
  return await http.put(`${controller}/${id}`, hrTicketCategory);
};

export const deleteHRTicketCategory = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};
