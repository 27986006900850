import { LeftOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Modal, Row, Typography } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IssueIcon, ListIcon, NewIcon, PlusIcon } from 'src/assets/icons';
import CustomCard from 'src/components/v2/Card';
import urlPath from 'src/constants/urlPath/urlPath';
import { TicketTypeEn } from 'src/constants/ticketType';
import '../RaiseTicketModal/RaiseTicketModal.scss';
const { Text } = Typography;

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isRaising?: boolean;
};

export default function TicketManagementModal({ isOpen, setIsOpen, isRaising = false }: Props) {
  const navigate = useNavigate();
  const [isRaisingTicket, setIsRaisingTicket] = useState<boolean>(isRaising);

  return (
    <Modal
      className='raise-ticket-modal'
      open={isOpen}
      maskClosable={false}
      destroyOnClose
      title={
        <Flex align='center'>
          {isRaisingTicket && (
            <Button
              type='text'
              size='small'
              className='back-btn'
              icon={<LeftOutlined />}
              onClick={() => {
                setIsRaisingTicket(false);
              }}
            />
          )}
          <Text className='title'>{isRaisingTicket ? 'Raise ticket' : 'Ticket management'}</Text>
        </Flex>
      }
      zIndex={1050}
      footer={null}
      onCancel={() => setIsOpen(false)}
    >
      {isRaisingTicket ? (
        <Row justify='space-evenly' className='card-row' gutter={[20, 20]}>
          <Col span={24}>
            <CustomCard
              image={NewIcon}
              imageSize={64}
              title='Activities'
              description='Release các activities của bạn.'
              layout='horizontal'
              action={null}
              size='medium'
              style={{ width: '100%' }}
              hoverable
              onClick={() => {
                navigate(`${urlPath.ticketAdd.location}?ticketTypeId=${TicketTypeEn.New}`);
                setIsOpen(false);
              }}
            />
          </Col>
          <Col span={24}>
            <CustomCard
              image={IssueIcon}
              imageSize={64}
              title='Sự cố, vấn đề phát sinh'
              description='Yêu cầu hỗ trợ hoặc giải quyết các sự cố.'
              layout='horizontal'
              action={null}
              size='medium'
              style={{ width: '100%' }}
              hoverable
              onClick={() => {
                navigate(`${urlPath.ticketAdd.location}?ticketTypeId=${TicketTypeEn.Issue}`);
                setIsOpen(false);
              }}
            />
          </Col>
        </Row>
      ) : (
        <Row justify='space-evenly' className='card-row' gutter={[20, 20]}>
          <Col span={24}>
            <CustomCard
              image={ListIcon}
              imageSize={64}
              title='Danh sách ticket'
              description='Xem danh sách những ticket bạn đã tạo hoặc được giao.'
              layout='horizontal'
              action={null}
              size='medium'
              style={{ width: '100%' }}
              onClick={() => navigate(urlPath.myTicket.location)}
              hoverable
            />
          </Col>
          <Col span={24}>
            <CustomCard
              image={PlusIcon}
              imageSize={64}
              title='Raise ticket'
              description='Gửi yêu cầu giải quyết sự cố phát sinh hoặc có trong kế hoạch.'
              layout='horizontal'
              action={null}
              size='medium'
              style={{ width: '100%' }}
              onClick={() => setIsRaisingTicket(true)}
              hoverable
            />
          </Col>
        </Row>
      )}
    </Modal>
  );
}
