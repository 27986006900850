import { CRUDPathSegmentEn } from 'src/constants/newFeatures/crudPathSegment.enum';

const standardsQualityPath = {
  standardsQuality: '/erp/standards-quality',

  // PRODUCTION PLAN - KẾ HOẠCH SẢN XUẤT
  productionPlan: '/erp/standards-quality/production-plan',
  productionPlanDetail: '/erp/standards-quality/production-plan',
  productionPlanAdd: '/erp/standards-quality/production-plan' + CRUDPathSegmentEn.ADD,
  productionPlanEdit: '/erp/standards-quality/production-plan' + CRUDPathSegmentEn.EDIT,

  // ACCEPTANCE TRACKING - BÁO CÁI NGHIỆM THU
  acceptanceTracking: '/erp/standards-quality/acceptance-tracking',
  acceptanceTrackingDetail: '/erp/standards-quality/acceptance-tracking',
  acceptanceTrackingAdd: '/erp/standards-quality/acceptance-tracking' + CRUDPathSegmentEn.ADD,
  acceptanceTrackingEdit: '/erp/standards-quality/acceptance-tracking' + CRUDPathSegmentEn.EDIT,

  // STANDARD - DANH MỤC TIÊU CHUẨN
  standard: '/erp/standards-quality/standard',

  // CHECK LIST - DANH MỤC CHECK LIST
  checkList: '/erp/standards-quality/check-list',

  // CERTIFICATION ASSESSMENT PLAN - KẾ HOẠCH ĐÁNH GIÁ CÁC CHỨNG NHẬN
  certificationAssessmentPlan: '/erp/standards-quality/certification-assessment-plan',

  // QUALITY INSPECTION REQUIREMENT - YÊU CẦU KIỂM TRA CHÂT LƯỢNG
  qualityInspectionRequirement: '/erp/standards-quality/quality-inspection-requirement',

  // QUALITY CONTROL PROCESS - QUY TRÌNH KIỂM SOÁT CHẤT LƯỢNG
  qualityControlProcess: '/erp/standards-quality/quality-control-process',

  // MATERIAL QUALITY MANAGEMENT - QUẢN LÝ CHẤT LƯỢNG NVL
  materialQualityManagement: '/erp/standards-quality/material-quality-management'
};
export default standardsQualityPath;
