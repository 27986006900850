import { lazy } from 'react';
import productionPath from 'src/constants/urlPath/productionPath';
import type { RouteType } from 'src/hooks/useRouteElements';
import NewLayout from '../layouts/NewFeatures/MainLayout';

const ProductionOrder = lazy(() => import('src/pages/ERP/ContractExecution/Production/ProductionOrder'));
const AddProductionOrder = lazy(
  () => import('src/pages/ERP/ContractExecution/Production/ProductionOrder/AddProductionOrder')
);

const ProductionRequest = lazy(() => import('src/pages/ERP/ContractExecution/Production/ProductionRequest'));
const AddProductionRequest = lazy(
  () => import('src/pages/ERP/ContractExecution/Production/ProductionRequest/AddProductionRequest')
);

const AcceptanceReport = lazy(() => import('src/pages/ERP/ContractExecution/Production/AcceptanceReport'));

const AcceptanceMinute = lazy(() => import('src/pages/ERP/ContractExecution/Production/AcceptanceMinute'));
const AcceptanceMinuteForm = lazy(
  () => import('src/pages/ERP/ContractExecution/Production/AcceptanceMinute/AcceptanceMinuteForm')
);

const AddRawMatBom = lazy(() => import('src/pages/ERP/ContractExecution/Production/RawMatBom/AddRawMatBom'));
const AddLaborBom = lazy(() => import('src/pages/ERP/ContractExecution/Production/LaborBom/AddLaborBom'));
const AddEquipmentBom = lazy(() => import('src/pages/ERP/ContractExecution/Production/EquipmentBom/AddEquipmentBom'));

const productionRoutes: RouteType[] = [
  // Production order - Phiếu yêu cầu sản xuất
  {
    path: productionPath.productionOrder,
    Layout: NewLayout,
    Component: ProductionOrder
  },
  {
    path: productionPath.productionOrderAdd,
    Layout: NewLayout,
    Component: AddProductionOrder
  },
  {
    path: `${productionPath.productionOrderEdit}/:code`,
    Layout: NewLayout,
    Component: AddProductionOrder
  },
  {
    path: `${productionPath.productionOrderDetailCode}/:code`,
    Layout: NewLayout,
    Component: AddProductionOrder
  },

  // Production request - Lệnh sản xuất
  {
    path: productionPath.productionRequest,
    Layout: NewLayout,
    Component: ProductionRequest
  },
  {
    path: productionPath.productionRequestAdd,
    Layout: NewLayout,
    Component: AddProductionRequest
  },
  {
    path: `${productionPath.productionRequestEdit}/:code`,
    Layout: NewLayout,
    Component: AddProductionRequest
  },
  {
    path: `${productionPath.productionRequestDetailCode}/:code`,
    Layout: NewLayout,
    Component: AddProductionRequest
  },
  // Acceptance report - Báo cáo  nghiệm thu
  {
    path: productionPath.acceptanceReport,
    Layout: NewLayout,
    Component: AcceptanceReport
  },
  // Acceptance minute - Biên bản nghiệm thu thành phẩm
  {
    path: productionPath.acceptanceMinute,
    Layout: NewLayout,
    Component: AcceptanceMinute
  },
  {
    path: productionPath.acceptanceMinuteAdd,
    Layout: NewLayout,
    Component: AcceptanceMinuteForm
  },
  {
    path: `${productionPath.acceptanceMinuteEdit}/:code`,
    Layout: NewLayout,
    Component: AcceptanceMinuteForm
  },
  {
    path: `${productionPath.acceptanceMinuteDetailCode}/:code`,
    Layout: NewLayout,
    Component: AcceptanceMinuteForm
  },

  // Raw Mat BOM - BOM Nguyên vật liệu
  {
    path: productionPath.rawMatBomAdd,
    Layout: NewLayout,
    Component: AddRawMatBom
  },
  {
    path: `${productionPath.rawMatBomEdit}/:code`,
    Layout: NewLayout,
    Component: AddRawMatBom
  },
  {
    path: `${productionPath.rawMatBomDetailCode}/:code`,
    Layout: NewLayout,
    Component: AddRawMatBom
  },
  // Labor BOM - BOM Nhân công
  {
    path: productionPath.laborBomAdd,
    Layout: NewLayout,
    Component: AddLaborBom
  },
  {
    path: `${productionPath.laborBomEdit}/:code`,
    Layout: NewLayout,
    Component: AddLaborBom
  },
  {
    path: `${productionPath.laborBomDetailCode}/:code`,
    Layout: NewLayout,
    Component: AddLaborBom
  },
  // Equipment BOM - BOM công cụ dụng cụ
  {
    path: productionPath.equipmentBomAdd,
    Layout: NewLayout,
    Component: AddEquipmentBom
  },
  {
    path: `${productionPath.equipmentBomEdit}/:code`,
    Layout: NewLayout,
    Component: AddEquipmentBom
  },
  {
    path: `${productionPath.equipmentBomDetailCode}/:code`,
    Layout: NewLayout,
    Component: AddEquipmentBom
  }
];

export default productionRoutes;
