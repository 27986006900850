export interface PathType {
  location: string;
  path: string[];
}

const getPath = (location: string, path: string[]): PathType => {
  const home: PathType = {
    location,
    path
  };
  return home;
};

const urlPath: { [key: string]: PathType } = {
  systemAdmin: getPath('/system-admin', ['sidebar.home']),

  //#region Ticket
  ticketType: getPath('/system-admin/ticket-type', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'sidebar.ticket.ticketType'
  ]),
  ticketCategoryGroup: getPath('/system-admin/ticket-category-group', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'sidebar.ticket.ticketCategoryGroup'
  ]),
  ticketCategory: getPath('/system-admin/ticket-category', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'sidebar.ticket.ticketCategory'
  ]),
  ticketCategoryEdit: getPath('/system-admin/ticket-category/edit', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'sidebar.ticket.ticketCategory',
    'action.edit'
  ]),
  ticketCategoryAdd: getPath('/system-admin/ticket-category/add', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'sidebar.ticket.ticketCategory',
    'action.add'
  ]),
  ticketCategoryDetail: getPath('/system-admin/ticket-category/detail', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'sidebar.ticket.ticketCategory',
    'page.detail'
  ]),
  ticketForm: getPath('/system-admin/ticket-form', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'sidebar.ticket.ticketForm'
  ]),
  ticketFormAdd: getPath('/system-admin/ticket-form/add', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'sidebar.ticket.ticketForm',
    'action.add'
  ]),
  ticketFormEdit: getPath('/system-admin/ticket-form/edit', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'sidebar.ticket.ticketForm',
    'action.edit'
  ]),
  ticketFormDetail: getPath('/system-admin/ticket-form/detail', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'sidebar.ticket.ticketForm',
    'page.detail'
  ]),
  ticketFormMapping: getPath('/system-admin/ticket-form-mapping', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'sidebar.ticket.ticketFormMapping'
  ]),
  reason: getPath('/system-admin/reason', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'sidebar.ticket.reason'
  ]),
  //#endregion

  //#region Hr ticket
  biometricLog: getPath('/system-admin/biometric-log', ['sidebar.hr.biometric.index', 'sidebar.hr.biometricLog']),
  biometricEmployeeMapping: getPath('/system-admin/biometric-employee-mapping', [
    'sidebar.hr.biometric.index',
    'sidebar.hr.biometricEmployeeMapping'
  ]),
  advancePaymentLimit: getPath('/system-admin/advance-payment-limit', [
    'v2.sidebar.home',
    'sidebar.hr.index',
    'sidebar.hr.advancePaymentLimit'
  ]),
  employeeEvaluation: getPath('/system-admin/employee-evaluation', [
    'v2.sidebar.home',
    'sidebar.hr.index',
    'sidebar.hr.employeeEvaluation'
  ]),
  leaveRemainingYear: getPath('/system-admin/leave-remaining-year', ['sidebar.home', 'v2.leaveRemainingYear.index']),
  employeeShift: getPath('/system-admin/employee-shift', ['sidebar.home', 'v2.employeeShift.index']),
  advancePaymentEmployee: getPath('/system-admin/advance-payment-employee', [
    'sidebar.home',
    'v2.advancePaymentEmployee.index'
  ]),
  leaveTicketPackage: getPath('/system-admin/leave-ticket-package', ['sidebar.home', 'v2.leaveTicketPackage.index']),
  leaveTicketPackageAdd: getPath('/system-admin/leave-ticket-package/add', [
    'sidebar.home',
    'v2.leaveTicketPackage.index',
    'action.add'
  ]),
  leaveTicketPackageDetail: getPath('/system-admin/leave-ticket-package/detail', [
    'sidebar.home',
    'v2.leaveTicketPackage.index',
    'page.detail'
  ]),
  additionalWorkingTimePackage: getPath('/system-admin/additional-working-time-package', [
    'sidebar.home',
    'v2.additionalWorkingTimePackage.index'
  ]),
  additionalWorkingTimePackageAdd: getPath('/system-admin/additional-working-time-package/add', [
    'sidebar.home',
    'v2.additionalWorkingTimePackage.index',
    'action.add'
  ]),
  additionalWorkingTimePackageDetail: getPath('/system-admin/additional-working-time-package/detail', [
    'sidebar.home',
    'v2.additionalWorkingTimePackage.index',
    'page.detail'
  ]),
  additionalWage: getPath('/system-admin/additional-wage', ['sidebar.home', 'v2.additionalWage.index']),
  employeeCompensation: getPath('/system-admin/employee-compensation', [
    'sidebar.home',
    'v2.employeeCompensation.index'
  ]),
  timesheet: getPath('/system-admin/timesheet', ['sidebar.home', 'sidebar.hr.timesheet']),
  timesheetAdd: getPath('/system-admin/timesheet/add', ['sidebar.home', 'v2.timesheet.index', 'action.add']),
  timesheetDetail: getPath('/system-admin/timesheet/detail', ['sidebar.home', 'v2.timesheet.index', 'page.detail']),
  //#endregion

  //#region Administration
  approvalProcess: getPath('/system-admin/approval-process', [
    'v2.sidebar.home',
    'sidebar.administration.index',
    'sidebar.administration.approvalProcess'
  ]),
  approvalProcessAdd: getPath('/system-admin/approval-process/add', [
    'v2.sidebar.home',
    'sidebar.administration.index',
    'sidebar.administration.approvalProcess',
    'action.add'
  ]),
  approvalProcessEdit: getPath('/system-admin/approval-process/edit', [
    'v2.sidebar.home',
    'sidebar.administration.index',
    'sidebar.administration.approvalProcess',
    'action.edit'
  ]),
  approvalProcessDetail: getPath('/system-admin/approval-process/detail', [
    'v2.sidebar.home',
    'sidebar.administration.index',
    'sidebar.administration.approvalProcess',
    'page.detail'
  ]),
  handleProcess: getPath('/system-admin/handle-process', [
    'v2.sidebar.home',
    'sidebar.administration.index',
    'sidebar.administration.handleProcess'
  ]),
  handleProcessAdd: getPath('/system-admin/handle-process/add', [
    'v2.sidebar.home',
    'sidebar.administration.index',
    'sidebar.administration.handleProcess',
    'action.add'
  ]),
  handleProcessEdit: getPath('/system-admin/handle-process/edit', [
    'v2.sidebar.home',
    'sidebar.administration.index',
    'sidebar.administration.handleProcess',
    'action.edit'
  ]),
  template: getPath('/system-admin/template', [
    'v2.sidebar.home',
    'sidebar.administration.index',
    'sidebar.administration.template'
  ]),
  templateAdd: getPath('/system-admin/template/add', [
    'v2.sidebar.home',
    'sidebar.administration.index',
    'sidebar.administration.template',
    'action.add'
  ]),
  templateEdit: getPath('/system-admin/template/edit', [
    'v2.sidebar.home',
    'sidebar.administration.index',
    'sidebar.administration.template',
    'action.edit'
  ]),
  //#endregion

  //#region Master data
  employee: getPath('/system-admin/employee', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.employee'
  ]),
  employeeAdd: getPath('/system-admin/employee/add', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.employee',
    'action.add'
  ]),
  employeeQR: getPath('/system-admin/employee/qr', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.employee',
    'action.getQR'
  ]),
  employeeEdit: getPath('/system-admin/employee/edit', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.employee',
    'action.edit'
  ]),
  employeeDetail: getPath('/system-admin/employee/detail', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.employee',
    'page.detail'
  ]),
  department: getPath('/system-admin/department', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.department'
  ]),
  branch: getPath('/system-admin/branch', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.branch'
  ]),
  position: getPath('/system-admin/position', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.position'
  ]),
  board: getPath('/system-admin/board', ['v2.sidebar.home', 'sidebar.master-data.index', 'sidebar.master-data.board']),
  level: getPath('/system-admin/level', ['v2.sidebar.home', 'sidebar.master-data.index', 'sidebar.master-data.level']),
  tier: getPath('/system-admin/tier', ['v2.sidebar.home', 'sidebar.master-data.index', 'sidebar.master-data.tier']),
  jobCategory: getPath('/system-admin/job-category', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.jobCategory'
  ]),
  jobCategoryAdd: getPath('/system-admin/job-category/add', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.jobCategory',
    'action.add'
  ]),
  jobCategoryEdit: getPath('/system-admin/job-category/edit', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.jobCategory',
    'action.edit'
  ]),
  organizationChart: getPath('/system-admin/organization-chart', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.organizationChart'
  ]),
  asset: getPath('/system-admin/asset', ['v2.sidebar.home', 'sidebar.master-data.index', 'sidebar.master-data.asset']),
  degree: getPath('/system-admin/degree', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.degree'
  ]),
  partnerType: getPath('/system-admin/partner-type', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.partnerType'
  ]),
  partner: getPath('/system-admin/partner', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.partner'
  ]),
  partnerAdd: getPath('/system-admin/partner/add', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.partner',
    'action.add'
  ]),
  partnerEdit: getPath('/system-admin/partner/edit', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.partner',
    'action.edit'
  ]),
  accountingAccountType: getPath('/system-admin/accounting-account-type', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.accountingAccountType'
  ]),
  accountingObjectType: getPath('/system-admin/accounting-object-type', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.accountingObjectType'
  ]),
  generalAccountingAccount: getPath('/system-admin/general-accounting-account', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.generalAccountingAccount'
  ]),
  employeeContract: getPath('/system-admin/employee-contract', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.employeeContract'
  ]),
  contractType: getPath('/system-admin/contract-type', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.contractType'
  ]),
  applicationInfo: getPath('/system-admin/application-info', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.applicationInfo'
  ]),
  applicationInfoAdd: getPath('/system-admin/application-info/add', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.applicationInfo',
    'action.add'
  ]),
  applicationInfoEdit: getPath('/system-admin/application-info/edit', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.applicationInfo',
    'action.edit'
  ]),
  applicationInfoDetail: getPath('/system-admin/application-info/detail', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.applicationInfo',
    'page.detail'
  ]),
  certificate: getPath('/system-admin/certificate', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.certificate'
  ]),
  errorCode: getPath('/system-admin/error-code', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.errorCode'
  ]),
  hrTicketCategory: getPath('/system-admin/hr-category', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.hrTicketCategory'
  ]),
  resource: getPath('/system-admin/resource', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.resource'
  ]),
  applicationFunction: getPath('/system-admin/application-function', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.applicationFunction'
  ]),
  role: getPath('/system-admin/role', ['v2.sidebar.home', 'sidebar.master-data.index', 'sidebar.master-data.role']),
  roleAdd: getPath('/system-admin/role/add', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.role',
    'action.add'
  ]),
  roleEdit: getPath('/system-admin/role/edit', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.role',
    'action.edit'
  ]),
  userResource: getPath('/system-admin/user-resource', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.userResource'
  ]),
  userResourceAdd: getPath('/system-admin/user-resource/add', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.userResource',
    'action.add'
  ]),
  userResourceEdit: getPath('/system-admin/user-resource/edit', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.userResource',
    'action.edit'
  ]),
  permission: getPath('/system-admin/permission', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.permission'
  ]),

  biometricDevice: getPath('/system-admin/biometric-device', [
    'sidebar.hr.biometric.index',
    'sidebar.master-data.biometricDevice'
  ]),
  distance: getPath('/system-admin/distance', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.distance'
  ]),
  holiday: getPath('/system-admin/holiday', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.holiday'
  ]),
  fuelConsumption: getPath('/system-admin/fuel-consumption', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.fuelConsumption'
  ]),
  fuelUnitPrice: getPath('/system-admin/fuel-unit-price', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.fuelUnitPrice'
  ]),
  subSystem: getPath('/system-admin/sub-system', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.subSystem'
  ]),
  subSystemAdd: getPath('/system-admin/sub-system/add', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.subSystem',
    'action.add'
  ]),
  subSystemEdit: getPath('/system-admin/sub-system/edit', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.subSystem',
    'action.edit'
  ]),
  workDescription: getPath('/system-admin/work-description', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.workDescription'
  ]),
  userTicketCategory: getPath('/system-admin/user-ticket-category', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.userTicketCategory'
  ]),
  userJobCategory: getPath('/system-admin/user-job-category', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.userJobCategory'
  ]),
  shift: getPath('/system-admin/shift', ['v2.sidebar.home', 'sidebar.master-data.index', 'sidebar.master-data.shift']),
  managerAssignment: getPath('/system-admin/manager-assignment', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.managerAssignment'
  ]),
  wareHouse: getPath('/system-admin/ware-house', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.wareHouse'
  ]),
  mobileVersion: getPath('/system-admin/mobile-versions', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.mobileVersion'
  ]),
  productionUnit: getPath('/interactive/system-admin/production-unit', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.productionUnit'
  ]),
  productionUnitAdd: getPath('/interactive/system-admin/production-unit/add', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.productionUnit',
    'action.add'
  ]),
  productionUnitEdit: getPath('/interactive/system-admin/production-unit/edit', [
    'v2.sidebar.home',
    'sidebar.master-data.index',
    'sidebar.master-data.productionUnit',
    'action.edit'
  ]),
  //#endregion

  //#region Signin
  logout: getPath('/logout', []),
  signinOidc: getPath('/signin-oidc', []),
  signoutOidc: getPath('/signout-oidc', []),
  signoutCallbackOidc: getPath('/signout-callback-oidc', []),
  //#endregion

  //#region Server Error
  serverError: getPath('/server-error', []),
  //#endregion

  //#region V2
  homeV2: getPath('/', ['v2.sidebar.home']),

  helpdeskV2: getPath('/help-desk', ['v2.sidebar.home', 'v2.helpdesk.index']),
  //#region Ticket
  ticketV2: getPath('/help-desk/ticket', ['v2.sidebar.home', 'v2.helpdesk.index', 'v2.sidebar.ticket.index']),
  ticketDetailV2: getPath('/help-desk/ticket/detail', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'action.page'
  ]),
  ticketAddV2: getPath('/help-desk/ticket/add', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'action.add'
  ]),
  ticketEditV2: getPath('/help-desk/ticket/edit', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'action.edit'
  ]),
  myTicketV2: getPath('/help-desk/ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'Ticket của tôi'
  ]),
  approvalV2: getPath('/help-desk/ticket/approval', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'v2.sidebar.approval'
  ]),
  acceptV2: getPath('/help-desk/ticket/accept', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'v2.sidebar.accept'
  ]),
  solutionV2: getPath('/help-desk/ticket/solution', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'v2.sidebar.handle'
  ]),
  solutionAddV2: getPath('/help-desk/ticket/solution/add', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'v2.sidebar.handle',
    'action.add'
  ]),
  solutionEditV2: getPath('/help-desk/ticket/solution/add', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'action.edit'
  ]),
  handleV2: getPath('/help-desk/ticket/handle', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.ticket.index',
    'v2.sidebar.myHandle'
  ]),
  //#endregion

  //#region Hr Ticket
  hrTicketV2: getPath('/help-desk/hr-ticket', ['v2.sidebar.home', 'v2.helpdesk.index', 'sidebar.hr.index']),

  //#region Advance Payment Ticket
  advancePaymentTicketV2: getPath('/help-desk/hr-ticket/advance-payment-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.advancePaymentTicket'
  ]),
  myAdvancePaymentTicketV2: getPath('/help-desk/hr-ticket/advance-payment-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.advancePaymentTicket',
    'Phiếu của tôi'
  ]),
  advancePaymentTicketApprovalV2: getPath('/help-desk/hr-ticket/advance-payment-ticket/approval', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.advancePaymentTicket',
    'v2.sidebar.approval'
  ]),
  advancePaymentTicketAddV2: getPath('/help-desk/hr-ticket/advance-payment-ticket/add', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.advancePaymentTicket',
    'action.add'
  ]),
  advancePaymentTicketEditV2: getPath('/help-desk/hr-ticket/advance-payment-ticket/edit', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.advancePaymentTicket',
    'action.edit'
  ]),
  advancePaymentTicketDetailV2: getPath('/help-desk/hr-ticket/advance-payment-ticket/detail', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.dvancePaymentTicket',
    'page.detail'
  ]),
  //#endregion

  //#region Leave ticket
  leaveTicketV2: getPath('/help-desk/hr-ticket/leave-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.leaveTicket'
  ]),
  myLeaveTicketV2: getPath('/help-desk/hr-ticket/leave-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.leaveTicket',
    'Phiếu của tôi'
  ]),
  leaveTicketApprovalV2: getPath('/help-desk/hr-ticket/leave-ticket/approval', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.leaveTicket',
    'v2.sidebar.approval'
  ]),
  leaveTicketPackageApprovalV2: getPath('/help-desk/hr-ticket/leave-ticket/approval-package', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.leaveTicket',
    'v2.sidebar.approval'
  ]),
  leaveTicketAddV2: getPath('/help-desk/hr-ticket/leave-ticket/add', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.leaveTicket',
    'action.add'
  ]),
  leaveTicketEditV2: getPath('/help-desk/hr-ticket/leave-ticket/edit', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.leaveTicket',
    'action.edit'
  ]),
  //#endregion

  //#region ExpenseTrackingRequestticket
  expenseTrackingRequestTicket: getPath('/help-desk/hr-ticket/expense-tracking-request-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.expenseTrackingRequestTicket'
  ]),
  myExpenseTrackingRequestTicket: getPath('/help-desk/hr-ticket/expense-tracking-request-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.expenseTrackingRequestTicket',
    'Phiếu của tôi'
  ]),
  expenseTrackingRequestTicketApproval: getPath('/help-desk/hr-ticket/expense-tracking-request-ticket/approval', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.expenseTrackingRequestTicket',
    'v2.sidebar.approval'
  ]),
  expenseTrackingRequestTicketAdd: getPath('/help-desk/hr-ticket/expense-tracking-request-ticket/add', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.expenseTrackingRequestTicket',
    'action.add'
  ]),
  expenseTrackingRequestTicketEdit: getPath('/help-desk/hr-ticket/expense-tracking-request-ticket/edit', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.expenseTrackingRequestTicket',
    'action.edit'
  ]),
  //#endregion

  //#region Overtime ticket
  overtimeTicketV2: getPath('/help-desk/hr-ticket/overtime-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.overtimeTicket'
  ]),
  myOvertimeTicketV2: getPath('/help-desk/hr-ticket/overtime-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.overtimeTicket',
    'Phiếu của tôi'
  ]),
  overtimeTicketApprovalV2: getPath('/help-desk/hr-ticket/overtime-ticket/approval', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.overtimeTicket',
    'v2.sidebar.approval'
  ]),
  overtimeTicketAddV2: getPath('/help-desk/hr-ticket/overtime-ticket/add', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.overtimeTicket',
    'action.add'
  ]),
  overtimeTicketEditV2: getPath('/help-desk/hr-ticket/overtime-ticket/edit', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.overtimeTicket',
    'action.edit'
  ]),
  overtimeTicketConfirmV2: getPath('/help-desk/hr-ticket/overtime-ticket/confirm', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.overtimeTicket',
    'action.confirm'
  ]),
  overtimeTicketSupervisorConfirmV2: getPath('/help-desk/hr-ticket/overtime-ticket/supervisor-confirm', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.overtimeTicket',
    'action.supervisorConfirm'
  ]),
  //#endregion

  //#region Business trip ticket
  businessTripTicketV2: getPath('/help-desk/hr-ticket/business-trip-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.businessTripTicket'
  ]),
  myBusinessTripTicketV2: getPath('/help-desk/hr-ticket/business-trip-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.businessTripTicket',
    'Phiếu của tôi'
  ]),
  businessTripTicketApprovalV2: getPath('/help-desk/hr-ticket/business-trip-ticket/approval', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.businessTripTicket',
    'Phê duyệt'
  ]),
  businessTripTicketAddV2: getPath('/help-desk/hr-ticket/business-trip-ticket/add', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.businessTripTicket',
    'action.add'
  ]),
  businessTripTicketEditV2: getPath('/help-desk/hr-ticket/business-trip-ticket/edit', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.businessTripTicket',
    'action.edit'
  ]),
  //#endregion

  //#region Purchase request
  purchaseRequestTicketV2: getPath('/help-desk/hr-ticket/purchase-request-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.purchaseRequestTicket'
  ]),
  myPurchaseRequestTicketV2: getPath('/help-desk/hr-ticket/purchase-request-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.purchaseRequestTicket',
    'Phiếu của tôi'
  ]),
  purchaseRequestTicketApprovalV2: getPath('/help-desk/hr-ticket/purchase-request-ticket/approval', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.purchaseRequestTicket',
    'Phê duyệt'
  ]),
  purchaseRequestTicketAddV2: getPath('/help-desk/hr-ticket/purchase-request-ticket/add', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.purchaseRequestTicket',
    'action.add'
  ]),
  purchaseRequestTicketEditV2: getPath('/help-desk/hr-ticket/purchase-request-ticket/edit', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.purchaseRequestTicket',
    'action.edit'
  ]),
  //#endregion

  //#region Transportation ticket
  transportationTicketV2: getPath('/help-desk/hr-ticket/transportation-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.transportationTicket'
  ]),
  myTransportationTicketV2: getPath('/help-desk/hr-ticket/transportation-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.transportationTicket',
    'Phiếu của tôi'
  ]),
  transportationTicketApprovalV2: getPath('/help-desk/hr-ticket/transportation-ticket/approval', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.transportationTicket',
    'Phê duyệt'
  ]),
  transportationTicketConfirmV2: getPath('/help-desk/hr-ticket/transportation-ticket/confirm', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.transportationTicket',
    'Xác nhận'
  ]),
  transportationTicketHandleV2: getPath('/help-desk/hr-ticket/transportation-ticket/handle', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.transportationTicket',
    'Phiếu của tôi xử lý'
  ]),
  transportationTicketAddV2: getPath('/help-desk/hr-ticket/transportation-ticket/add', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.transportationTicket',
    'action.add'
  ]),
  transportationTicketEditV2: getPath('/help-desk/hr-ticket/transportation-ticket/edit', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.transportationTicket',
    'action.edit'
  ]),
  //#endregion

  //#region Equipment Supply Request Ticket
  equipmentSupplyRequestTicketV2: getPath('/help-desk/hr-ticket/equipment-supply-request-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.equipmentSupplyRequestTicket'
  ]),
  myEquipmentSupplyRequestTicketV2: getPath('/help-desk/hr-ticket/equipment-supply-request-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.equipmentSupplyRequestTicket',
    'Phiếu của tôi'
  ]),
  equipmentSupplyRequestTicketApprovalV2: getPath('/help-desk/hr-ticket/equipment-supply-request-ticket/approval', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.equipmentSupplyRequestTicket',
    'Phê duyệt'
  ]),
  equipmentSupplyRequestTicketAddV2: getPath('/help-desk/hr-ticket/equipment-supply-request-ticket/add', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.equipmentSupplyRequestTicket',
    'action.add'
  ]),
  equipmentSupplyRequestTicketEditV2: getPath('/help-desk/hr-ticket/equipment-supply-request-ticket/edit', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.equipmentSupplyRequestTicket',
    'action.edit'
  ]),
  //#endregion

  //#region Additional Working Time Ticket
  additionalWorkingTimeTicketV2: getPath('/help-desk/hr-ticket/additional-working-time-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.additionalWorkingTimeTicket'
  ]),
  myAdditionalWorkingTimeTicketV2: getPath('/help-desk/hr-ticket/additional-working-time-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.additionalWorkingTimeTicket',
    'Phiếu của tôi'
  ]),
  additionalWorkingTimeTicketApprovalV2: getPath('/help-desk/hr-ticket/additional-working-time-ticket/approval', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.additionalWorkingTimeTicket',
    'Phê duyệt'
  ]),
  additionalWorkingTimeTicketAddV2: getPath('/help-desk/hr-ticket/additional-working-time-ticket/add', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.additionalWorkingTimeTicket',
    'action.add'
  ]),
  additionalWorkingTimeTicketEditV2: getPath('/help-desk/hr-ticket/additional-working-time-ticket/edit', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.additionalWorkingTimeTicket',
    'action.edit'
  ]),
  //#endregion

  //#region Additional Working Time Package
  additionalWorkingTimePackageV2: getPath('/help-desk/hr-ticket/additional-working-time-package', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket. additionalWorkingTimePackage'
  ]),
  additionalWorkingTimePackageApprovalV2: getPath('/help-desk/hr-ticket/additional-working-time-package/approval', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket. additionalWorkingTimePackage',
    'Phê duyệt'
  ]),
  //#endregion

  //#region Hr confirm
  hrConfirmV2: getPath('/help-desk/hr-confirm', ['v2.sidebar.home', 'v2.sidebar.hrConfirm.index']),
  hrConfirmLeaveTicketV2: getPath('/help-desk/hr-confirm/leave-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrConfirm.index',
    'v2.sidebar.hrConfirm.leaveTicket'
  ]),
  hrConfirmExpenseTrackingRequestTicket: getPath('/help-desk/hr-confirm/expense-tracking-request-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrConfirm.index',
    'v2.sidebar.hrConfirm.leaveTicket'
  ]),
  hrConfirmOvertimeTicketV2: getPath('/help-desk/hr-confirm/overtime-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrConfirm.index',
    'v2.sidebar.hrConfirm.overtimeTicket'
  ]),
  hrConfirmBusinessTripTicketV2: getPath('/help-desk/hr-confirm/business-trip-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrConfirm.index',
    'v2.sidebar.hrConfirm.businessTripTicket'
  ]),
  hrConfirmTransportationTicketV2: getPath('/help-desk/hr-confirm/transportation-ticket', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrConfirm.index',
    'v2.sidebar.hrConfirm.transportationTicket'
  ]),
  //#endregion

  //#region Hr confirm
  biometricLogV2: getPath('/biometric-log', ['v2.sidebar.home', 'v2.sidebar.hrTicket.biometricLog']),
  //#endregion
  //#endregion
  //#endregion

  //#region USER GUIDE
  userGuideV2: getPath('/user-guide', ['sidebar.home', 'v2.userGuide.index']),
  userGuideAddV2: getPath('/user-guide/add', ['sidebar.home', 'v2.userGuide.index', 'action.add']),
  //#endregion USER GUIDE

  //#region SUB
  homeSub: getPath('/s', ['v2.sidebar.home']),

  //#region QR SCANNER
  qrScanner: getPath('/s/qr-scanner', ['v2.sidebar.home', 'v2.sidebar.qrScanner.index']),
  //#endregion QR SCANNER

  biometricLogSub: getPath('/s/biometric-log', ['v2.sidebar.home', 'v2.sidebar.hrTicket.biometricLog']),

  //#region LEAVE TICKET
  leaveTicketSub: getPath('/s/hr-ticket/leave-ticket', [
    'v2.sidebar.home',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.leaveTicket'
  ]),
  myLeaveTicketSub: getPath('/s/hr-ticket/leave-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.leaveTicket',
    'Phiếu của tôi'
  ]),
  leaveTicketAddSub: getPath('/s/hr-ticket/leave-ticket/add', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.leaveTicket',
    'action.add'
  ]),
  leaveTicketEditSub: getPath('/s/hr-ticket/leave-ticket/edit', [
    'v2.sidebar.home',
    'v2.helpdesk.index',
    'v2.sidebar.hrTicket.index',
    'v2.sidebar.hrTicket.leaveTicket',
    'action.edit'
  ]),
  //#endregion LEAVE TICKET
  //#endregion SUB

  //#region COPORATE OPERATION
  corporateOperationV2: getPath('/coporate-opertaion', ['sidebar.home', 'v2.coporateOpertaion.index']),
  //#region Handover ticket
  handoverTicketV2: getPath('/coporate-opertaion/asset-management/handover-ticket', [
    'v2.sidebar.home',
    'v2.assetManagement.index',
    'v2.assetManagement.handoverTicket'
  ]),
  myHandoverTicketV2: getPath('/coporate-opertaion/asset-management/handover-ticket/my-ticket', [
    'v2.sidebar.home',
    'v2.assetManagement.index',
    'v2.assetManagement.handoverTicket',
    'Phiếu của tôi'
  ]),
  handoverTicketApprovalV2: getPath('/coporate-opertaion/asset-management/handover-ticket/confirm', [
    'v2.sidebar.home',
    'v2.assetManagement.index',
    'v2.assetManagement.handoverTicket',
    'v2.sidebar.confirm'
  ]),
  handoverTicketAddV2: getPath('/coporate-opertaion/asset-management/handover-ticket/add', [
    'v2.sidebar.home',
    'v2.assetManagement.index',
    'v2.assetManagement.handoverTicket',
    'action.add'
  ]),
  handoverTicketEditV2: getPath('/coporate-opertaion/asset-management/handover-ticket/edit', [
    'v2.sidebar.home',
    'v2.assetManagement.index',
    'v2.assetManagement.handoverTicket',
    'action.edit'
  ]),
  //#endregion
  //#endregion COPORATE OPERATION

  //#region Topic
  topic: getPath('/topic', ['sidebar.home', 'v2.topic.index']),
  //#endregion Topic

  //#region REPORT
  report: getPath('/report', ['sidebar.home', 'v2.report.index']),
  reportLeaveTicket: getPath('/report/leave-ticket', ['sidebar.home', 'v2.report.index', 'v2.report.leaveTicket']),
  reportOvertimeTicket: getPath('/report/overtime-ticket', [
    'sidebar.home',
    'v2.report.index',
    'v2.report.overtimeTicket'
  ]),
  reportPNL04: getPath('/report/pln4', ['sidebar.home', 'v2.report.index', 'pln4']),
  reportBusinessTripTicket: getPath('/report/business-trip-ticket', [
    'sidebar.home',
    'v2.report.index',
    'v2.report.businessTripTicket'
  ]),
  reportDailyInventoryJournal: getPath('/report/daily-inventory-journal', [
    'sidebar.home',
    'v2.report.index',
    'v2.report.dailyInventoryJournal'
  ]),
  //#endregion REPORT

  //#region ITEM MASTER DATA
  itemMasterData: getPath('/item-master-data', ['sidebar.home', 'v2.itemMasterData.index']),
  itemMasterDataAdd: getPath('/item-master-data/add', ['sidebar.home', 'v2.itemMasterData.index', 'action.add']),
  itemMasterDataEdit: getPath('/item-master-data/edit', ['sidebar.home', 'v2.itemMasterData.index', 'action.edit']),
  itemType: getPath('/item-type', ['sidebar.home', 'v2.itemMasterData.index', 'v2.itemMasterData.itemType']),
  itemTypeAdd: getPath('/item-type/add', [
    'sidebar.home',
    'v2.itemMasterData.index',
    'v2.itemMasterData.itemType',
    'action.add'
  ]),
  itemTypeEdit: getPath('/item-type/edit', [
    'sidebar.home',
    'v2.itemMasterData.index',
    'v2.itemMasterData.itemType',
    'action.edit'
  ]),
  itemGroup: getPath('/item-group', ['sidebar.home', 'v2.itemMasterData.index', 'v2.itemMasterData.itemGroup']),
  itemGroupAdd: getPath('/item-group/add', [
    'sidebar.home',
    'v2.itemMasterData.index',
    'v2.itemMasterData.itemGroup',
    'action.add'
  ]),
  itemGroupEdit: getPath('/item-group/edit', [
    'sidebar.home',
    'v2.itemMasterData.index',
    'v2.itemMasterData.itemGroup',
    'action.edit'
  ]),
  //#endregion ITEM MASTER DATA

  //#region ERP
  erp: getPath('/erp-management', ['sidebar.home', 'v2.erp.index']),
  erpCrm: getPath('/erp-crm', ['sidebar.home', 'sidebar.erp.index', 'sidebar.erp.planning']),
  erpPlanning: getPath('/erp-planning', ['sidebar.home', 'sidebar.erp.index', 'sidebar.erp.planning']),
  erpPurchasing: getPath('/erp-purchasing', ['sidebar.home', 'sidebar.erp.index', 'sidebar.erp.planning']),
  erpProduction: getPath('/erp-production', ['sidebar.home', 'sidebar.erp.index', 'sidebar.erp.planning']),
  erpLogistic: getPath('/erp-logistic', ['sidebar.home', 'sidebar.erp.index', 'sidebar.erp.planning']),
  erpWareHouse: getPath('/erp-ware-house', ['sidebar.home', 'sidebar.erp.index', 'sidebar.erp.planning']),
  erpAccount: getPath('/erp-account', ['sidebar.home', 'sidebar.erp.index', 'sidebar.erp.planning']),
  erpMrp: getPath('/erp-mrp', ['sidebar.home', 'sidebar.erp.index', 'sidebar.erp.planning']),
  erpCorporateOperation: getPath('/erp-corporation-operation', [
    'sidebar.home',
    'sidebar.erp.index',
    'sidebar.erp.planning'
  ]),
  //#endregion ERP

  //#region R&D
  researchAndDev: getPath('/r&d', ['sidebar.home', 'v2.r&d.index']),
  //#endregion R&D

  //#region Kpi
  target: getPath('/target', ['sidebar.home', 'sidebar.planning.index', 'sidebar.planning.target']),
  workAssign: getPath('/work-assign', ['sidebar.home', 'sidebar.planning.index', 'sidebar.planning.workAssign']),
  workAssignAdd: getPath('/work-assign/add', [
    'sidebar.home',
    'sidebar.planning.index',
    'sidebar.planning.workAssign',
    'action.add'
  ]),
  workAssignEdit: getPath('/work-assign/edit', [
    'sidebar.home',
    'sidebar.planning.index',
    'sidebar.planning.workAssign',
    'action.edit'
  ]),
  workPlan: getPath('/work-plan', ['sidebar.home', 'sidebar.planning.index', 'sidebar.planning.workPlan']),
  workPlanAdd: getPath('/work-plan/add', [
    'sidebar.home',
    'sidebar.planning.index',
    'sidebar.planning.workPlan',
    'action.add'
  ]),
  workPlanEdit: getPath('/work-plan/edit', [
    'sidebar.home',
    'sidebar.planning.index',
    'v2.planning.workPlan',
    'action.edit'
  ]),
  //#endregion Kpi

  //#region Project Planning
  projectPlanning: getPath('/project-planning', ['sidebar.home', 'v2.projectPlanning.index']),

  // PQ
  planningPQ: getPath('/project-planning/pq', [
    'sidebar.home',
    'v2.projectPlanning.index',
    'v2.projectPlanning.planningPQ'
  ]),
  planningPQDetail: getPath('/project-planning/pq/detail', [
    'sidebar.home',
    'v2.projectPlanning.index',
    'v2.projectPlanning.planningPQ',
    'v2.projectPlanning.detail'
  ]),
  planningPQDetailCode: getPath('/project-planning/pq/detail-by-code', [
    'sidebar.home',
    'v2.projectPlanning.index',
    'v2.projectPlanning.planningPQ',
    'v2.projectPlanning.detail'
  ]),

  // MRP
  mrp: getPath('/project-planning/mrp', ['sidebar.home', 'v2.projectPlanning.index', 'v2.projectPlanning.mrp']),

  //#endregion Project Planning

  //#region CONTRACT EXECUTION

  //#region PRODUCTION MANAGEMENT
  production: getPath('/production', ['sidebar.home', 'v2.production.index']),

  //#region DELIVERY ORDER
  deliveryOrder: getPath('/contract-execution/production-management/delivery-order', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.deliveryOrder'
  ]),
  deliveryOrderDetail: getPath('/contract-execution/production-management/delivery-order/detail', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.deliveryOrder',
    'v2.bom.detail'
  ]),
  deliveryOrderDetailCode: getPath('/contract-execution/production-management/delivery-order/detail', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.deliveryOrder',
    'v2.bom.detail'
  ]),
  deliveryOrderAdd: getPath('/contract-execution/production-management/delivery-order/add', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.deliveryOrder',
    'action.add'
  ]),
  deliveryOrderEdit: getPath('/contract-execution/production-management/delivery-order/edit', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.deliveryOrder',
    'action.edit'
  ]),
  //#endregion DELIVERY ORDER

  //#region DELIVERY REQUEST
  deliveryRequest: getPath('/contract-execution/production-management/delivery-request', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.deliveryRequest'
  ]),
  deliveryRequestDetail: getPath('/contract-execution/production-management/delivery-request/detail', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.deliveryRequest',
    'v2.bom.detail'
  ]),
  deliveryRequestDetailCode: getPath('/contract-execution/production-management/delivery-request/detail', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.deliveryRequest',
    'v2.bom.detail'
  ]),
  deliveryRequestAdd: getPath('/contract-execution/production-management/delivery-request/add', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.deliveryRequest',
    'action.add'
  ]),
  deliveryRequestEdit: getPath('/contract-execution/production-management/delivery-request/edit', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.deliveryRequest',
    'action.edit'
  ]),
  //#endregion DELIVERY REQUEST

  //#region PRODUCTION ORDER
  productionOrder: getPath('/contract-execution/production-management/production-order', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.productionOrder'
  ]),
  productionOrderDetail: getPath('/contract-execution/production-management/production-order/detail', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.productionOrder',
    'v2.bom.detail'
  ]),
  productionOrderDetailCode: getPath('/contract-execution/production-management/production-order/detail', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.productionOrder',
    'v2.bom.detail'
  ]),
  productionOrderAdd: getPath('/contract-execution/production-management/production-order/add', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.productionOrder',
    'action.add'
  ]),
  productionOrderEdit: getPath('/contract-execution/production-management/production-order/edit', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.productionOrder',
    'action.edit'
  ]),
  //#endregion PRODUCTION ORDER

  //#region PRODUCTION REQUEST
  productionRequest: getPath('/contract-execution/production-management/production-request', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.productionRequest'
  ]),
  productionRequestDetail: getPath('/contract-execution/production-management/production-request/detail', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.productionRequest',
    'v2.bom.detail'
  ]),
  productionRequestDetailCode: getPath('/contract-execution/production-management/production-request/detail', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.productionRequest',
    'v2.bom.detail'
  ]),
  productionRequestAdd: getPath('/contract-execution/production-management/production-request/add', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.productionRequest',
    'action.add'
  ]),
  productionRequestEdit: getPath('/contract-execution/production-management/production-request/edit', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.productionRequest',
    'action.edit'
  ]),
  //#endregion PRODUCTION REQUEST

  //#region PMR SPREADSHEET
  pmrSpreadsheet: getPath('/contract-execution/production-management/pmr-spreadsheet', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.pmrSpreadsheet'
  ]),
  pmrSpreadsheetDetail: getPath('/contract-execution/production-management/pmr-spreadsheet/detail', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.pmrSpreadsheet',
    'v2.bom.detail'
  ]),
  pmrSpreadsheetDetailCode: getPath('/contract-execution/production-management/pmr-spreadsheet/detail', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.pmrSpreadsheet',
    'v2.bom.detail'
  ]),
  pmrSpreadsheetAdd: getPath('/contract-execution/production-management/pmr-spreadsheet/add', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.pmrSpreadsheet',
    'action.add'
  ]),
  pmrSpreadsheetEdit: getPath('/contract-execution/production-management/pmr-spreadsheet/edit', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.pmrSpreadsheet',
    'action.edit'
  ]),
  //#endregion PMR SPREADSHEET

  //#region RAW MATERIAL ESTIMATE
  rawMatEst: getPath('/contract-execution/production-management/raw-material-estimate', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.rawMatEst'
  ]),
  rawMatEstDetail: getPath('/contract-execution/production-management/raw-material-estimate/detail', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.rawMatEst',
    'v2.bom.detail'
  ]),
  rawMatEstDetailCode: getPath('/contract-execution/production-management/raw-material-estimate/detail', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.rawMatEst',
    'v2.bom.detail'
  ]),
  rawMatEstAdd: getPath('/contract-execution/production-management/raw-material-estimate/add', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.rawMatEst',
    'action.add'
  ]),
  rawMatEstEdit: getPath('/contract-execution/production-management/raw-material-estimate/edit', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.rawMatEst',
    'action.edit'
  ]),
  //#endregion PMR SPREADSHEET

  //#region PMR
  pmr: getPath('/contract-execution/production-management/pmr', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.pmr'
  ]),
  pmrDetail: getPath('/contract-execution/production-management/pmr/detail', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.pmr',
    'v2.bom.detail'
  ]),
  pmrDetailCode: getPath('/contract-execution/production-management/pmr/detail', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.pmr',
    'v2.bom.detail'
  ]),
  pmrAdd: getPath('/contract-execution/production-management/pmr/add', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.pmr',
    'action.add'
  ]),
  pmrEdit: getPath('/contract-execution/production-management/pmr/edit', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.pmr',
    'action.edit'
  ]),
  //#endregion PMR

  //#region BOM - BILL OF MATERIAL
  bom: getPath('/contract-execution/production-management/bom', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.bom'
  ]),
  bomDetail: getPath('/contract-execution/production-management/bom/detail', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.bom',
    'v2.bom.detail'
  ]),
  bomDetailCode: getPath('/contract-execution/production-management/bom/detail', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.bom',
    'v2.bom.detail'
  ]),
  bomAdd: getPath('/contract-execution/production-management/bom/add', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.bom',
    'action.add'
  ]),
  bomEdit: getPath('/contract-execution/production-management/bom/edit', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.bom',
    'action.edit'
  ]),
  //#endregion BOM - BILL OF MATERIAL

  //#region Packaging Request
  packagingRequest: getPath('/contract-execution/production-management/packaging-request', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.packagingRequest'
  ]),
  packagingRequestDetail: getPath('/contract-execution/production-management/packaging-request/detail', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.packagingRequest',
    'v2.bom.detail'
  ]),
  packagingRequestDetailCode: getPath('/contract-execution/production-management/packaging-request/detail', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.packagingRequest',
    'v2.bom.detail'
  ]),
  packagingRequestAdd: getPath('/contract-execution/production-management/packaging-request/add', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.packagingRequest',
    'action.add'
  ]),
  packagingRequestEdit: getPath('/contract-execution/production-management/packaging-request/edit', [
    'sidebar.home',
    'v2.contractExecution.index',
    'v2.contractExecution.productionManagement.index',
    'v2.contractExecution.productionManagement.packagingRequest',
    'action.edit'
  ]),
  //#endregion Packaging Request

  //#endregion PRODUCTION MANAGEMENT

  //#endregion CONTRACT EXECUTION

  //#region Sale
  sale: getPath('/sale', ['sidebar.home', 'v2.sale.index']),

  // Business Proposal
  shippingPlan: getPath('/sale/shipping-plan', ['sidebar.home', 'v2.sale.index', 'v2.sale.shippingPlan']),
  saleDebt: getPath('/sale/debt', ['sidebar.home', 'v2.sale.index', 'v2.sale.debt']),
  saleHistoryOrder: getPath('/sale/history-order', ['sidebar.home', 'v2.sale.index', 'v2.sale.saleHistoryOrder']),
  businessProposal: getPath('/sale/business-proposal', ['sidebar.home', 'v2.sale.index', 'v2.sale.businessProposal']),
  so: getPath('/sale/so', ['sidebar.home', 'v2.sale.index', 'v2.sale.so']),
  sq: getPath('/sale/sq', ['sidebar.home', 'v2.sale.index', 'v2.sale.sq']),
  salePr: getPath('/sale/pr', ['sidebar.home', 'v2.sale.index', 'v2.sale.salePr']),

  //#endregion Sale

  //#region Purchase
  purchase: getPath('/purchase', ['sidebar.home', 'v2.purchase.index']),

  // SR - SUPPLY REQUEST
  sr: getPath('/purchase/sr', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.sr']),
  srDetail: getPath('/purchase/sr/detail', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.sr',
    'v2.purchase.detail'
  ]),
  srDetailCode: getPath('/purchase/sr/detail-by-code', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.sr',
    'v2.purchase.detail'
  ]),
  srAdd: getPath('/purchase/sr/add', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.sr', 'action.add']),
  srEdit: getPath('/purchase/sr/edit', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.sr', 'action.edit']),

  // PR
  purchaseRequest: getPath('/purchase/pr', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.pr']),
  purchaseRequestDetail: getPath('/purchase/pr/detail', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pr',
    'v2.purchase.detail'
  ]),
  purchaseRequestDetailCode: getPath('/purchase/pr/detail-by-code', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pr',
    'v2.purchase.detail'
  ]),
  purchaseRequestAdd: getPath('/purchase/pr/add', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pr',
    'action.add'
  ]),
  purchaseRequestEdit: getPath('/purchase/pr/edit', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pr',
    'action.edit'
  ]),

  // PQR
  pqr: getPath('/purchase/pqr', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.pqr']),
  pqrDetail: getPath('/purchase/pqr/detail', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pqr',
    'v2.purchase.detail'
  ]),
  pqrDetailCode: getPath('/purchase/pqr/detail-by-code', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pqr',
    'v2.purchase.detail'
  ]),
  pqrAdd: getPath('/purchase/pqr/add', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.pqr', 'action.add']),
  pqrEdit: getPath('/purchase/pqr/edit', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.pqr', 'action.edit']),

  // PQ
  purchaseQuotation: getPath('/purchase/pq', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.pq']),
  purchaseQuotationDetail: getPath('/purchase/pq/detail', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pq',
    'v2.purchase.detail'
  ]),
  purchaseQuotationDetailCode: getPath('/purchase/pq/detail-by-code', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pq',
    'v2.purchase.detail'
  ]),
  purchaseQuotationAdd: getPath('/purchase/pq/add', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pq',
    'action.add'
  ]),
  purchaseQuotationVendorAdd: getPath('/purchase/pq/vendor-add', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pq',
    'action.add'
  ]),
  purchaseQuotationEdit: getPath('/purchase/pq/edit', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.pq',
    'action.edit'
  ]),

  //PO
  purchaseOrder: getPath('/purchase/po', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.po']),
  purchaseOrderStamp: getPath('/purchase/po-stamp', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.poStamp']),
  purchaseOrderDetail: getPath('/purchase/po/detail', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.po',
    'v2.purchase.detail'
  ]),
  purchaseOrderDetailCode: getPath('/purchase/po/detail-by-code', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.po',
    'v2.purchase.detail'
  ]),
  purchaseOrderAdd: getPath('/purchase/po/add', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.po', 'action.add']),
  purchaseOrderEdit: getPath('/purchase/po/edit', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.po',
    'action.edit'
  ]),

  //GRPO
  grpo: getPath('/purchase/grpo', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.grpo']),
  grpoDetail: getPath('/purchase/grpo/detail', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.grpo',
    'v2.purchase.detail'
  ]),
  grpoDetailCode: getPath('/purchase/grpo/detail-by-code', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.grpo',
    'v2.purchase.detail'
  ]),
  grpoAdd: getPath('/purchase/grpo/add', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.grpo', 'action.add']),
  grpoEdit: getPath('/purchase/grpo/edit', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.grpo', 'action.edit']),

  grpor: getPath('/purchase/grpor', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.grpor']),
  grporDetail: getPath('/purchase/grpor/detail', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.grpor',
    'v2.purchase.detail'
  ]),
  grporDetailCode: getPath('/purchase/grpor/detail-by-code', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.grpor',
    'v2.purchase.detail'
  ]),
  grporAdd: getPath('/purchase/grpor/add', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.grpor', 'action.add']),
  grporEdit: getPath('/purchase/grpor/edit', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.grpor', 'action.edit']),

  // QC Result
  qcResult: getPath('/purchase/qc-result', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.qcResult']),
  qcResultDetail: getPath('/purchase/qc-result/detail', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.qcResult',
    'v2.purchase.detail'
  ]),
  qcResultAdd: getPath('/purchase/qc-result/add', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.qcResult',
    'action.add'
  ]),
  qcResultEdit: getPath('/purchase/qc-result/edit', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.qcResult',
    'action.edit'
  ]),

  // HẠCH TOÁN - ACCOUNTING
  accounting: getPath('/purchase/accounting', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.accounting']),
  accountingDetail: getPath('/purchase/accounting/detail', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.accounting',
    'v2.purchase.detail'
  ]),
  accountingAdd: getPath('/purchase/accounting/add', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.accounting',
    'action.add'
  ]),
  accountingEdit: getPath('/purchase/accounting/edit', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.accounting',
    'action.edit'
  ]),

  // AP Invoice
  apInvoice: getPath('/purchase/ap-invoice', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.apInvoice']),
  apInvoiceDetail: getPath('/purchase/ap-invoice/detail', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.apInvoice',
    'v2.purchase.detail'
  ]),
  apInvoiceAdd: getPath('/purchase/ap-invoice/add', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.apInvoice',
    'action.add'
  ]),
  apInvoiceEdit: getPath('/purchase/ap-invoice/edit', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.apInvoice',
    'action.edit'
  ]),

  receivePlan: getPath('/purchase/receive-plan', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.receivePlan']),
  ordersHistory: getPath('/purchase/orders-history', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.ordersHistory'
  ]),
  debtGroup: getPath('/purchase/debt-group', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.debtGroup']),
  debt: getPath('/purchase/debt', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.debt']),
  setOfDocument: getPath('/purchase/set-of-documents', [
    'sidebar.home',
    'v2.purchase.index',
    'v2.purchase.setOfDocument'
  ]),
  documentType: getPath('/purchase/document-type', ['sidebar.home', 'v2.purchase.index', 'v2.purchase.documentType']),
  refKey: getPath('/purchase/ref-key', ['sidebar.home', 'v2.purchase.index', 'Ref key']),

  //#endregion Purchase

  //#region CRM
  crmManagement: getPath('/crm-management', ['sidebar.home', 'v2.crmManagement.index']),
  //#endregion

  //#region TICKET
  ticketManagement: getPath('/ticket-management', ['sidebar.home', 'v2.ticketManagement.index']),
  //#endregion

  //#region ERP
  erpManagement: getPath('/erp-management', ['sidebar.home', 'v2.erpManagement.index']),
  //#endregion

  //#region MES
  mesManagement: getPath('/mes-management', ['sidebar.home', 'v2.mesManagement.index']),
  //#endregion

  //#region INTERACTIVE
  interactiveManagement: getPath('/interactive-management', ['sidebar.home', 'v2.interactiveManagement.index'])
  //#endregion
};

export default urlPath;
