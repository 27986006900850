import type { FlatItem, HierarchicalItem } from 'src/types/elements.type';
import type {
  GeneralAccountingAccountAdd,
  IGeneralAccountingAccount
} from 'src/types/master_data/generalAccountingAccount.type';
import { http } from 'src/utils/http';

const controller = 'master-data/general-accounting-accounts';

export async function getJobCategoriesDropdown() {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
}

export const getGeneralAccountingAccounts = async (sequenceStatus: string) => {
  return await http.get<IGeneralAccountingAccount[]>(`${controller}`, {
    params: {
      sequenceStatus
    }
  });
};

export const getGeneralAccountingAccount = async (id: number) => {
  return http.get<IGeneralAccountingAccount>(`${controller}/${id}`);
};

export const addGeneralAccountingAccount = async (generalAccountingAccount: GeneralAccountingAccountAdd) => {
  return http.post<GeneralAccountingAccountAdd>(controller, generalAccountingAccount);
};

export const updateGeneralAccountingAccount = async (
  id: number,
  generalAccountingAccount: IGeneralAccountingAccount
) => {
  return await http.put<IGeneralAccountingAccount[]>(`${controller}/${id}`, generalAccountingAccount);
};

export const deleteGeneralAccountingAccount = async (id: number) => {
  await http.delete(`${controller}/${id}`);
};

export const getGeneralAccountingAccountsDropdown = async (accountingAccountTypeId?: number) => {
  return http.get<HierarchicalItem[]>(`${controller}/dropdown`, {
    params: { accountingAccountTypeId }
  });
};
